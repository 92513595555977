import React, { useEffect, useState } from "react";
import "./CustomFieldEditor.scss";
import {
  customFieldInterface,
  customFieldOptionInterface,
  CUSTOM_FIELD_TARGET,
  CUSTOM_FIELD_TYPE,
} from "api/customfield";
import {
  Button,
  ButtonToolbar,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Schema,
  SelectPicker,
} from "rsuite";
import { FormOptionSelectValueInput, isSelect, makeFormError } from "./CustomFieldUtils";
import useMBContext from "context/useMBContext";

const { StringType, ArrayType } = Schema.Types;

const customFieldModel = Schema.Model({
  type: StringType().isRequired("Type is required."),
  target: StringType().isRequired("Target is required."),
  name: StringType().isRequired("Name is required."),
  description: StringType(),
  options: ArrayType()
    .addRule((value, data) => {
      return !isSelect(data.type) || value?.length > 0;
    }, "Select must have at least one allowed value")
    .addRule((value) => {
      const names = value.map((x) => x.name);
      const nameSet = new Set(names);
      return names.length === nameSet.size;
    }, "All allowed values must be unique"),
  tags: StringType(),
});

const optionModel = Schema.Model({
  name: StringType().isRequired("Name is required"),
  description: StringType(),
});

const defaultFieldOption: customFieldOptionInterface = {
  name: "Filing",
};

const defaultField: customFieldInterface = {
  target: CUSTOM_FIELD_TARGET.USER,
  type: CUSTOM_FIELD_TYPE.TEXT,
  name: "Department",
  description: "The department that this user is a member of",
  options: [{ ...defaultFieldOption }],
  tags: "",
};

interface CustomFieldEditorProps {
  internalFields: { id: string; name: string }[];
  initialCustomField?: customFieldInterface;
  onSave?: (field: customFieldInterface | undefined) => void;
  canEdit?: boolean;
  onEdit?: () => void;
}

export const CustomFieldEditor: React.FC<CustomFieldEditorProps> = ({
  internalFields,
  initialCustomField,
  onSave,
  canEdit,
  onEdit,
}) => {
  const { isMBAdmin } = useMBContext();

  const [customField, setCustomField] = useState({
    ...defaultField,
    ...initialCustomField,
  });

  useEffect(() => {
    setCustomField({
      ...defaultField,
      ...initialCustomField,
    });
  }, [initialCustomField]);

  const updateOption = (index, field, value) => {
    const updatedOptions = [...(customField.options || [])];
    updatedOptions[index][field] = value;
    setCustomField({ ...customField, options: updatedOptions });
  };

  const addOption = () => {
    setCustomField({
      ...customField,
      options: [...(customField.options || []), { ...defaultFieldOption }],
    });
  };

  const removeOption = (index) => {
    setCustomField({
      ...customField,
      options: customField.options?.filter((_, i) => i !== index),
    });
  };

  const [formError, setFormError] = useState<Record<string, string | undefined>>({});

  useEffect(() => {
    const fieldErrors = makeFormError(customFieldModel.check(customField as any));
    const optionErrors = (customField.options || []).map((x) =>
      makeFormError(optionModel.check(x as any)),
    );
    var allErrors = {
      ...fieldErrors,
    };
    if (Object.values(optionErrors).filter((x) => Object.keys(x).length > 0).length > 0) {
      allErrors.optionArray = optionErrors as any;
    }
    setFormError(allErrors as any);
  }, [customField]);

  const handleChange = (value) => {
    setCustomField((x) => ({ ...x, ...value }));
  };

  const handleSubmit = () => {
    if (Object.keys(formError).length > 0) return;
    const field = {
      ...customField,
    };
    if (!isSelect(field.type)) {
      delete field.options;
    }
    onSave?.(field);
  };

  const _computeAllowedTypes = () => {
    const options: Record<string, any>[] = [];
    if (customField.id === undefined || customField.type === CUSTOM_FIELD_TYPE.TEXT) {
      options.push({ label: "Text", value: CUSTOM_FIELD_TYPE.TEXT });
    }
    if (customField.id === undefined || customField.type === CUSTOM_FIELD_TYPE.SELECT) {
      options.push({ label: "Select", value: CUSTOM_FIELD_TYPE.SELECT });
    }
    if (customField.id === undefined || isSelect(customField.type)) {
      options.push({ label: "Select (with Other)", value: CUSTOM_FIELD_TYPE.SELECT_WITH_OTHER });
    }
    if (customField.id === undefined || customField.type === CUSTOM_FIELD_TYPE.BOOLEAN) {
      options.push({ label: "Checkbox", value: CUSTOM_FIELD_TYPE.BOOLEAN });
    }
    return options;
  };
  const allowedTypeOptions = _computeAllowedTypes();

  return (
    <div className="custom-field-editor">
      {onSave ? (
        <Form
          layout="horizontal"
          formValue={customField}
          formError={formError}
          onChange={handleChange}
          onSubmit={handleSubmit}
        >
          <FormGroup>
            <ControlLabel>Name</ControlLabel>
            <FormControl name="name" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Description</ControlLabel>
            <FormControl name="description" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Tags</ControlLabel>
            <FormControl name="tags" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Target</ControlLabel>
            <FormControl
              name="target"
              accepter={SelectPicker}
              searchable={false}
              cleanable={false}
              data={[
                { label: "User", value: CUSTOM_FIELD_TARGET.USER },
                { label: "Internal", value: CUSTOM_FIELD_TARGET.NONE },
              ]}
              disabled={!!customField.id}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Type</ControlLabel>
            <FormControl
              name="type"
              accepter={SelectPicker}
              cleanable={false}
              searchable={false}
              data={allowedTypeOptions}
              disabled={!!customField.id && !isSelect(customField.type)}
            />
          </FormGroup>
          {isSelect(customField.type) && (
            <>
              <p>Allowed values:</p>
              {customField.options?.map((option, index) => (
                <>
                  <FormGroup key={index}>
                    <ControlLabel>Option {index + 1}</ControlLabel>
                    <FormControl
                      accepter={FormOptionSelectValueInput}
                      value={option.name}
                      onChange={(value) => updateOption(index, "name", value)}
                      errorMessage={
                        formError.optionArray?.[index]?.["name"] && (
                          <p>{formError.optionArray?.[index]["name"]}</p>
                        )
                      }
                      onClear={
                        !option.id && (customField.options?.length || 0) > 1
                          ? () => removeOption(index)
                          : undefined
                      }
                      onAdd={addOption}
                    />
                  </FormGroup>
                  <p style={{ marginLeft: "120px", marginBottom: "20px" }}>
                    <span style={{ paddingRight: "5px" }}>Sub field:</span>
                    <SelectPicker
                      data={internalFields.map((x) => ({ label: x.name, value: x.id }))}
                      value={option.childId}
                      searchable={false}
                      onChange={(value) => updateOption(index, "childId", value)}
                      disabled={!!option.id}
                    />
                  </p>
                </>
              ))}
              {formError.options && <p style={{ color: "red" }}>{formError.options}</p>}
            </>
          )}
          <ButtonToolbar>
            <Button appearance="primary" onClick={handleSubmit}>
              Save
            </Button>
            <Button
              appearance="subtle"
              onClick={() => {
                onSave?.(undefined);
              }}
            >
              Cancel
            </Button>
          </ButtonToolbar>
        </Form>
      ) : (
        <div className="custom-field-summary">
          <div>
            {isMBAdmin && <p>{customField.id}</p>}
            <p>{customField.name}</p>
            <p style={{ fontSize: "80%" }}>{customField.description}</p>
            <p style={{ fontSize: "80%" }}>Tags: {customField.tags}</p>
            {customField.options && (
              <p>Allowed values: {customField.options.map((x) => x.name).join(", ")}</p>
            )}
          </div>
          <div>
            {canEdit && (
              <Button appearance="primary" onClick={onEdit} style={{ width: "100%" }}>
                Edit
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
