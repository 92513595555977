import React, { ErrorInfo, ReactNode } from "react";
import { NavLink } from "react-router-dom";
import "./ErrorBoundary.scss";
import logo from "assets/img/logo.png";
import http, { url } from "misc/http";

const { REACT_APP_ENVIRONMENT } = process.env;

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(): State {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const isProd = REACT_APP_ENVIRONMENT === "production";
    if (isProd) {
      http.post(url.log, { error: JSON.stringify({ error, errorInfo }) });
    } else {
      console.log(JSON.stringify({ error, errorInfo }));
    }
  }

  reloadApp() {
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <header className="Header">
            <NavLink to="/" className="Header-logo" data-testid="header-logo">
              <img
                className="Header-logo-image"
                src={logo}
                alt="MagicBus logo"
                data-testid="header-logo-image"
              />
            </NavLink>
          </header>
          <div className="Field">
            <div className="Field-Heading" data-testid="main-heading">
              App Error
            </div>
            <p className="Field-Summary" data-testid="main-summary">
              We are unable to process your request at this time - please try again
            </p>
            <button className="Reload" onClick={this.reloadApp}>
              Reload
            </button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
