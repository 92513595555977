import React, { useEffect, useState } from "react";
import { Link, Route, Switch, useParams } from "react-router-dom";
import { routes } from "misc/http";
import "./Places.scss";
import { Button, Input, InputGroup, Icon, Table } from "rsuite";
import Map from "components/Map";
import PlacesEditor from "components/PlacesEditor";
import { useHistory } from "react-router-dom";
import useMBContext from "context/useMBContext";
import { placeInterface } from "interfaces/place";
import { LngLatBounds } from "mapbox-gl";
import { boundsFromPoint } from "misc/utils";
import { fetchOrganizationDefaultMapLocation } from "api/organization";
import { fetchPlaces } from "api/places";
import { ActionCell } from "components/common/Table";

const { Cell, Column, HeaderCell } = Table;

interface PlaceEditBouncerProps {
  onClose?: () => void | undefined;
  refreshData?: () => void | undefined;
  places: placeInterface[];
}

const PlaceEditBouncer: React.FC<PlaceEditBouncerProps> = ({ places, ...rest }) => {
  interface Params {
    id: string;
  }
  const { id } = useParams<Params>();
  const place = places.find((x) => x.id === id);
  if (!place) {
    rest.onClose?.();
  }

  return <>{place && <PlacesEditor {...rest} place={place} />}</>;
};

const Places: React.FC = () => {
  const { selectedAccount, selectedOrganization } = useMBContext();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [placesData, setPlacesData] = useState<placeInterface[]>([]);
  const [bounds, setBounds] = useState<LngLatBounds | null>(null);
  const [selectedPlace, setSelectedPlace] = useState<placeInterface | undefined>();

  const history = useHistory();

  useEffect(() => {
    refreshData();
  }, [selectedAccount, selectedOrganization]);

  const refreshData = () => {
    if (!selectedOrganization) return;

    fetchPlaces({ orgId: selectedOrganization.id }).then((items) => {
      setPlacesData(items);
      const allBounds = new LngLatBounds();
      items.forEach((item: placeInterface) => {
        allBounds.extend(boundsFromPoint(item.coordinates));
      });
      setBounds(allBounds);
      if (allBounds.isEmpty()) {
        fetchOrganizationDefaultMapLocation({
          accountId: selectedAccount?.id,
          id: selectedOrganization.id,
        }).then((place) => {
          setBounds(boundsFromPoint(place.coordinates, 0.05));
        });
      }
    });
  };

  const filterPlaces = (value: any) => {
    setSearchTerm(value);
  };
  const showPlace = (data: placeInterface) => {
    const selectedPlace = placesData.find((p) => p.id === data.id);
    selectedPlace?.coordinates && setBounds(boundsFromPoint(selectedPlace.coordinates));
  };

  const handleEdit = (row: placeInterface) => {
    history.push(`${routes.places}/edit/${row.id}`);
  };

  const handleClose = () => {
    history.push(`${routes.places}`);
  };

  const filteredPlaces = placesData.filter(
    (v) => !searchTerm || v.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div className="Places">
      <div className="Places-sidebar">
        <div className="Places-toolbar">
          <div className="Places-toolbar-controls">
            <InputGroup>
              <Input placeholder="Find Places" onChange={filterPlaces} />
              <InputGroup.Addon>
                <Icon icon="search" />
              </InputGroup.Addon>
            </InputGroup>
            <Link to={`${routes.places}/add`}>
              <Button>Add Place</Button>
            </Link>
          </div>
        </div>
        <div className="scroll-container">
          <Table autoHeight data={filteredPlaces} affixHeader={0} onRowClick={showPlace}>
            <Column flexGrow={1}>
              <HeaderCell>Name</HeaderCell>
              <Cell dataKey="name" />
            </Column>
            <Column flexGrow={1}>
              <HeaderCell>Street Address</HeaderCell>
              <Cell dataKey="streetAddress" />
            </Column>
            <Column>
              <HeaderCell>City</HeaderCell>
              <Cell dataKey="city" />
            </Column>
            <Column>
              <HeaderCell>State</HeaderCell>
              <Cell dataKey="state" />
            </Column>
            <Column>
              <HeaderCell>Zip</HeaderCell>
              <Cell dataKey="zip" />
            </Column>
            <Column>
              <HeaderCell>Action</HeaderCell>
              <ActionCell name="Edit" onClick={handleEdit} />
            </Column>
          </Table>
        </div>
      </div>
      <div className="Places-map">
        <Map places={placesData} selectedBounds={bounds} />
      </div>
      <Switch>
        <Route path={`${routes.places}/add`}>
          <PlacesEditor
            onClose={handleClose}
            refreshData={refreshData}
            place={{} as placeInterface}
          />
        </Route>
        <Route path={`${routes.places}/edit/:id`}>
          {placesData.length && (
            <PlaceEditBouncer onClose={handleClose} refreshData={refreshData} places={placesData} />
          )}
        </Route>
      </Switch>
    </div>
  );
};

export default Places;
