import { customFieldValueUpdateInterface } from "api/customfield";
import { GQLBase } from "misc/utils";
import moment, { Moment } from "moment";
import { addressInterface } from "./place";

export enum ORG_USER_ROLE {
  OWNER = "OWNER",
  MANAGER = "MANAGER",
  DRIVER = "DRIVER",
  RIDER = "RIDER",
  VANPOOL_MANAGER = "VANPOOL_MANAGER",
}

export const OrganizationUserRoles = [
  { label: "Rider", value: ORG_USER_ROLE.RIDER },
  { label: "Driver", value: ORG_USER_ROLE.DRIVER },
  {
    label: "VPM",
    value: ORG_USER_ROLE.VANPOOL_MANAGER,
  },
  { label: "Org Manager", value: ORG_USER_ROLE.MANAGER },
  { label: "Org Owner", value: ORG_USER_ROLE.OWNER },
];

export enum ORG_USER_FLAG {
  NO_FEE = "NO_FEE",
}

export const OrganizationUserFlags = [{ label: "No Fee", value: ORG_USER_FLAG.NO_FEE }];

export interface accountRoleInterface {
  id: string;
  name: string;
  number: number;
  isMagicBus: boolean;
  roles: ORG_USER_ROLE[];
}

export interface organizationRoleInterface {
  id: string;
  accountId: string;
  name: string;
  code: string;
  roles: ORG_USER_ROLE[];
}

export interface adminUserInterface {
  id: string;
  name: string;
  email: string;
  phone: string;
  accounts: accountRoleInterface[];
  organizations: organizationRoleInterface[];
}

export class userOrgUpdateInterface extends GQLBase {
  confirmed = false;
  deleted = false;
  id?: string;
  name?: string;
  email?: string;
  phone?: string;
  homeAddress? = new addressInterface();

  organizationId = "";
  roles = [ORG_USER_ROLE.DRIVER];
  flags = [ORG_USER_FLAG.NO_FEE];
  customFieldValues = [new customFieldValueUpdateInterface()];

  static toGQL = (d: userOrgUpdateInterface) => ({
    ...d,
    customFieldValues: d.customFieldValues.map((x) => customFieldValueUpdateInterface.toGQL(x)),
  });
}

// a flatted structure of user + a single org role
export class userOrgInterface extends userOrgUpdateInterface {
  // a user will always have these properties
  created = moment();
  id = "";
  name = "";
  firstRide? = moment(); // pass includeFirstRide to users query

  static fromGQL = (d: userOrgInterface) => ({
    ...d,
    created: moment(d.created),
    customFieldValues: d.customFieldValues.map((x) => customFieldValueUpdateInterface.fromGQL(x)),
    firstRide: d.firstRide ? moment(d.firstRide) : undefined,
  });
}
