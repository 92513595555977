import { GQLBase } from "misc/utils";

export class coordinatesInterface extends GQLBase {
  lat = 0;
  lng = 0;
}

export class placeBaseInterface extends GQLBase {
  coordinates = new coordinatesInterface();
  name = "";
  streetAddress = "";
  city = "";
  state = "";
  zip = "";
  county? = "";
}

export class placeInterface extends placeBaseInterface {
  id = "";
}

export class addressInterface extends placeBaseInterface {
  placeId? = "";
}
