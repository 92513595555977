import React from "react";
import "./Avatar.scss";
import useMBContext from "context/useMBContext";

const Avatar: React.FC = () => {
  const { adminUser } = useMBContext();

  const lastName = adminUser?.name ? adminUser.name.split(" ") : "";

  return (
    <div>
      {adminUser && (
        <span className="Avatar-user-icon">
          {adminUser.name && adminUser.name[0]}
          {lastName[1] && lastName[1][0]}
        </span>
      )}
    </div>
  );
};

export default Avatar;
