/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import "./Table.scss";
import { Table } from "rsuite";
import Pagination from "rsuite/lib/Pagination";
import { TableCellProps } from "rsuite/lib/Table/TableCell";
import { flattenObject, formatPhoneNumberDisplay } from "../../../misc/utils";
import { ORG_USER_ROLE } from "interfaces/user";
import { customFieldInterface, customFieldValueDisplay } from "api/customfield";

type SortType = "desc" | "asc";

interface RTableInterface {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableData: any;
  limit?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectRow?: (...args: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editRow?: (...args: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeRow?: (...args: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleEditAction?: (rowData: any) => void;
  actionLabel?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hideAction?: (rowData: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleCalendarAction?: (rowData: any) => void;
  calendarLabel?: string;
}

const { Cell } = Table;

export const BasicCell: React.ComponentType<TableCellProps> = ({ rowData, dataKey, ...props }) => {
  const value = rowData && dataKey && rowData[dataKey];
  return (
    <Cell {...props} title={value}>
      {value}
    </Cell>
  );
};

const objectItems = (items) => {
  return Object.keys(items).map((item) => <div key={item}>{items[item]}</div>);
};

const renderArrayCell = (cellItem, item) => {
  if (!Array.isArray(item[cellItem]) && typeof item[cellItem] !== "object") {
    return item[cellItem];
  } else if (typeof item[cellItem] === "object") {
    return objectItems(item[cellItem]);
  }
};

const ArrayCell: React.ComponentType<TableCellProps> = ({ rowData, dataKey, ...props }) => (
  <Cell {...props}>
    {rowData &&
      dataKey &&
      rowData[dataKey] &&
      rowData[dataKey].map((item, index) => {
        const flattenObj = {};
        flattenObject(flattenObj, item);
        return (
          <div key={index}>
            {Object.keys(flattenObj).map((schItem) => renderArrayCell(schItem, flattenObj))}
          </div>
        );
      })}
  </Cell>
);

export const ColorCell: React.ComponentType<TableCellProps> = ({ rowData, dataKey, ...props }) => (
  <Cell {...props}>
    {rowData && dataKey && rowData[dataKey] && (
      <div className="ColorBlock-Small" style={{ backgroundColor: rowData[dataKey] }}></div>
    )}
  </Cell>
);

interface ActionCellTypes extends TableCellProps {
  onClick: (row: any) => void;
  name: string;
}

export const ActionCell: React.FC<ActionCellTypes> = ({
  rowData,
  name,
  onClick,
  dataKey,
  ...props
}) => (
  <Table.Cell {...props} style={{ padding: 0 }}>
    <span>
      <a onClick={() => onClick(rowData!)} className="action">
        {name}
      </a>
    </span>
  </Table.Cell>
);

const UserRoleNames = {
  [ORG_USER_ROLE.RIDER]: "Rider",
  [ORG_USER_ROLE.DRIVER]: "Driver",
  [ORG_USER_ROLE.MANAGER]: "Org Manager",
  [ORG_USER_ROLE.OWNER]: "Org Owner",
  [ORG_USER_ROLE.VANPOOL_MANAGER]: "VPM",
};

export const UserRolesCell: React.FC<TableCellProps> = ({ rowData, dataKey, ...props }) => {
  const value = rowData && dataKey && rowData[dataKey].map((x) => UserRoleNames[x]).join(", ");
  return (
    <Table.Cell {...props} style={{ padding: 0 }} title={value}>
      {value}
    </Table.Cell>
  );
};

export const UserFlagsCell: React.FC<TableCellProps> = ({ rowData, dataKey, ...props }) => {
  const value = rowData && dataKey && rowData[dataKey].join(", ");
  return (
    <Table.Cell {...props} style={{ padding: 0 }} title={value}>
      {value}
    </Table.Cell>
  );
};

export const PhoneNumberCell: React.FC<TableCellProps> = ({ rowData, dataKey, ...props }) => {
  const value = rowData && dataKey && formatPhoneNumberDisplay(rowData[dataKey]);
  return (
    <Table.Cell {...props} style={{ padding: 0 }} title={value}>
      {value}
    </Table.Cell>
  );
};

export const DateCell: React.FC<TableCellProps & { format: string }> = ({
  rowData,
  dataKey,
  format,
  ...props
}) => {
  const value = rowData && dataKey && rowData[dataKey]?.format(format);
  return (
    <Table.Cell {...props} style={{ padding: 0 }} title={value}>
      {value}
    </Table.Cell>
  );
};

export const CustomFieldCell: React.FC<TableCellProps & { field: customFieldInterface }> = ({
  rowData,
  dataKey,
  field,
  ...props
}) => {
  const value =
    rowData &&
    dataKey &&
    customFieldValueDisplay(
      field,
      rowData["customFieldValues"].find((x) => x.fieldId === dataKey),
    );
  return (
    <Table.Cell {...props} style={{ padding: 0 }} title={value}>
      {value}
    </Table.Cell>
  );
};

const RTable: React.FC<RTableInterface> = ({
  tableData,
  selectRow,
  limit = 15,
  handleEditAction,
  actionLabel = "Edit",
  hideAction,
  handleCalendarAction,
  calendarLabel,
}: RTableInterface) => {
  const { Column, HeaderCell, Cell } = Table;
  const [sortColumn, setSortColumn] = useState("");
  const [sortType, setSortType] = useState<SortType | undefined>();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [tableData]);

  const getData = () => {
    if (sortColumn && sortType) {
      return tableData.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === "string") {
          x = x.charCodeAt(0);
        }
        if (typeof y === "string") {
          y = y.charCodeAt(0);
        }
        if (sortType === "asc") {
          return x - y;
        } else {
          return y - x;
        }
      });
    }

    return tableData;
  };

  const filterData = getData().filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const handleRowClick = (data) => {
    selectRow && selectRow(data);
  };

  return (
    <div>
      <Table
        wordWrap
        height={620}
        data={filterData}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={loading}
        onRowClick={(data) => {
          handleRowClick(data);
        }}
      >
        {filterData.length > 0 &&
          Object.keys(filterData[0]).map((item) => {
            let cellObj = <Cell dataKey={item} />;
            if (Array.isArray(filterData[0][item])) {
              cellObj = <ArrayCell dataKey={item} />;
            }
            if (filterData[0][item] && filterData[0][item].toString().match("rgba(.+,.+,.+,.+)")) {
              cellObj = <ColorCell dataKey={item} />;
            }
            return (
              <Column flexGrow={1} key={item} align="center" sortable>
                <HeaderCell>{item}</HeaderCell>
                {cellObj}
              </Column>
            );
          })}
        {handleCalendarAction && (
          <Column width={120}>
            <HeaderCell>Calendar</HeaderCell>
            <Cell>
              {(rowData) => {
                function handleCalendar() {
                  handleCalendarAction && handleCalendarAction(rowData.Id);
                }
                if (calendarLabel) {
                  return (
                    <span>
                      <a onClick={handleCalendar}>{calendarLabel}</a>
                    </span>
                  );
                } else {
                  return "";
                }
              }}
            </Cell>
          </Column>
        )}
        <Column width={80} fixed="right">
          <HeaderCell>Action</HeaderCell>
          <Cell>
            {(rowData) => {
              function handleEdit() {
                handleEditAction && handleEditAction(rowData.Id);
              }
              if (hideAction && hideAction(rowData)) {
                return "";
              } else {
                return (
                  <span>
                    <a onClick={handleEdit}>{actionLabel}</a>
                  </span>
                );
              }
            }}
          </Cell>
        </Column>
      </Table>
      <div style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={10}
          size="xs"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={tableData.length}
          limit={limit}
          pages={Math.ceil(tableData.length / limit)}
          activePage={page}
          onSelect={setPage}
        />
      </div>
    </div>
  );
};

export default RTable;
