import { geoPointDataInterface } from "interfaces/map";
import { addressInterface, placeInterface } from "interfaces/place";
import http from "misc/http";

export const getRoute = (
  startLon: number,
  startLat: number,
  endLon: number,
  endLat: number,
  mode: string,
) => {
  const apiURL = `https://api.mapbox.com/directions/v5/mapbox/${mode}/${startLon},${startLat};${endLon},${endLat}`;
  return http.get(apiURL, {
    params: {
      access_token: process.env.REACT_APP_MB_TOKEN,
      geometries: "geojson",
    },
  });
};

export interface coordinate {
  lat: number;
  lon: number;
}

export const getRoute2 = (coordinates: coordinate[]) => {
  const coords = coordinates.map((c) => `${c.lon},${c.lat}`).join(";");
  const apiURL = `https://api.mapbox.com/directions/v5/mapbox/driving/${coords}`;
  return http.get(apiURL, {
    params: {
      access_token: process.env.REACT_APP_MB_TOKEN,
      geometries: "polyline",
      // overview: "full",
    },
  });
};

export const mapboxToAddress = (item) => {
  const address = {
    name: item.place_name,
    coordinates: {
      lat: item.center[1],
      lng: item.center[0],
    },
    city: "",
    state: "",
    zip: "",
    county: "",
  } as addressInterface;
  if (item.place_type[0] === "address") {
    address.streetAddress = item.address ? item.address + " " + item.text : item.text;
  }
  if (item.place_type[0] === "poi") {
    address.streetAddress = item.properties.address ? item.properties.address : item.text;
  }
  if (item.place_type[0] === "place") {
    address.city = item.text;
  }
  item.context.forEach((element) => {
    if (element.id.includes("place")) {
      address.city = element.text;
    }
    if (element.id.includes("region")) {
      address.state = element.short_code.split("-")[1];
    }
    if (element.id.includes("postcode")) {
      address.zip = element.text;
    }
    if (element.id.includes("district")) {
      address.county = element.text.replace(" County", "");
    }
  });
  return address;
};

export const addressToPlace = (item: addressInterface): placeInterface =>
  ({
    ...item,
    id: item.placeId,
  } as unknown as placeInterface);

export const getAddresses = (value: string) => {
  const apiURL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json`;
  return http
    .get(apiURL, {
      params: {
        types: "address,poi",
        access_token: process.env.REACT_APP_MB_TOKEN,
        country: "US",
      },
    })
    .then<geoPointDataInterface[]>((data) => data.data.features);
};

export const geocodeLatLng = (lat: number, lng: number) => {
  const apiURL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json`;
  return http
    .get(apiURL, {
      params: {
        types: "address,poi",
        access_token: process.env.REACT_APP_MB_TOKEN,
        country: "US",
        routing: "true",
      },
    })
    .then<geoPointDataInterface[]>((data) => data.data.features);
};
