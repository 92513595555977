import { gqlOp, GQLSchema } from "misc/http";
import { GQLBase, slice } from "misc/utils";

export class vehicleInterface extends GQLBase {
  id? = "";
  description = "";
  year? = 0;
  make = "";
  model = "";
  color = "";
  capacity = 0;
  state = "";
  licensePlate = "";
  organizationId = "";
}

const vehicleSchema: GQLSchema = {
  params: {
    accountId: "String",
    orgId: "String",
    ids: "[String]",
  },
  name: "vehicles",
  op: "query",
};

export const fetchVehicles = (params: { accountId?: string; orgId?: string; ids?: string[] }) => {
  return gqlOp<vehicleInterface[]>(vehicleSchema, params, [new vehicleInterface()]);
};

const vehicleUpdateSchema: GQLSchema = {
  params: {
    accountId: "String",
    orgId: "String",
    vehicle: "VehicleUpdateInput!",
  },
  name: "vehicleUpdate",
  op: "mutation",
};

export const fetchVehicleUpdate = (params: {
  accountId?: string;
  orgId?: string;
  vehicle: Partial<vehicleInterface>;
}) => {
  const p = {
    ...params,
    vehicle: slice(new vehicleInterface(), params.vehicle as vehicleInterface),
  };

  return gqlOp(vehicleUpdateSchema, p, new vehicleInterface());
};
