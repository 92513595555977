import React from "react";
import "./MapMarkerPin.scss";

interface MapMarkerPinInterface {
  iconName: string;
  pinNumber?: number | null;
  pinName?: string | null;
  active?: boolean;
  data?: {
    type: string;
    time?: string | number | Date;
  };
}

const MapMarkerPin: React.FC<MapMarkerPinInterface> = ({
  iconName,
  data,
  pinNumber = null,
  pinName = null,
  active = false,
}: MapMarkerPinInterface) => {
  const icon: { [key: string]: string } = {
    start: "Home",
    end: "Work",
    pickup: "VanPin",
    dropoff: "BuildingPin",
  };

  const IconComponent = React.lazy(() => import(`components/common/Icon/Icons/${icon[iconName]}`));

  return (
    <div>
      <div className="MapMarkerPin">
        {pinName && (
          <div className="MapMarkerPin-data">
            <div>{pinName}</div>
          </div>
        )}
        <IconComponent className="MapMarkerPin-icon" />
        {pinNumber !== null && <div className="MapMarkerPin-data-number">{pinNumber}</div>}
      </div>
    </div>
  );
};

export default MapMarkerPin;
