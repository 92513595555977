import React, { useState, useEffect } from "react";
import { Link, Route, Switch } from "react-router-dom";
import { routes } from "misc/http";
import "./Pools.scss";
import { Button, Input, InputGroup, Icon, SelectPicker } from "rsuite";
import Map from "components/Map";
import PoolsEditor from "components/PoolsEditor";
import { useHistory } from "react-router-dom";
import useMBContext from "context/useMBContext";
import {
  poolInterface,
  poolDisplayInterface,
  poolUserInterface,
  selectItemInterface,
  fetchVanpoolsV2,
} from "interfaces/pool";
import { placeInterface } from "interfaces/place";
import { stopInterface } from "interfaces/stop";
import { mapLine, mapPin } from "interfaces/map";
import {
  boundsFromPoint,
  boundsFromPolyline,
  compare,
  decodePolyline,
  lineColors,
} from "../../misc/utils";
import { fetchPoolUsers } from "api/pools";
import moment from "moment";
import { fetchVehicles, vehicleInterface } from "api/vehicle";
import { fetchPlaces } from "api/places";
import { LngLatBounds } from "mapbox-gl";
import { fetchOrganizationDefaultMapLocation } from "api/organization";

interface PoolItemProps {
  pool: poolDisplayInterface;
  selected?: boolean;
  onSelect?: (pool: poolDisplayInterface) => void;
  onEdit?: (pool: poolDisplayInterface) => void;
}

const PoolItem: React.FC<PoolItemProps> = ({ pool, onSelect, onEdit, selected = false }) => {
  return (
    <div className={"PoolItem" + (selected ? " selected" : "")} onClick={() => onSelect?.(pool)}>
      <div className="Props">
        {pool.name && (
          <p style={{ fontWeight: "bold" }}>
            <span>{pool.name}</span>
          </p>
        )}
        <p style={{ marginTop: 0 }}>{pool.internalName}</p>
        {pool.services[0] ? (
          <>
            <p>Days: {pool.services[0]?.availableDays.join(", ")}</p>
            <p>
              From {moment(pool.services[0]?.startDate).format("YYYY-MM-DD")}{" "}
              {pool.services[0]?.endDate &&
                "to " + moment(pool.services[0]?.endDate).format("YYYY-MM-DD")}
            </p>
          </>
        ) : (
          <p>No services</p>
        )}
      </div>
      <div className="actions">
        <>
          <p>
            <Button appearance="ghost" size="xs" onClick={() => onEdit?.(pool)}>
              Edit
            </Button>
          </p>
        </>
      </div>
    </div>
  );
};

const Pools: React.FC = () => {
  const { isMBAdmin, isAccManagerRole, isOrgManagerRole, selectedAccount, selectedOrganization } =
    useMBContext();

  // from graphql
  const [poolsData, setPoolsData] = useState<Array<poolInterface>>([]);

  // for display
  const [poolsDisplay, setPoolsDisplay] = useState<Array<poolDisplayInterface>>([]);
  const [usersDisplay, setUsersDisplay] = useState<Array<selectItemInterface<poolUserInterface>>>();
  const [vehiclesDisplay, setVehiclesDisplay] =
    useState<Array<selectItemInterface<vehicleInterface>>>();
  const [placesDisplay, setPlacesDisplay] = useState<Array<selectItemInterface<placeInterface>>>();

  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [pins, setPins] = useState<Array<mapPin>>([]);
  const [polylines, setPolylines] = useState<Array<mapLine>>([]);
  const [activePins, setActivePins] = useState<Array<mapPin>>([]);
  const [activePolylines, setActivePolylines] = useState<Array<mapLine>>([]);
  const [bounds, setBounds] = useState<LngLatBounds | null>(null);
  const [vehicleSearchId, setVehicleSearchId] = useState<string | undefined>();
  const [userSearchId, setUserSearchId] = useState<string | undefined>();
  const [selectedPool, setSelectedPool] = useState<poolInterface | null>(null);

  const history = useHistory();

  const refetchPools = () => {
    if (!selectedOrganization) return;

    // fetch pools
    fetchVanpoolsV2({
      accountId: selectedAccount?.id,
      orgId: selectedOrganization.id,
      vehicleId: vehicleSearchId,
      userId: userSearchId,
    }).then((pools) => {
      setPoolsData(pools);
    });
  };

  // refetch pools list when params change
  useEffect(() => {
    refetchPools();
  }, [selectedAccount, selectedOrganization, vehicleSearchId, userSearchId]);

  // fetch users/vehicles when account/org changes
  useEffect(() => {
    if (!selectedOrganization) return;
    const params = {
      orgId: selectedOrganization.id,
      accountId: selectedAccount?.id,
    };

    fetchPoolUsers(params).then((users) => {
      const display = users.map(
        (user: poolUserInterface): selectItemInterface<poolUserInterface> => ({
          value: user.id,
          label: user.name,
          object: user,
        }),
      );
      setUsersDisplay(display.sort(compare));
    });

    fetchVehicles(params).then((vehicles) => {
      const display = vehicles.map(
        (v: vehicleInterface): selectItemInterface<vehicleInterface> => ({
          value: v.id!,
          label: `${v.licensePlate} - ${v.description} (${v.capacity})`,
          object: v,
        }),
      );
      setVehiclesDisplay(display.sort(compare));
    });

    fetchPlaces(params).then((places) => {
      const display = places.map(
        (p: placeInterface): selectItemInterface<placeInterface> => ({
          value: p.id,
          label: p.name,
          object: p,
        }),
      );
      setPlacesDisplay(display);
    });
  }, [selectedAccount, selectedOrganization]);

  // update display values when raw data changes
  useEffect(() => {
    if (!selectedOrganization) return;
    const poolItems: Array<poolDisplayInterface> = [];
    const pins: Array<mapPin> = [];
    const lines: Array<mapLine> = [];
    const bounds = new LngLatBounds();
    poolsData
      .filter((p) => !searchTerm || p.name?.toLowerCase().includes(searchTerm.toLowerCase()))
      .forEach((pool: poolInterface, index) => {
        poolItems.push({
          ...pool,
          Color: lineColors[index % lineColors.length],
        });

        const today = moment();
        const service =
          pool.services.find(
            (s) => moment(s.startDate) <= today && (!s.endDate || moment(s.endDate) >= today),
          ) || pool.services[0];
        if (!service) return;

        service.outbound.stops.forEach((stop: stopInterface, index: number) => {
          bounds.extend(boundsFromPoint(stop.place.coordinates));
          pins.push({
            id: pool.id.toString() + "-" + stop.place.id.toString(),
            pinNumber: index + 1,
            place: stop.place,
            label: pool.services[0].shortName,
          });
        });

        const line = service.outbound.legs
          .map((l) => decodePolyline(l.polyline))
          .reduce((a, b) => a.concat(b));
        if (line) {
          bounds.extend(line);
          lines[pool.id] = {
            id: pool.id,
            polyline: line,
            color: lineColors[index % lineColors.length],
          };
        }
      });
    setPoolsDisplay(poolItems);
    setPolylines(lines);
    setPins(pins);
    setBounds(bounds);

    if (bounds.isEmpty()) {
      fetchOrganizationDefaultMapLocation({
        accountId: selectedAccount?.id,
        id: selectedOrganization.id,
      }).then((place) => {
        setBounds(boundsFromPoint(place.coordinates, 0.05));
      });
    }
  }, [poolsData, searchTerm]);

  const [isEditing, setEditing] = useState<boolean>(false);

  const showSchedule = (data: poolDisplayInterface) => {
    if (polylines[data.id] && polylines[data.id].polyline) {
      setBounds(boundsFromPolyline(polylines[data.id].polyline));
    }
    const selectedPool = poolsData.filter((pool) => pool.id === data.id);
    selectedPool && setSelectedPool(selectedPool[0]);
  };
  const handleEdit = (row: poolDisplayInterface) => {
    const selectedPool = poolsData.filter((pool) => pool.id === row.id);
    selectedPool && setSelectedPool(selectedPool[0]);
    history.push(`${routes.pools}/edit/${row.id}`);
  };

  const handleClose = () => {
    history.push(`${routes.pools}`);
    setEditing(false);
    refetchPools();
  };

  const handleActiveRoute = (pins: mapPin[], lines: mapLine[]) => {
    setEditing(true);
    setActivePins(pins);
    setActivePolylines(lines);
    if (Object.values(lines).length) {
      setBounds(boundsFromPolyline(Object.values(lines)[0].polyline));
    }
  };

  return (
    <div className="Pools">
      {usersDisplay && vehiclesDisplay && placesDisplay && (
        <div className="Pools-sidebar">
          <Switch>
            <Route path={`${routes.pools}/add`}>
              <PoolsEditor
                onClose={handleClose}
                setRoute={handleActiveRoute}
                users={usersDisplay}
                vehicles={vehiclesDisplay}
                places={placesDisplay}
              />
            </Route>
            <Route path={`${routes.pools}/edit/:poolId`}>
              <PoolsEditor
                onClose={handleClose}
                setRoute={handleActiveRoute}
                users={usersDisplay}
                vehicles={vehiclesDisplay}
                places={placesDisplay}
              />
            </Route>
            <Route>
              <div className="Pools-toolbar">
                <div className="Pools-toolbar-controls">
                  <div className="Pools-toolbar-dropdown">
                    <SelectPicker
                      data={vehiclesDisplay}
                      className="Pools-toolbar-dropdown-picker"
                      placeholder="Search by License Plate"
                      value={vehicleSearchId}
                      onSelect={(id) => setVehicleSearchId(id)}
                      onClean={() => setVehicleSearchId(undefined)}
                    />
                  </div>
                  <div className="Pools-toolbar-search">
                    <InputGroup>
                      <Input
                        placeholder="Search by Pool Name"
                        value={searchTerm}
                        onChange={(value) => setSearchTerm(value)}
                      />
                      <InputGroup.Addon>
                        <Icon icon="search" />
                      </InputGroup.Addon>
                    </InputGroup>
                  </div>
                </div>
                <div className="Pools-toolbar-add">
                  {(isMBAdmin || isAccManagerRole || isOrgManagerRole) && (
                    <Link to={`${routes.pools}/add`}>
                      <Button>Add Pool</Button>
                    </Link>
                  )}
                </div>
              </div>
              <div className="Pools-scroll">
                <div className="PoolItemContainer">
                  {poolsDisplay.map((pool) => {
                    return (
                      <PoolItem
                        key={pool.id}
                        pool={pool}
                        onSelect={(p) => showSchedule(p)}
                        onEdit={(p) => handleEdit(p)}
                        selected={selectedPool?.id === pool.id}
                      />
                    );
                  })}
                </div>
              </div>
            </Route>
          </Switch>
        </div>
      )}
      <div className="Pools-map">
        <Map
          pins={isEditing ? activePins : pins}
          lines={isEditing ? activePolylines : polylines}
          selectedBounds={bounds}
        />
      </div>
    </div>
  );
};

export default Pools;
