import { customFieldInterface, customFieldLinkedInterface } from "api/customfield";
import { GQLBase } from "misc/utils";
import { placeInterface } from "./place";
import { ORG_USER_FLAG, ORG_USER_ROLE } from "./user";

export interface currentAccountInterface {
  id: string;
  name: string;
  number: number;
}

export class routeMakerInterface extends GQLBase {
  logoURL? = "";
  welcomeMessage? = "";
  defaultPlaceId? = "";
  allowedPlaceIds? = [""];
  signupSchema? = ""; // JSON encoded string
}

export class defaultUserSettingsInterface extends GQLBase {
  roles: ORG_USER_ROLE[] = [ORG_USER_ROLE.RIDER];
  flags: ORG_USER_FLAG[] = [ORG_USER_FLAG.NO_FEE];
  privateNetworkOnly = false;
}

export class organizationBasicInterface extends GQLBase {
  id = "";
  accountId = "";
  code = "";
  name = "";
}

export class organizationBaseInterface extends organizationBasicInterface {
  termsLink? = "";
  routeMaker? = new routeMakerInterface();
  adhocPricing? = new pricingInterface();
  userSettings = new defaultUserSettingsInterface();
  defaultMapLocation? = new placeInterface();
  customFields = [new customFieldInterface()];
}

export class organizationInterface extends organizationBaseInterface {
  customFields = [new customFieldLinkedInterface()];
}

export interface organizationDisplayInterface {
  Id: string;
  Code: string;
  Name: string;
  TermsLink?: string;
  RouteMaker?: routeMakerInterface;
  AdHocPricing?: pricingInterface;
}

export interface updateOrganizationInterface {
  name: string;
  termsAndConditionsLink?: string;
  routeMaker?: routeMakerInterface;
  adhocPricing?: pricingInterface;
  userSettings: defaultUserSettingsInterface;
  defaultMapLocation?: string;
}

export class pricingInterface extends GQLBase {
  baseRate = 0;
  perMileRate = 0;
}
