import React, { useEffect, useState } from "react";
import { Link, Route, Switch, useParams } from "react-router-dom";
import { routes } from "misc/http";
import "./Users.scss";
import { Button, Input, InputGroup, Icon, Table, Loader, CheckPicker, Dropdown } from "rsuite";
import {
  BasicCell,
  CustomFieldCell,
  DateCell,
  PhoneNumberCell,
  UserFlagsCell,
  UserRolesCell,
} from "components/common/Table";
import { UserEditor, UserEditorInterface } from "components/UserEditor";
import { useHistory } from "react-router-dom";
import useMBContext from "context/useMBContext";
import { userOrgInterface } from "interfaces/user";
import { fetchUsers } from "api/user";
import { fetchOrganizationsFull } from "api/organization";
import { organizationInterface } from "interfaces/organization";
import { ActionCell } from "components/common/Table";
import { unstable_batchedUpdates } from "react-dom";
import {
  customFieldInterface,
  customFieldValueDisplay,
  CUSTOM_FIELD_TARGET,
} from "api/customfield";
import { compareOpt, formatPhoneNumberDisplay } from "misc/utils";

const { Column, HeaderCell } = Table;

const UserEditBouncer: React.FC<UserEditorInterface> = (props) => {
  interface Params {
    id: string;
  }
  const { id } = useParams<Params>();
  const [user, setUser] = useState<userOrgInterface | undefined>();
  const history = useHistory();
  useEffect(() => {
    fetchUsers({ ids: [id] }).then((users) => {
      if (!users.length) {
        history.replace(routes.users);
      } else {
        setUser(users[0]);
      }
    });
  }, [id]);

  return <>{user && <UserEditor {...props} user={user} />}</>;
};

const Users: React.FC = () => {
  const { selectedAccount, selectedOrganization, isMBAdmin } = useMBContext();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<userOrgInterface[]>([]);
  const [org, setOrg] = useState<organizationInterface | undefined>();
  const history = useHistory();

  useEffect(() => {
    refreshData();
  }, [selectedAccount, selectedOrganization]);

  const refreshData = () => {
    if (!selectedOrganization) return;

    setLoading(true);
    Promise.all([
      fetchOrganizationsFull({ ids: [selectedOrganization.id] }),
      fetchUsers({ orgId: selectedOrganization.id, includeFirstRide: true }),
    ]).then(([orgs, users]) => {
      unstable_batchedUpdates(() => {
        setOrg(orgs[0]);
        setUsers(users);
        setLoading(false);
      });
    });
  };

  const filterUsers = (value: any) => {
    setSearchTerm(value);
  };

  const handleClose = (update: userOrgInterface | undefined) => {
    history.push(`${routes.users}`);
    if (update) {
      setUsers((us) => {
        const x = [...us];
        const idx = x.findIndex((u) => u.id === update.id);
        if (idx >= 0) {
          update.deleted ? x.splice(idx, 1) : (x[idx] = update);
        } else {
          x.push(update);
        }
        return x;
      });
    }
  };

  const customFieldOptions = org?.customFields
    .filter((f) => f.target === CUSTOM_FIELD_TARGET.USER)
    .map((c) => ({ value: c.id!, label: c.name }));
  const [activeCustomFields, setActiveCustomFields] = useState<string[]>([]);

  const extraFieldOptions = [{ value: "id", label: "User ID", props: { width: 250 } }];
  const [activeExtraFields, setActiveExtraFields] = useState<string[]>([]);

  const handleEdit = (row: userOrgInterface) => {
    history.push(`${routes.users}/edit/${row.id}`);
  };

  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState(1);

  const handleSortColumn = (dataKey: string, sortType: "desc" | "asc") => {
    setSortColumn(dataKey);
    setSortDirection(sortType === "asc" ? 1 : -1);
  };

  const filteredUsers = users
    .filter((user) => {
      return (
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (user.email || "").toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .toSorted((a, b) => {
      function doSort() {
        const ac = a[sortColumn] || "";
        const bc = b[sortColumn] || "";
        if (sortColumn === "roles") {
          const minLength = Math.min(ac.length, bc.length);
          for (let i = 0; i < minLength; i++) {
            if (ac[i] !== bc[i]) {
              return ac[i].localeCompare(bc[i]);
            }
          }
          return a.roles.length - b.roles.length;
        } else if (sortColumn === "firstRide") {
          return compareOpt(a.firstRide?.unix(), b.firstRide?.unix());
        } else if (activeCustomFields.includes(sortColumn)) {
          // custom field sort
          const field = org?.customFields.find((x) => x.id === sortColumn);
          const av =
            field &&
            customFieldValueDisplay(
              field,
              a.customFieldValues.find((x) => x.fieldId === sortColumn),
            );
          const bv =
            field &&
            customFieldValueDisplay(
              field,
              b.customFieldValues.find((x) => x.fieldId === sortColumn),
            );
          return compareOpt(av, bv, (avv, bvv) => avv.localeCompare(bvv));
        }

        return ac.localeCompare(bc);
      }

      return doSort() * sortDirection;
    });

  const convertToCSV = (data: userOrgInterface[]) => {
    const header = [
      "ID",
      "Name",
      "Email",
      "Phone",
      "First Ride",
      "Home Address",
      "City",
      "State",
      "Zip",
      "County",
      "Roles",
      "Flags",
    ];
    const customFields: customFieldInterface[] = activeCustomFields.map(
      (id) => org?.customFields?.find((x) => x.id === id)!,
    );
    header.push(...customFields.map((x) => x.name));

    const rows = data.map((user) => {
      const row = [
        user.id,
        '"' + user.name + '"',
        user.email,
        formatPhoneNumberDisplay(user.phone),
        user.firstRide?.format("YYYY-MM-DD"),
        user.homeAddress?.streetAddress,
        user.homeAddress?.city,
        user.homeAddress?.state,
        user.homeAddress?.zip,
        user.homeAddress?.county,
        user.roles.join(" & "),
        user.flags.join(" & "),
      ];

      customFields.forEach((field) => {
        row.push(
          customFieldValueDisplay(
            field,
            user.customFieldValues.find((x) => x.fieldId === field.id),
            " - ",
          ),
        );
      });

      return row;
    });

    return [header, ...rows].map((row) => row.join(",")).join("\n");
  };

  const downloadCSV = () => {
    const csv = convertToCSV(filteredUsers);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "user_list.csv";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 100);
  };

  return (
    <div className="Users">
      <div className="Users-toolbar">
        <div className="Users-toolbar-controls">
          <InputGroup>
            <Input placeholder="Find Users" onChange={filterUsers} />
            <InputGroup.Addon>
              <Icon icon="search" />
            </InputGroup.Addon>
          </InputGroup>
          <Link to={`${routes.users}/add`}>
            <Button>Add User</Button>
          </Link>
        </div>
        <div className="Users-toolbar-controls" style={{ gridGap: "5px" }}>
          {extraFieldOptions && (
            <CheckPicker
              data={extraFieldOptions}
              value={activeExtraFields}
              onChange={(v) => setActiveExtraFields(v)}
              placeholder="Extra Fields"
              placement="bottomStart"
              style={{ width: "200px" }}
            />
          )}
          {customFieldOptions && (
            <CheckPicker
              data={customFieldOptions}
              value={activeCustomFields}
              onChange={(v) => setActiveCustomFields(v)}
              placeholder="Custom Fields"
              placement="bottomEnd"
              style={{ width: "200px" }}
            />
          )}
          <Dropdown appearance="primary" title="Export" placement="bottomEnd" size="md">
            <Dropdown.Item onSelect={downloadCSV}>CSV</Dropdown.Item>
          </Dropdown>
        </div>
      </div>
      {loading ? (
        <Loader center content="Loading Users..." size="lg" />
      ) : (
        <div className="scroll-container">
          <Table
            autoHeight
            data={filteredUsers}
            affixHeader={0}
            onSortColumn={handleSortColumn}
            sortColumn={sortColumn}
            sortType={sortDirection === 1 ? "asc" : "desc"}
          >
            <Column sortable={true} width={300} resizable={true}>
              <HeaderCell>Name</HeaderCell>
              <BasicCell dataKey="name" />
            </Column>
            {activeExtraFields.map((id) => {
              const option = extraFieldOptions.find((x) => x.value === id);
              return (
                <Column resizable={true} {...option?.props}>
                  <HeaderCell>{option?.label}</HeaderCell>
                  <BasicCell dataKey={option?.value} />
                </Column>
              );
            })}
            <Column sortable={true} resizable={true} width={350}>
              <HeaderCell>Email</HeaderCell>
              <BasicCell dataKey="email" />
            </Column>
            <Column width={170} resizable={true}>
              <HeaderCell>Phone</HeaderCell>
              <PhoneNumberCell dataKey="phone" />
            </Column>
            <Column sortable={true} width={170} resizable={true}>
              <HeaderCell>First Ride</HeaderCell>
              <DateCell dataKey="firstRide" format="YYYY-MM-DD" />
            </Column>
            <Column sortable={true} resizable={true} width={150}>
              <HeaderCell>Roles</HeaderCell>
              <UserRolesCell dataKey="roles" />
            </Column>
            {isMBAdmin && (
              <Column width={75}>
                <HeaderCell>Flags</HeaderCell>
                <UserFlagsCell dataKey="flags" />
              </Column>
            )}
            {org &&
              activeCustomFields.map((id) => {
                const field = org.customFields.find((x) => x.id === id)!;
                return (
                  <Column width={150} resizable={true} sortable={true}>
                    <HeaderCell>{field.name}</HeaderCell>
                    <CustomFieldCell field={field} dataKey={field.id} />
                  </Column>
                );
              })}
            <Column width={55}>
              <HeaderCell>Action</HeaderCell>
              <ActionCell name="Edit" onClick={handleEdit} />
            </Column>
          </Table>
        </div>
      )}
      <Switch>
        <Route path={`${routes.users}/add`}>
          {org && <UserEditor onClose={handleClose} org={org} />}
        </Route>
        <Route path={`${routes.users}/edit/:id`}>
          {org && users.length && <UserEditBouncer onClose={handleClose} org={org} />}
        </Route>
      </Switch>
    </div>
  );
};

export default Users;
