import React, { useEffect, useState } from "react";
import useMBContext from "context/useMBContext";
import {
  Button,
  CheckPicker,
  Modal,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Schema,
  Notification,
  HelpBlock,
  Toggle,
  Dropdown,
  IconButton,
  Icon,
  InputGroup,
  AutoComplete,
} from "rsuite";
import "./UserEditor.scss";
import { formatEmail, formatPhoneNumber } from "misc/utils";
import {
  OrganizationUserFlags,
  OrganizationUserRoles,
  userOrgInterface,
  userOrgUpdateInterface,
} from "interfaces/user";
import { checkEmailRegistered, fetchAdminUserUpdate } from "api/user";
import { organizationInterface } from "interfaces/organization";
import {
  customFieldDefaultValue,
  customFieldInterface,
  customFieldLinkedInterface,
  customFieldValueUpdateInterface,
  CUSTOM_FIELD_TARGET,
} from "api/customfield";
import {
  AnyType,
  FormControlWrapper,
  FormOptionValueInput,
  makeFormError,
  mergeFormErrors,
} from "components/common/CustomFields/CustomFieldUtils";
import { addressToPlace, getAddresses, mapboxToAddress } from "api/mapbox";
import { geoPointDataInterface } from "interfaces/map";
import { selectInterface } from "interfaces/selectInterface";
import { addressInterface, placeInterface } from "interfaces/place";

export interface UserEditorInterface {
  user?: userOrgInterface;
  org: organizationInterface;
  onClose?: (update: userOrgInterface | undefined) => void;
}

export const UserEditor: React.FC<UserEditorInterface> = ({
  user,
  org,
  onClose,
}: UserEditorInterface) => {
  const { isMBAdmin } = useMBContext();
  const { StringType, ArrayType, BooleanType } = Schema.Types;
  const [isAddRole, setIsAddRole] = useState(false);
  const [emailInUse, setEmailInUse] = useState(false);

  const baseModel = Schema.Model({
    email: StringType().isEmail("Please enter a valid email"),
    phone: StringType().addRule((value, data) => {
      return !value || validatePhone(value);
    }, "Please enter a valid phone number."),
    confirmed: BooleanType(),
    deleted: BooleanType(),
  });

  // creating a new user
  const newUserModel = Schema.Model.combine(
    Schema.Model({
      name: StringType().isRequired("Please enter an user name"),
      roles: ArrayType().minLength(1, "Please select at least one role"),
    }),
    baseModel,
  );

  // adding an existing user to this org
  const addRoleUserModel = Schema.Model.combine(
    Schema.Model({
      roles: ArrayType().minLength(1, "Please select at least one role"),
    }),
    baseModel,
  );

  // editing an existing user's role in this org
  const editingUserModel = Schema.Model.combine(Schema.Model({}), baseModel);

  const model = user ? editingUserModel : isAddRole ? addRoleUserModel : newUserModel;

  const customFieldValueModel = Schema.Model({
    fieldId: StringType().isRequired(""),
    value: AnyType().addRule((value, data) => {
      if (value === true || value === false) return true;
      if (typeof value === "string" && value.length <= 0) return false;
      return true;
    }, "Please enter a non-empty value"),
  });

  const validatePhone = (phone) => {
    // test for US specific 10 digit phone formats with optional country code
    // or test for E.164 format (number up to 15 digit length starting with +)
    return (
      /^(\+[1-9]\d{1,14})$|^((\+?1)?)-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
        phone.trim(),
      ) && formatPhoneNumber(phone) !== null
    );
  };

  const checkAlreadyRegistered = (email) => {
    if (!isMBAdmin || !email) {
      setIsAddRole(false);
      setEmailInUse(false);
      return;
    }
    checkEmailRegistered({ email }).then((registered) => {
      setIsAddRole(registered && !user);
      setEmailInUse(!!user && user.email !== email && registered);
    });
  };

  const makeFormValue = (user?: userOrgInterface): userOrgUpdateInterface => {
    if (user) {
      var u = {
        ...user,
        phone: user.phone ? formatPhoneNumber(user.phone) : user.phone,
      };
      // wow typescript, nice one
      delete (u as Partial<userOrgInterface>).created;
      return u;
    } else {
      return {
        roles: org.userSettings.roles,
        flags: org.userSettings.flags,
        organizationId: org.id,
        confirmed: false,
        deleted: false,
        customFieldValues: [],
      };
    }
  };

  const [formValue, _setFormValue] = useState<userOrgUpdateInterface>(makeFormValue(user));

  const setFormValue = (value: userOrgUpdateInterface) => {
    _setFormValue({
      ...value,
      confirmed: value.confirmed || !value.email,
      phone: value.phone ? formatPhoneNumber(value.phone) : undefined,
      email: formatEmail(value.email),
    });
  };

  const [suggestions, setSuggestions] = useState<Array<selectInterface>>([]);
  const [addresses, setAddresses] = useState<Array<addressInterface>>([]);

  const handleSuggest = (value: string, event) => {
    value &&
      event.type === "change" &&
      getAddresses(value).then((data: geoPointDataInterface[]) => {
        const addresses = data.map(mapboxToAddress);
        const suggestions: Array<selectInterface> = addresses.map((place, index) => {
          const obj: selectInterface = {
            label: place.name,
            value: place.name,
          };
          return obj;
        });
        setAddresses(addresses);
        setSuggestions(suggestions);
      });
  };
  const handleSuggestSelect = (value: any, event) => {
    const place = addresses.find((place) => value.value === place.name);
    _setFormValue((f) => ({ ...f, homeAddress: place }));
  };

  const handleSubmit = (valid: boolean) => {
    if (!valid || emailInUse) return;
    fetchAdminUserUpdate({ user: formValue })
      .then((newUser) => {
        Notification.open({
          title: "Changes saved!",
          placement: "bottomStart",
        });
        onClose?.(newUser);
      })
      .catch((errors) => {
        Notification.open({
          title: "Could not save changes",
          description: `${JSON.stringify(errors?.[0])}`,
          placement: "bottomStart",
        });
      });
  };

  // link custom field children for the editor
  const internalFields: { [key: string]: customFieldInterface } = Object.fromEntries(
    org.customFields.filter((x) => x.target === CUSTOM_FIELD_TARGET.NONE).map((x) => [x.id!, x]),
  );
  const userCustomFields: customFieldLinkedInterface[] = org.customFields
    .filter((x) => x.target === CUSTOM_FIELD_TARGET.USER)
    .map((x) => ({
      ...x,
      options: x.options?.map((o) => ({
        ...o,
        child: o.childId ? internalFields[o.childId] : undefined,
      })),
    }));

  // custom fields in this organization that are not set yet on this user
  const availableCustomFields = userCustomFields.filter(
    (x) => !formValue.customFieldValues.some((c) => c.fieldId === x.id),
  );

  const updateCustomFields = (
    f: (fields: customFieldValueUpdateInterface[]) => customFieldValueUpdateInterface[],
  ) => {
    _setFormValue((v) => ({
      ...v,
      customFieldValues: f(v.customFieldValues),
    }));
  };

  const handleAddCustomField = (field: customFieldInterface) => {
    updateCustomFields((fields) => [
      {
        fieldId: field.id!,
        value: customFieldDefaultValue(field),
      },
      ...fields,
    ]);
  };

  const handleUpdateCustomFieldValue = (field: customFieldValueUpdateInterface) => {
    updateCustomFields((fields) => {
      const d = [...fields];
      const val = d.find((x) => x.fieldId === field.fieldId)!;
      val.value = field.value;
      val.child = field.child;
      return d;
    });
  };

  const handleRemoveCustomFieldValue = (fieldId: string) => {
    updateCustomFields((fields) => fields.filter((x) => x.fieldId !== fieldId));
  };

  const [formError, setFormError] = useState<Record<string, string | undefined>>({});

  useEffect(() => {
    const errors = {
      ...makeFormError(model.check(formValue as any)),
      ...mergeFormErrors({
        customFieldValueArray: formValue.customFieldValues.map((x) =>
          makeFormError(customFieldValueModel.check(x as any)),
        ),
      }),
    };
    setFormError(errors as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue.customFieldValues]);

  return (
    <Modal show={true} onHide={() => onClose?.(undefined)}>
      {formValue && (
        <Form
          fluid
          formValue={formValue}
          layout="horizontal"
          model={model}
          onSubmit={handleSubmit}
          onChange={(value) => {
            setFormValue(value as userOrgInterface);
          }}
        >
          <Modal.Header>
            <Modal.Title>
              {user ? "Edit User" : isAddRole ? "Add User to this Organization" : "Add New User"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(!isAddRole || user) && (
              <FormGroup>
                <ControlLabel>Name</ControlLabel>
                <FormControl name="name" readOnly={isAddRole} />
              </FormGroup>
            )}
            <FormGroup>
              <ControlLabel>Email</ControlLabel>
              <FormControl
                name="email"
                type="email"
                onBlur={() => {
                  checkAlreadyRegistered(formValue.email);
                }}
                errorMessage={emailInUse && "This email is already in use."}
              />
            </FormGroup>
            {(!isAddRole || user) && (
              <>
                <FormGroup>
                  <ControlLabel>Phone</ControlLabel>
                  <FormControl name="phone" readOnly={isAddRole} />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Confirmed</ControlLabel>
                  <FormControl
                    name="confirmed"
                    checked={formValue.confirmed}
                    readOnly={isAddRole}
                    accepter={Toggle}
                    style={{ marginTop: "6px" }}
                  />
                  <HelpBlock>
                    Allow the user to log in without verifying their email and phone number.
                  </HelpBlock>
                </FormGroup>
              </>
            )}
            {isMBAdmin && user && (
              <FormGroup>
                <ControlLabel>Deleted</ControlLabel>
                <FormControl
                  name="deleted"
                  checked={formValue.deleted}
                  accepter={Toggle}
                  style={{ marginTop: "6px" }}
                />
                <HelpBlock>User will be unable to drive or book rides.</HelpBlock>
              </FormGroup>
            )}
            <FormGroup>
              <ControlLabel>Roles</ControlLabel>
              <FormControl name="roles" accepter={CheckPicker} data={OrganizationUserRoles} />
            </FormGroup>
            {isMBAdmin && (
              <FormGroup>
                <ControlLabel>Flags</ControlLabel>
                <FormControl name="flags" accepter={CheckPicker} data={OrganizationUserFlags} />
              </FormGroup>
            )}
            <FormGroup>
              <ControlLabel>Home Address</ControlLabel>
              <InputGroup inside style={{ width: 300 }}>
                <AutoComplete
                  data={suggestions}
                  placeholder={formValue.homeAddress?.streetAddress}
                  onChange={handleSuggest}
                  onSelect={handleSuggestSelect}
                  filterBy={() => {
                    // need this to avoid substring-only matches
                    return true;
                  }}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <ControlLabel>City</ControlLabel>
              <FormControl
                readOnly={true}
                value={formValue.homeAddress?.city}
                style={{ width: 200 }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>State</ControlLabel>
              <FormControl
                readOnly={true}
                value={formValue.homeAddress?.state}
                style={{ width: 200 }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>ZIP Code</ControlLabel>
              <FormControl
                readOnly={true}
                value={formValue.homeAddress?.zip}
                style={{ width: 100 }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>County</ControlLabel>
              <FormControl
                readOnly={true}
                value={formValue.homeAddress?.county}
                style={{ width: 200 }}
              />
            </FormGroup>

            {!isAddRole && userCustomFields.length > 0 && (
              <div>
                <p>Custom Fields</p>
                {formValue.customFieldValues.map((value, index) => {
                  const field = userCustomFields.find((x) => x.id === value.fieldId)!;
                  return (
                    <FormGroup>
                      <ControlLabel>{field.name}</ControlLabel>
                      <FormControlWrapper
                        accepter={FormOptionValueInput}
                        field={field}
                        value={value}
                        name={"derp"}
                        errorMessage={
                          formError.customFieldValueArray?.[index]?.["value"] && (
                            <p>{formError.customFieldValueArray?.[index]?.["value"]}</p>
                          )
                        }
                        onChange={handleUpdateCustomFieldValue}
                        onClear={() => handleRemoveCustomFieldValue(value.fieldId)}
                      />
                    </FormGroup>
                  );
                })}
                {availableCustomFields.length > 0 && (
                  <Dropdown
                    renderTitle={() => {
                      return <IconButton appearance="primary" icon={<Icon icon="plus" />} circle />;
                    }}
                    placement="topStart"
                  >
                    {availableCustomFields.map((field) => (
                      <Dropdown.Item onSelect={() => handleAddCustomField(field)}>
                        {field.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit">Save</Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  );
};
