import React, { useRef, useState } from "react";
import "./VehiclesEditor.scss";
import useMBContext from "context/useMBContext";
import {
  Modal,
  Button,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  SelectPicker,
  Schema,
} from "rsuite";
import { states } from "constants/states";
import { fetchVehicleUpdate, vehicleInterface } from "api/vehicle";

export interface VehiclesEditorProps {
  onClose?: () => void | undefined;
  refreshData?: () => void | undefined;
  vehicle: vehicleInterface;
}

const VehiclesEditor: React.FC<VehiclesEditorProps> = ({
  onClose,
  refreshData,
  vehicle,
}: VehiclesEditorProps) => {
  const { selectedAccount, selectedOrganization } = useMBContext();
  const { StringType, NumberType } = Schema.Types;
  const validation = Schema.Model({
    year: NumberType().isRequired("Please enter the vehicle year"),
    make: StringType().isRequired("Please enter the vehicle make (e.g. Ford)"),
    model: StringType().isRequired("Please enter the vehicle model (e.g. Passenger Van)"),
    capacity: NumberType().isRequired("Please enter the vehicle seating capacity"),
    state: StringType().isRequired("Please enter the state where the vehicle is registered"),
    licensePlate: StringType().isRequired("Please enter the vehicle's license plate"),
    color: StringType(),
    description: StringType(),
  });
  const [formValue, setFormValue] = useState<vehicleInterface>(vehicle);
  const editor = useRef();
  const [editorError, setEditorError] = useState<string>("");
  const [editorLoading, setEditorLoading] = useState<boolean>(false);

  const handleSubmit = (valid: boolean) => {
    if (!valid || !selectedOrganization) return;

    setEditorLoading(true);
    fetchVehicleUpdate({ vehicle: formValue })
      .then((veh) => {
        refreshData?.();
        setEditorLoading(false);
        onClose?.();
      })
      .catch((errors) => {});
  };
  return (
    <Modal show={true} onHide={onClose}>
      <Form
        fluid
        formValue={formValue}
        layout="horizontal"
        model={validation}
        onSubmit={handleSubmit}
        ref={editor}
        onChange={(value) => {
          setFormValue(value as vehicleInterface);
        }}
      >
        <Modal.Header>
          <Modal.Title>{!vehicle.id ? "Add New Vehicle" : "Edit Vehicle"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <ControlLabel>Year</ControlLabel>
            <FormControl name="year" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Make</ControlLabel>
            <FormControl name="make" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Model</ControlLabel>
            <FormControl name="model" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Seating Capacity</ControlLabel>
            <FormControl name="capacity" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>State</ControlLabel>
            <FormControl
              name="state"
              data={states}
              style={{ width: 300 }}
              accepter={SelectPicker}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>License Plate</ControlLabel>
            <FormControl name="licensePlate" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Color</ControlLabel>
            <FormControl name="color" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Description</ControlLabel>
            <FormControl rows={2} name="description" componentClass="textarea" />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <div className="form-error">{editorError}</div>
          <Button type="submit" loading={editorLoading}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default VehiclesEditor;
