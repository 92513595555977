import React, { useEffect, useState, Suspense } from "react";
import "./Map.scss";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import MapMarkerPin from "components/common/Icon/MapMarkerPin";
import { mapLine, mapPin } from "interfaces/map";
import MapLine from "components/Map/mapline";
import "mapbox-gl/dist/mapbox-gl.css";
import { LngLatBounds } from "mapbox-gl";
import { FitBounds } from "react-mapbox-gl/lib/map";
import { placeInterface } from "interfaces/place";

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MB_TOKEN ? process.env.REACT_APP_MB_TOKEN : "",
  attributionControl: false,
  logoPosition: "bottom-right",
});

interface MapInterface {
  places?: Array<placeInterface>;
  pins?: Array<mapPin>;
  lines?: Array<mapLine>;
  selectedBounds?: LngLatBounds | null;
  handleClick?: (lat: number, lng: number) => void;
}

const Mapbox: React.FC<MapInterface> = ({
  places = [],
  pins = [],
  lines = [],
  selectedBounds = null,
  handleClick,
}: MapInterface) => {
  const defaultBounds = new LngLatBounds({ lng: -118.2, lat: 34.0 }, { lng: -118.3, lat: 34.1 });
  const [bounds, setBounds] = useState<LngLatBounds>(selectedBounds || defaultBounds);

  useEffect(() => {
    if (selectedBounds) {
      setBounds(selectedBounds.isEmpty() ? defaultBounds : selectedBounds);
    }
  }, [selectedBounds]);

  const mapClick = (map, e) => {
    handleClick && handleClick(e.lngLat.lat, e.lngLat.lng);
  };

  return (
    <>
      <Map
        style="mapbox://styles/magicbus/cle38orav001f01p559os5nw1"
        fitBounds={bounds.toArray() as FitBounds}
        fitBoundsOptions={{ padding: 50, duration: 0 }}
        containerStyle={{
          height: "100%",
          width: "100%",
        }}
        onClick={mapClick}
      >
        <Suspense fallback={null}>
          {pins &&
            pins.map((pin, index) => (
              <Marker
                key={`mapmarker${pin.id}`}
                coordinates={[
                  Number(pin.place.coordinates!.lng),
                  Number(pin.place.coordinates!.lat),
                ]}
                style={{ width: "35px" }}
                anchor="bottom"
              >
                <MapMarkerPin
                  key={`mapmarkerpin${pin.id}`}
                  iconName="pickup"
                  data={{ type: "Pickup" }}
                  pinNumber={pin.pinNumber}
                  pinName={pin.label}
                />
              </Marker>
            ))}
          {places &&
            places.map((place, index) => (
              <Marker
                key={`mapmarker${place.id}`}
                coordinates={[Number(place.coordinates!.lng), Number(place.coordinates!.lat)]}
                style={{ width: "35px" }}
                anchor="bottom"
              >
                <MapMarkerPin
                  key={`mapmarker${place.id}`}
                  iconName="pickup"
                  data={{ type: "Pickup" }}
                  pinNumber={index + 1}
                  pinName={place.name}
                />
              </Marker>
            ))}
          {lines &&
            Object.values(lines).map((line, index) => (
              <MapLine key={index} routeID={index} polyline={line.polyline} color={line.color} />
            ))}
        </Suspense>
      </Map>
    </>
  );
};

export default Mapbox;
