import { fetchNTDReport } from "api/reports";
import useMBContext from "context/useMBContext";
import "./Reporting.scss";
import { ntd_average, ntd_mr20, ntd_report } from "interfaces/reporting";
import { routes } from "misc/http";
import moment from "moment";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Checkbox,
  DateRangePicker,
  Loader,
  Radio,
  RadioGroup,
  Table,
  TableCellProps,
  TableProps,
} from "rsuite";
import { RangeType } from "rsuite/lib/DateRangePicker";
import ExcelJS from "exceljs";

const { Cell, Column, HeaderCell } = Table;

interface Row {
  name: string;
  key: ((a: ntd_average) => any) | string;
  values?: any[];
  cellF?: (a: any) => React.ReactNode;
}

interface NTD_S10_ReportProps {
  report: ntd_report;
  exportHandler?: (any) => void;
}

interface MyTableCellProps extends TableCellProps {
  type?: "int" | "text" | "checkbox";
  index?: number;
  dataF?: (data: any) => any;
}

const DataCell: React.FC<MyTableCellProps> = ({
  rowData,
  type = "int",
  index,
  dataKey,
  dataF,
  ...rest
}) => {
  const rd: any = rowData;
  const value = dataF
    ? dataF(rowData)
    : dataKey
    ? rowData?.[dataKey]
    : index !== undefined
    ? rd?.values[index]
    : undefined;
  const cellF = !dataKey ? rd.cellF : undefined;

  return (
    <Cell {...rest}>
      {cellF ? (
        cellF(value)
      ) : type === "int" ? (
        <>{value !== null ? (isNaN(+value) ? value : Math.trunc(value)) : "N/A"}</>
      ) : type === "checkbox" ? (
        <>checkbox</>
      ) : (
        <>{value}</>
      )}
    </Cell>
  );
};

const formatTime = (num: number): string => {
  return moment()
    .hours((num / 100) % 24)
    .minutes(num % 100)
    .format("HH:mm");
};

const CommonTable: React.FC<TableProps> = (props) => (
  <Table {...props} autoHeight>
    <Column width={400}>
      <HeaderCell>Field</HeaderCell>
      <DataCell dataKey="name" type="text" />
    </Column>
    <Column flexGrow={1}>
      <HeaderCell>Average Weekday Schedule</HeaderCell>
      <DataCell index={0} />
    </Column>
    <Column flexGrow={1}>
      <HeaderCell>Average Saturday Schedule</HeaderCell>
      <DataCell index={1} />
    </Column>
    <Column flexGrow={1}>
      <HeaderCell>Average Sunday Schedule</HeaderCell>
      <DataCell index={2} />
    </Column>
    <Column flexGrow={1}>
      <HeaderCell>Annual Total</HeaderCell>
      <DataCell index={3} />
    </Column>
  </Table>
);

const exportDataToExcel = async (workbook: ExcelJS.Workbook, name: string) => {
  // Save the workbook to a file
  // Create a download link
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${name}.xlsx`;
  link.click();

  // Clean up the temporary URL
  URL.revokeObjectURL(url);
};

interface NTD_S10_ReportRef {
  export: () => void;
}

const NTD_S10_Report = forwardRef<NTD_S10_ReportRef, NTD_S10_ReportProps>(({ report }, ref) => {
  const { selectedOrganization } = useMBContext();
  const [selected, setSelected] = useState<number>(0);
  const isFull = selected === 1;

  const data = useMemo(() => {
    const total = Object.values(report.report.s10.averages).reduce((a, b) => ({
      begin: Math.min(a.begin, b.begin),
      end: Math.max(a.end, b.end),
      not_operated_emergency: a.not_operated_emergency + b.not_operated_emergency,
      not_operated_strike: a.not_operated_strike + b.not_operated_strike,
      operated: a.operated + b.operated,
      voms: Math.max(a.voms, b.voms),
      pmt: a.pmt + b.pmt,
      vah: a.vah + b.vah,
      vrh: a.vrh + b.vrh,
      vam: a.vam + b.vam,
      vrm: a.vrm + b.vrm,
      upt: a.upt + b.upt,
      range: a.range,
    }));
    const three = Object.values(report.report.s10.averages);
    const all = [...three, total];

    const maxData: Row[] = [
      {
        name: "",
        key: "",
        values: [report.report.s10.voms, report.report.s10.vams],
      },
    ];

    const makeData = (schema: Row[]) =>
      schema.map((s) => {
        return {
          ...s,
          values: all.map((x) => (typeof s.key === "function" ? s.key(x) : x[s.key])),
        };
      });

    const periodSchema: Row[] = [
      {
        name: "Time Service Beings",
        key: (a) => (a.operated > 0 ? formatTime(a.begin) : "N/A"),
      },
      {
        name: "Time Service Ends",
        key: (a) => (a.operated > 0 ? formatTime(a.end) : "N/A"),
      },
      {
        name: "Ends Next Day",
        key: (a) => a.end >= 2400,
        cellF: (value: any) => <Checkbox checked={value as boolean} />,
      },
    ];
    const periodData: Row[] = makeData(periodSchema);

    const suppliedSchema: Row[] = [
      {
        name: "Vehicles in Operation",
        key: "voms",
      },
      {
        name: "Total Actual Vehicle Miles",
        key: "vam",
      },
      {
        name: "Total Actual Vehicle Revenue Miles (VRM)",
        key: "vrm",
      },
      ...(isFull
        ? [
            {
              name: "Deadhead Miles",
              key: "deadhead",
            },
            {
              name: "Total Scheduled Vehicle Revenue Miles",
              key: "scheduled",
            },
          ]
        : []),
      {
        name: "Total Actual Vehicle Hours",
        key: "vah",
      },
      {
        name: "Total Actual Vehicle Revenue Hours (VRH)",
        key: "vrh",
      },
      ...(isFull
        ? [
            {
              name: "Deadhead hours",
              key: "deadhead_hours",
            },
            {
              name: "Charter Service Hours",
              key: "charter_hours",
            },
            {
              name: "School Bus Hours",
              key: "school_hours",
            },
          ]
        : []),
    ];
    const suppliedData: Row[] = makeData(suppliedSchema);

    const consumedSchema: Row[] = [
      {
        name: "Unlinked Passenger Trips (UPT)",
        key: "upt",
      },
      {
        name: "Passenger Miles Traveled (PMT)",
        key: "pmt",
      },
    ];
    const consumedData: Row[] = makeData(consumedSchema);

    const operatedSchema: Row[] = [
      {
        name: "Days Operated",
        key: "operated",
      },
      {
        name: "Days Not Operated (Strikes)",
        key: "not_operated_strike",
      },
      {
        name: "Days Not Operated (Officially Declared Emergencies)",
        key: "not_operated_emergency",
      },
    ];
    const operatedData: Row[] = makeData(operatedSchema);

    return {
      maxData,
      periodData,
      suppliedData,
      consumedData,
      operatedData,
    };
  }, [report, isFull]);

  // eslint-disable-line react-hooks/exhaustive-deps
  const doExport = useCallback(() => {
    const workbook = new ExcelJS.Workbook();

    {
      const sheets10 = workbook.addWorksheet("NTD S-10");
      sheets10.getColumn(1).width = 38;
      sheets10.getColumn(1).font = { size: 9 };
      sheets10.getColumn(2).font = { size: 9 };
      sheets10.getColumn(3).font = { size: 9 };
      sheets10.getColumn(4).font = { size: 9 };
      sheets10.getColumn(5).font = { size: 9 };
      const endCol = 5;
      const addDataTable = (title: string, data: Row[], rowIndex: number, legend: string[]) => {
        const sectionRow = sheets10.getRow(rowIndex++);
        sectionRow.values = [title];
        sectionRow.font = { bold: true, size: 11 };
        sheets10.mergeCells(rowIndex - 1, 1, rowIndex - 1, endCol);

        const headRow = sheets10.getRow(rowIndex++);
        headRow.alignment = { wrapText: true };
        headRow.font = { italic: true, size: 9 };
        headRow.values = legend;
        headRow.height = 35;

        data.forEach((row) => {
          const dataRow = sheets10.getRow(rowIndex++);
          const values = row.values?.map((v) => (v as string) ?? "") ?? [];
          if (row.name.length > 0) {
            values.unshift(row.name);
          }
          dataRow.values = values;
        });
        return rowIndex;
      };

      let rowIndex = 1;
      const headerRow = sheets10.getRow(rowIndex++);
      headerRow.values = ["NTD Service Non-Rail (S-10) - CB DO"];
      headerRow.font = { bold: true, size: 12 };
      sheets10.mergeCells(rowIndex - 1, 1, rowIndex - 1, 5);

      const dateRow = sheets10.getRow(rowIndex++);
      dateRow.values = [report.dateStart.format("yyyy/MM"), report.dateEnd.format("yyyy/MM")];

      ++rowIndex;
      const maxData = {
        ...data.maxData[0],
        values: [data.maxData[0].values?.[0], "", data.maxData[0].values?.[1]],
      };
      rowIndex = addDataTable("Maximum Service Vehicles", [maxData], rowIndex, [
        "Vehicles Operated in Annual Maximum Service (VOMS)",
        "",
        "Vehicles Available for Annual Maximum Service (VAMS)",
      ]);
      sheets10.mergeCells(rowIndex - 2, 1, rowIndex - 2, 2);
      sheets10.mergeCells(rowIndex - 1, 1, rowIndex - 1, 2);
      sheets10.mergeCells(rowIndex - 2, 3, rowIndex - 2, 5);
      sheets10.mergeCells(rowIndex - 1, 3, rowIndex - 1, 5);

      const headers = [
        "Field",
        "Average Weekday Schedule",
        "Average Saturday Schedule",
        "Average Sunday Schedule",
        "Annual Total",
      ];
      rowIndex = addDataTable(
        "Periods of Service",
        data.periodData.map((x) => ({ ...x, values: x.values?.slice(0, 3) })),
        rowIndex + 1,
        headers.slice(0, 4),
      );
      rowIndex = addDataTable("Services Supplied", data.suppliedData, rowIndex + 1, headers);
      rowIndex = addDataTable("Services Consumed", data.consumedData, rowIndex + 1, headers);
      rowIndex = addDataTable("Services Operated (Days)", data.operatedData, rowIndex + 1, headers);
    }

    {
      const sheets20 = workbook.addWorksheet("NTD MR-20");
      sheets20.getColumn(1).width = 15;
      for (let i = 0; i < 8; ++i) {
        sheets20.getColumn(i + 1).font = { size: 9 };
      }
      const ji = report.report.mr20s.some((x) => !!x.totalByTag["ji"]);
      const endCol = 5;

      let rowIndex = 1;
      const sectionRow = sheets20.getRow(rowIndex++);
      sectionRow.values = ["NTD Monthly Ridership Form (MR-20) - HR-DO"];
      sectionRow.font = { bold: true, size: 12 };
      sheets20.mergeCells(rowIndex - 1, 1, rowIndex - 1, endCol);

      const dateRow = sheets20.getRow(rowIndex++);
      dateRow.values = [report.dateStart.format("yyyy/MM"), report.dateEnd.format("yyyy/MM")];

      ++rowIndex;
      const headerRow = sheets20.getRow(rowIndex++);
      headerRow.font = { size: 9, italic: true };
      const headerValues = [
        "Form",
        "UPT",
        "VRM",
        "VRH",
        "VOMS",
        "Total Riders",
        "New Riders",
        "Seat Capacity",
      ];
      if (ji) headerValues.push("JI Riders");
      headerRow.values = headerValues;

      report.report.mr20s.forEach((mr20) => {
        const dataRow = sheets20.getRow(rowIndex++);
        const values = [
          mr20.date.format("yyyy/MM"),
          mr20.upt,
          mr20.vrm,
          mr20.vrh,
          mr20.voms,
          mr20.total,
          mr20.new,
          mr20.capacity,
        ];
        if (ji) {
          values.push(mr20.totalByTag["ji"] ?? 0);
        }
        dataRow.values = values;
      });
    }

    {
      // Custom 2CI Schema "Avg Schedule"
      const sheet = workbook.addWorksheet("Avg Schedule");

      let rowIndex = 1;
      const headerRow = sheet.getRow(rowIndex++);
      headerRow.values = [
        "Name",
        "Type of Day",
        "Start",
        "End",
        "Time Service Begins",
        "Time Service Ends",
        "Ends Next Day",
        "Vehicles in Operation",
        "Total Actual Vehicle Miles",
        "Total Actual Vehicle Revenue Miles (VRM)",
        "Total Actual Vechicle Hours",
        "Total Actual Vehicle Revenue Hours (VRH)",
        "Unlinked Passenger Trips (UPT)",
        "Passenger Miles Traveled (PMT)",
        "Days Operated",
        "Days Not Operated (Strikes)",
        "Days Not Operated (Officially Declared Emergencies)",
      ];
      headerRow.alignment = {
        textRotation: 60,
        vertical: "bottom",
        horizontal: "left",
      };
      headerRow.font = { size: 9 };
      headerRow.height = 200;
      sheet.getColumn(1).width = 20;

      const typeOfDay = ["Weekday", "Saturday", "Sunday"];
      for (let i = 0; i < 3; ++i) {
        const row = sheet.getRow(rowIndex++);
        const suppliedData = data.suppliedData.map((v) => v.values?.[i]);
        row.values = [
          selectedOrganization?.name,
          typeOfDay[i],
          report.dateStart.format("yyyy/MM"),
          report.dateEnd.format("yyyy/MM"),
          ...data.periodData.map((v) => v.values?.[i]).slice(0, 2),
          data.periodData[2].values?.[i] ? "TRUE" : "FALSE",
          ...(isFull ? suppliedData.slice(0, 3).concat(suppliedData.slice(5, 7)) : suppliedData),
          ...data.consumedData.map((v) => v.values?.[i]),
          ...data.operatedData.map((v) => v.values?.[i]),
        ];
      }
    }

    {
      // Custom 2CI Schema "Maximum"
      const sheet = workbook.addWorksheet("Maximum");

      let rowIndex = 1;
      const headerRow = sheet.getRow(rowIndex++);
      headerRow.values = [
        "Name",
        "Start",
        "End",
        "Vehicles Operated in Annual Maximum Service (VOMS)",
        "Vehicles Available for Annual Maximum Service (VAMS)",
      ];
      headerRow.alignment = {
        textRotation: 60,
        vertical: "bottom",
        horizontal: "left",
      };
      headerRow.font = { size: 9 };
      headerRow.height = 200;
      sheet.getColumn(1).width = 20;

      const row = sheet.getRow(rowIndex++);
      row.values = [
        selectedOrganization?.name,
        report.dateStart.format("yyyy/MM"),
        report.dateEnd.format("yyyy/MM"),
        data.maxData[0]?.values?.[0],
        data.maxData[0]?.values?.[1],
      ];
    }

    {
      const sheet = workbook.addWorksheet("Monthly");
      sheet.getColumn(1).width = 20;
      const ji = report.report.mr20s.some((x) => !!x.totalByTag["ji"]);

      let rowIndex = 1;
      const headerRow = sheet.getRow(rowIndex++);
      const headerValues = [
        "Name",
        "Form",
        "UPT",
        "VRM",
        "VRH",
        "VOMS",
        "Total Riders",
        "New Riders",
        "Seat Capacity",
      ];
      if (ji) headerValues.push("JI Riders");
      headerRow.values = headerValues;

      report.report.mr20s.forEach((mr20) => {
        const dataRow = sheet.getRow(rowIndex++);
        const values = [
          selectedOrganization?.name,
          mr20.date.format("yyyy/MM"),
          mr20.upt,
          mr20.vrm,
          mr20.vrh,
          mr20.voms,
          mr20.total,
          mr20.new,
          mr20.capacity,
        ];
        if (ji) {
          values.push(mr20.totalByTag["ji"] ?? 0);
        }
        dataRow.values = values;
      });
    }

    exportDataToExcel(
      workbook,
      `${selectedOrganization?.name} NTD S-10 MR-20 ${report.dateStart.format(
        "yyyy-MM",
      )} - ${report.dateStart.format("yyyy-MM")}`,
    );
  }, [data, report, selectedOrganization, isFull]);

  useImperativeHandle(ref, () => ({ export: doExport }), [doExport]);

  return (
    <div className="NTD">
      <h1>NTD Service Non-Rail (S-10) - CB DO</h1>
      <h4>
        {report.dateStart.format("yyyy - MMMM")} through {report.dateEnd.format("yyyy - MMMM")}
      </h4>
      <div className="divider" />
      <RadioGroup
        inline
        value={selected}
        onChange={(value) => {
          setSelected(value);
        }}
      >
        <Radio value={0}>Brief</Radio>
        <Radio value={1}>Full</Radio>
      </RadioGroup>

      <div className="divider" />
      <h3 className="section">Maximum Service Vehicles</h3>
      <Table data={data.maxData} autoHeight style={{ width: "100%" }}>
        <Column flexGrow={1}>
          <HeaderCell>Vehicles Operated in Annual Maximum Service (VOMS)</HeaderCell>
          <DataCell index={0} />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Vehicles Available for Annual Maximum Service (VAMS)</HeaderCell>
          <DataCell index={1} />
        </Column>
      </Table>

      <div className="divider" />
      <h3 className="section">Periods of Service</h3>
      <Table data={data.periodData} autoHeight>
        <Column width={200}>
          <HeaderCell>Field</HeaderCell>
          <DataCell dataKey="name" type="text" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Average Weekday Schedule</HeaderCell>
          <DataCell index={0} />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Average Saturday Schedule</HeaderCell>
          <DataCell index={1} />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Average Sunday Schedule</HeaderCell>
          <DataCell index={2} />
        </Column>
      </Table>

      <div className="divider" />
      <h3 className="section">Services Supplied</h3>
      <CommonTable data={data.suppliedData} />

      <div className="divider" />
      <h3 className="section">Services Consumed</h3>
      <CommonTable data={data.consumedData} />

      <div className="divider" />
      <h3 className="section">Services Operated (Days)</h3>
      <CommonTable data={data.operatedData} />

      <div className="divider" />
      <h1>NTD Monthly Ridership Form (MR-20) - HR-DO</h1>
      <div className="divider" />
      <Table data={report.report.mr20s} autoHeight>
        <Column width={200}>
          <HeaderCell>Form</HeaderCell>
          <DataCell dataF={(row) => row.date.format("yyyy - MMMM")} type="text" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>UPT</HeaderCell>
          <DataCell dataKey="upt" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>VRM</HeaderCell>
          <DataCell dataF={(data: ntd_mr20) => data.vrm.toFixed(2)} type="text" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>VRH</HeaderCell>
          <DataCell dataF={(data: ntd_mr20) => data.vrh.toFixed(2)} type="text" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>VOMS</HeaderCell>
          <DataCell dataKey="voms" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Total Riders</HeaderCell>
          <DataCell dataKey="total" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>New Riders</HeaderCell>
          <DataCell dataKey="new" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Seat Capacity</HeaderCell>
          <DataCell dataKey="capacity" />
        </Column>
        {report.report.mr20s.some((x) => !!x.totalByTag["ji"]) && (
          <Column flexGrow={1}>
            <HeaderCell>JI Riders</HeaderCell>
            <DataCell dataF={(data: ntd_mr20) => data.totalByTag["ji"]} />
          </Column>
        )}
      </Table>
      <div className="divider" />
    </div>
  );
});

interface Params {
  startDate?: string;
  endDate?: string;
}

const quickSelectOptions = () => {
  var start = moment().startOf("month").add(3, "month").startOf("year").subtract(3, "month");

  // YTD and last 7 years
  const options: RangeType[] = [
    {
      label: `FY ${start.year() + 1} - YTD`,
      value: [start.toDate(), moment().toDate()],
    },
  ];
  for (var i = -1; i > -6; --i) {
    start = moment(start).add(-1, "year");
    options.push({
      label: `FY ${start.year() + 1}`,
      value: [start.toDate(), moment(start).add(12, "month").subtract(1, "day").toDate()],
    });
  }
  return options;
};

interface NTDReportPageProps {}

export const NTDReportPage: React.FC<NTDReportPageProps> = ({}) => {
  const { selectedAccount, selectedOrganization } = useMBContext();

  const params: Params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
  const options = quickSelectOptions();
  const [dateRange, setDateRange] = useState<Date[]>(
    [
      params.startDate ? moment(params.startDate) : moment(options[0].value[0]),
      params.endDate ? moment(params.endDate) : moment(options[0].value[1]!),
    ].map((x) => x.toDate()),
  );
  const [ntdReport, setNtdReport] = useState<ntd_report>();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!selectedOrganization) return;
    setLoading(true);
    fetchNTDReport({
      startDate: dateRange[0],
      endDate: dateRange[1],
      orgId: selectedOrganization?.id,
      accountId: selectedAccount?.id,
    })
      .then((report) => {
        setNtdReport(report);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedAccount, selectedOrganization, dateRange]);

  const handleChangeReport = (value) => {
    history.push(`${routes.reporting}/${value}`);
  };

  const exportHandlerRef = useRef<any>();

  return (
    <div className="Reporting">
      <div className="Reporting-toolbar">
        <div className="Reporting-toolbar-controls">
          <div className="Reporting-toolbar-control-left">
            <RadioGroup inline appearance="picker" defaultValue="ntd" onChange={handleChangeReport}>
              <Radio value="default">Default</Radio>
              <Radio value="ntd">NTD</Radio>
            </RadioGroup>
          </div>
        </div>
        <div className="Reporting-toolbar-controls">
          <div className="Reporting-toolbar-control">
            <DateRangePicker
              value={dateRange as [Date?, Date?]}
              ranges={options}
              placement={"bottomEnd"}
              onChange={(date: any) => setDateRange(Array.isArray(date) ? date : [date])}
            />
          </div>
          <div className="Reporting-toolbar-control">
            <Button
              appearance="primary"
              title="Export"
              placement="bottomEnd"
              size="md"
              onClick={() => exportHandlerRef.current?.export?.()}
            >
              Export
            </Button>
          </div>
        </div>
      </div>
      {loading && <Loader center content="Loading Report..." size="lg" />}
      {!loading && ntdReport && <NTD_S10_Report report={ntdReport} ref={exportHandlerRef} />}
      <div style={{ height: "50px" }}></div>
    </div>
  );
};
