import { gqlOp, GQLSchema } from "misc/http";
import { placeInterface } from "interfaces/place";
import { slice } from "misc/utils";

const placeSchema: GQLSchema = {
  params: {
    accountId: "String",
    orgId: "String",
    ids: "[String]",
  },
  name: "places",
  op: "query",
};

export const fetchPlaces = (params: { accountId?: string; orgId: string; ids?: string[] }) => {
  return gqlOp<placeInterface[]>(placeSchema, params, [new placeInterface()]);
};

const placeUpdateSchema: GQLSchema = {
  params: {
    accountId: "String",
    orgId: "String",
    place: "PlaceUpdateInput!",
  },
  name: "placeUpdate",
  op: "mutation",
};

export const fetchPlaceUpdate = (params: {
  accountId?: string;
  orgId?: string;
  place: placeInterface;
}) => {
  const p = {
    ...params,
    place: slice(new placeInterface(), params.place as placeInterface),
  };
  return gqlOp(placeUpdateSchema, p, new placeInterface());
};
