import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";
import "./App.scss";
import ErrorBoundary from "components/ErrorBoundary";
import Header from "components/common/Header";
import Places from "pages/Places";
import Pools from "pages/Pools";
import NotFoundPage from "pages/NotFoundPage";
import Users from "../Users";
import Vehicles from "../Vehicles";
import Waitlist from "../Waitlist";
import Reporting from "../Reporting";
import { routes } from "misc/http";
import useMBContext from "context/useMBContext";
import Organizations from "components/Organizations";

const Content = () => {
  const { adminUser, loadingAdminUser } = useMBContext();
  const history = useHistory();

  useEffect(() => {
    if (!loadingAdminUser && !adminUser) {
      const path = history.location;
      const url = `${routes.user.login}?return=${process.env.REACT_APP_MY_URL}${path.pathname}`;
      window.open(url, "_self");
    }
  }, [adminUser, loadingAdminUser]);

  return (
    <>
      {adminUser && (
        <main className="App">
          <Header className="App-Header" />
          <div className="App-Interface">
            <React.Suspense fallback={null}>
              <Switch>
                <Route path={routes.users}>
                  <Users />
                </Route>
                <Route path={routes.vehicles}>
                  <Vehicles />
                </Route>
                <Route path={routes.places}>
                  <Places />
                </Route>
                <Route path={routes.pools}>
                  <Pools />
                </Route>
                <Route path={routes.waitlist}>
                  <Waitlist />
                </Route>
                <Route path={routes.reporting}>
                  <Reporting />
                </Route>
                <Route path={routes.organizations}>
                  <Organizations />
                </Route>
                <Route path="/" exact>
                  <Redirect to={routes.reporting} />
                </Route>
                <Route path="*" exact>
                  <NotFoundPage />
                </Route>
              </Switch>
            </React.Suspense>
          </div>
        </main>
      )}
    </>
  );
};

const App: React.FC = () => {
  const { adminUser, loadingAdminUser } = useMBContext();

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Content />
      </ErrorBoundary>
    </BrowserRouter>
  );
};
export default App;
