import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  poolInterface,
  selectItemInterface,
  updateServiceInterface,
  vanpoolServiceUpdateInterface,
  VANPOOL_SERVICE_UPDATE_APPLY,
} from "interfaces/pool";
import { stopDisplayInterface, stopInterface, STOP_TYPE } from "interfaces/stop";
import { pricingInterface } from "interfaces/organization";
import {
  Button,
  Checkbox,
  CheckPicker,
  DatePicker,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Message,
  Schema,
  CheckboxGroup,
  Toggle,
  SelectPicker,
} from "rsuite";
import DayPicker from "components/common/DayPicker";
import moment from "moment";
import { FormInstance } from "rsuite/lib/Form";
import "./PoolsEditor.scss";
import { estimateOneWayPricing } from "misc/utils";
import { serviceInterface, stopMappingInterface } from "interfaces/schedule";
import FormCurrencyInput from "components/common/CurrencyInput";
import { placeInterface } from "interfaces/place";
import {
  computeRoute,
  makeSchedule,
  makeStop,
  makeStopDisplay,
  serviceBookableOn,
} from "./PoolsEditorUtils";
import { ScheduleList, UPDATE_TYPE } from "./ScheduleEditor";
import { ServicePanel } from "./ServicePanel";
import useMBContext from "context/useMBContext";

interface serviceFormInterface {
  id?: string;
  shortName?: string;
  description?: string;
  minStartDate?: Date;
  startDate?: Date;
  endDate?: Date;
  outboundSchedule: stopDisplayInterface[];
  inboundSchedule: stopDisplayInterface[];
  days: string[];
  stops: string[];
  roundTrip: boolean[];
  adhocPricing: boolean;
  adhocPricingBaseRate?: number;
  adhocPricingPerMileRate?: number;
  stopMappingPreviousPlaces: string[];
  stopMapping: stopMappingInterface;
}

const scheduleValidationRule = (value: any) => {
  const items: stopDisplayInterface[] = value;

  // departure must be after arrival
  return (
    items.every((item) => {
      return item.arrivalDate <= item.departureDate;
    }) &&
    // item[index].arrival must be after item[index-1].departure
    items.every((item, index, all) => {
      return index == 0 || all[index - 1].departureDate < item.arrivalDate;
    })
  );
};

const { StringType, ArrayType, DateType, ObjectType } = Schema.Types;
const serviceModel = Schema.Model({
  shortName: StringType(),
  description: StringType(),
  days: ArrayType().minLength(1, "Service must run at least once per week"),
  stops: ArrayType().minLength(2, "Please select at least two places"),
  startDate: DateType()
    .isRequired("Please enter a start date")
    .addRule((value, data) => {
      return !data.minStartDate || value >= data.minStartDate;
    }, "Start date must be after previous service end date"),
  endDate: DateType().addRule((value, data) => {
    return !value || moment(value).isAfter(moment(data.startDate), "day");
  }, "Service cannot end before it starts"),
  outboundSchedule: ArrayType().addRule((value, data) => {
    return scheduleValidationRule(value);
  }),
  inboundSchedule: ArrayType().addRule((value, data) => {
    return (data.roundTrip || []).length === 0 || scheduleValidationRule(value);
  }),
  stopMapping: ObjectType().addRule((value: stopMappingInterface, data: serviceFormInterface) => {
    // previous place ids must be in outbound.stops or in the stop mapping
    const current = data.stops.concat(...(value?.stops.map((x) => x.oldId) || []));
    return data.stopMappingPreviousPlaces.every((x) => current.includes(x));
  }),
});

interface ServiceItemProps {
  vanpool: poolInterface;
  service: serviceInterface;
  previousService?: serviceInterface;
  places: selectItemInterface<placeInterface>[];
  setRoute;
  editing?: boolean;
  canEdit?: boolean;
  isMostRecent?: boolean;
  onSubmit?: (a: vanpoolServiceUpdateInterface) => void;
  onEdit?: (editing: boolean) => void;
}

export const ServiceItem: React.FC<ServiceItemProps> = ({
  vanpool,
  service,
  previousService,
  places,
  setRoute,
  editing = false,
  canEdit = false,
  isMostRecent = false,
  onSubmit,
  onEdit,
}) => {
  const { isMBAdmin } = useMBContext();

  // schedule info (complex updates so not stored in form data)
  const [outboundSchedule, _setOutboundSchedule] = useState<stopDisplayInterface[]>([]);
  const [inboundSchedule, _setInboundSchedule] = useState<stopDisplayInterface[]>([]);

  const _initializeInboundSchedule = (
    outboundSchedule: stopInterface[],
  ): stopDisplayInterface[] => {
    // initialize an inbound schedule with times starting from 5pm using outbound stops
    const inbound = createMirrorSchedule(
      outboundSchedule.map((stop, index) => {
        return makeStop(stop.place, 17 + index * -1, new Date(), stop.stopType);
      }),
    );

    return inbound;
  };

  const updateMapDisplay = (stops: stopDisplayInterface[]) => {
    computeRoute(stops).then((val) => setRoute?.(...val));
  };

  const buildScheduleAndValidate = (
    stops: string[],
    outboundSchedule: stopDisplayInterface[],
    inboundSchedule: stopDisplayInterface[],
  ) => {
    const currentStops = outboundSchedule.map((stop) => stop.place.id);
    let outbound = outboundSchedule;
    let inbound = inboundSchedule;

    if (stops.length < currentStops.length) {
      // remove de-selected stop without updating times
      const stopsSet = new Set(stops);
      const removedStopIds = currentStops.filter((stop) => !stopsSet.has(stop));
      outbound = outboundSchedule.filter((stop) => !removedStopIds.includes(stop.place.id));
      inbound = inboundSchedule.filter((stop) => !removedStopIds.includes(stop.place.id));
    } else if (stops.length > currentStops.length) {
      // add new stop to end of outbound and beginning of inbound
      const stopsSet = new Set(currentStops);
      const addedStopId = stops.filter((stop) => !stopsSet.has(stop))[0];
      const addedPlace = places.map((p) => p.object).filter((place) => place.id === addedStopId)[0];

      // make the outbound stop an hour after the current last stop (or 8am if no stops)
      const outboundDepartureTime = outbound.length
        ? outbound[outbound.length - 1].departureDate.getHours() + 1
        : 8;
      // make the inbound stop an hour before the current first stop (or 5pm if no stops)
      const inboundDepartureTime = inbound.length ? inbound[0].arrivalDate.getHours() - 2 : 17;

      // if there are existing stops we need to make sure the new stop's date is the same
      // otherwise the time validation can fail even if the times are correct and DatePicker
      // can bug out (see preserveScheduleTimes for explanation)
      const newOutboundStop = makeStop(
        addedPlace,
        outboundDepartureTime,
        outbound.length ? outbound[outbound.length - 1].arrivalDate : new Date(),
      );
      const newInboundStop = makeStop(
        addedPlace,
        inboundDepartureTime,
        inbound.length ? inbound[inbound.length - 1].arrivalDate : new Date(),
      );

      outbound.push(newOutboundStop);
      inbound.unshift(newInboundStop);
    }

    _setOutboundSchedule(outbound);
    _setInboundSchedule(inbound);

    updateMapDisplay(outbound);
    setFormValueAndValidate({
      stops,
      outboundSchedule: outbound,
      inboundSchedule: inbound,
    });
  };

  const setOutboundSchedule = (schedule: stopDisplayInterface[], updateType: UPDATE_TYPE) => {
    if (updateType !== UPDATE_TYPE.TIME) {
      preserveScheduleTimes(schedule, outboundSchedule);
    }

    _setOutboundSchedule(schedule);
    const mirrorSchedule = createMirrorSchedule(schedule);
    preserveScheduleTimes(mirrorSchedule, inboundSchedule);
    _setInboundSchedule(mirrorSchedule);

    updateMapDisplay(schedule);
    setFormValueAndValidate({
      outboundSchedule: schedule,
      inboundSchedule: mirrorSchedule,
    });
  };

  const setInboundSchedule = (schedule: stopDisplayInterface[], updateType: UPDATE_TYPE) => {
    if (updateType !== UPDATE_TYPE.TIME) {
      preserveScheduleTimes(schedule, inboundSchedule);
    }

    _setInboundSchedule(schedule);
    const mirrorSchedule = createMirrorSchedule(schedule);
    preserveScheduleTimes(mirrorSchedule, outboundSchedule);
    _setOutboundSchedule(mirrorSchedule);

    // map should display outbound schedule
    updateMapDisplay(mirrorSchedule);
    setFormValueAndValidate({
      inboundSchedule: schedule,
      outboundSchedule: mirrorSchedule,
    });
  };

  const createMirrorSchedule = (scheduleToMirror: stopDisplayInterface[]) => {
    const mirrorSchedule = [...scheduleToMirror].map((stop) => ({ ...stop })).reverse();

    for (let i = 0; i < mirrorSchedule.length; i++) {
      // mirror the stop types
      if (mirrorSchedule[i].stopTypes.length === 1) {
        if (mirrorSchedule[i].stopTypes.includes(STOP_TYPE.PICKUP)) {
          mirrorSchedule[i].stopTypes = [STOP_TYPE.DROPOFF];
        } else if (mirrorSchedule[i].stopTypes.includes(STOP_TYPE.DROPOFF)) {
          mirrorSchedule[i].stopTypes = [STOP_TYPE.PICKUP];
        }
      }
    }

    return mirrorSchedule;
  };

  const preserveScheduleTimes = (
    newSchedule: stopDisplayInterface[],
    scheduleTimesToPreserve: stopDisplayInterface[],
  ) => {
    // So. DatePicker, in it's infinite wisdom, decided that when the value
    // changes, the "pageDate" state variable (which controls the date shown
    // when the picker is updated) will only update if the new value is
    // NOT THE SAME DAY. Thus, since we actually only care about the time,
    // we give it a new day every time :)

    newSchedule.forEach((stop, index) => {
      stop.arrivalDate = moment(scheduleTimesToPreserve[index].arrivalDate).add(1, "day").toDate();
      stop.departureDate = moment(scheduleTimesToPreserve[index].departureDate)
        .add(1, "day")
        .toDate();
    });
  };

  const calculatePriceEstimate = (mileDistance: number) => {
    const baseRate = formValue.adhocPricingBaseRate || 0;
    const perMileRate = formValue.adhocPricingPerMileRate || 0;
    return estimateOneWayPricing(baseRate, perMileRate, mileDistance) * 2;
  };

  const isBookable = serviceBookableOn(vanpool, service);
  const canEditServiceStart = editing;
  const canEditServiceEnd = editing && isMostRecent;
  const canEditServiceDays = editing && !isBookable;
  const canEditStops = editing && !isBookable;
  const canEditPricing = editing && !isBookable;
  const canEditNames = editing;

  const makeFormValues = (service: serviceInterface): serviceFormInterface => {
    // build outbound and inbound schedules (even if vanpool is one way)
    // preserving existing schedules if we have them
    const stops = service.outbound.stops.map((s) => s.place.id);
    const outbound = service.outbound.stops.map((s) => makeStopDisplay(s));
    const inbound = service.inbound
      ? service.inbound.stops.map((s) => makeStopDisplay(s))
      : _initializeInboundSchedule(outboundSchedule);

    const previousPlaces = previousService?.outbound.stops.map((x) => x.place.id) || [];

    return {
      id: service.id,
      shortName: service.shortName,
      description: service.description,
      startDate: service.startDate ? moment(service.startDate).toDate() : undefined,
      endDate: service.endDate ? moment(service.endDate).toDate() : undefined,
      days: service.availableDays,
      roundTrip: service.inbound ? [true] : [],
      stops,
      outboundSchedule: outbound,
      inboundSchedule: inbound,
      adhocPricing: service.adhocPricing != undefined,
      adhocPricingBaseRate: service.adhocPricing?.baseRate,
      adhocPricingPerMileRate: service.adhocPricing?.perMileRate,
      stopMappingPreviousPlaces: previousPlaces,
      stopMapping: service.stopMapping || { stops: [] },
    };
  };
  const [formValue, setFormValue] = useState<serviceFormInterface>({} as serviceFormInterface);
  const [formError, setFormError] = useState<Record<string, string | undefined>>({});
  const form = useRef<FormInstance>();

  useEffect(() => {
    // update service and map
    const value = makeFormValues(service);
    setFormValue(value);
    _setOutboundSchedule(value.outboundSchedule);
    _setInboundSchedule(value.inboundSchedule);
    if (editing) {
      updateMapDisplay(value.outboundSchedule);
    }
  }, [service, editing]);

  useEffect(() => {
    const removedPlaces =
      formValue.stopMappingPreviousPlaces?.filter((x) => !formValue.stops.includes(x)) || [];
    const mapping = removedPlaces.map(
      (x) =>
        formValue.stopMapping.stops.find((y) => y.oldId === x) || {
          oldId: x,
          newId: formValue.stops[0],
        },
    );
    setFormValueAndValidate({ stopMapping: { stops: mapping } });
  }, [formValue.stops]);

  const handleUpdateStopMapping = (stop, value) => {
    const s = (formValue.stopMapping?.stops || []).map((x) =>
      x.oldId === stop.oldId ? { ...x, newId: value } : x,
    );
    setFormValueAndValidate({ stopMapping: { stops: s } });
  };

  const setFormValueAndValidate = useCallback(
    (newValue: any) => {
      setFormValue((old) => {
        const merged = { ...old, ...newValue };
        const result = serviceModel.check(merged as any);
        const newErrors = {};
        Object.keys(result).forEach((key) => {
          if (result[key].hasError) {
            newErrors[key] = result[key].errorMessage;
          }
        });
        setFormError(newErrors);
        return merged;
      });
    },
    [setFormValue, setFormError, serviceModel],
  );

  const handleSubmit = () => {
    if (!form.current?.check()) return;

    // construct pricing
    const adhocPricing = formValue.adhocPricing
      ? ({
          baseRate: formValue.adhocPricingBaseRate || 0,
          perMileRate: formValue.adhocPricingPerMileRate || 0,
        } as pricingInterface)
      : undefined;

    const newService: updateServiceInterface = {
      shortName: formValue.shortName,
      description: formValue.description,
      availableDays: formValue.days,
      startDate: moment(formValue.startDate).toISOString(true),
      endDate: formValue.endDate ? moment(formValue.endDate).toISOString(true) : undefined,
      outbound: makeSchedule(formValue.outboundSchedule),
      inbound: formValue.roundTrip.length > 0 ? makeSchedule(formValue.inboundSchedule) : undefined,
      adhocPricing,
      stopMapping: formValue.stopMapping,
    };

    const update: vanpoolServiceUpdateInterface = {
      apply: VANPOOL_SERVICE_UPDATE_APPLY.AFTER_DATE,
      applyDate: moment().format("YYYY-MM-DD"),
      service: newService,
      serviceId: service.id,
    };

    onSubmit?.(update);
  };

  const serviceTitle = (service?: serviceInterface) => {
    if (!service) return "New Service";
    const sd = service.startDate.format("MMMM D, YYYY");
    if (service.endDate) {
      const ed = service.endDate.format("MMMM D, YYYY");
      return `${sd} - ${ed}`;
    } else {
      return (service.startDate.isAfter(moment(), "day") ? "Starts " : "Started ") + sd;
    }
  };

  return (
    <div className="ServiceItem-container">
      <ServicePanel
        title={serviceTitle(service)}
        onEdit={onEdit}
        editing={editing}
        canEdit={editing || canEdit}
      >
        <Form
          model={serviceModel}
          layout="horizontal"
          formValue={formValue}
          formError={formError}
          onChange={(data) => setFormValue(data as serviceFormInterface)}
          onCheck={(errors) => setFormError(errors)}
          ref={form}
        >
          <FormGroup>
            <ControlLabel>Name</ControlLabel>
            <FormControl name="shortName" disabled={!canEditNames} />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Description</ControlLabel>
            <FormControl
              rows={2}
              name="description"
              componentClass="textarea"
              disabled={!canEditNames}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Start Service</ControlLabel>
            <FormControl
              name="startDate"
              placeholder="Start Date"
              placement="topEnd"
              oneTap
              format="YYYY-MM-DD"
              accepter={DatePicker}
              disabled={!canEditServiceStart}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>End Service</ControlLabel>
            <FormControl
              name="endDate"
              placeholder="End Date"
              placement="topEnd"
              oneTap
              format="YYYY-MM-DD"
              accepter={DatePicker}
              disabled={!canEditServiceEnd}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Available Days</ControlLabel>
            <DayPicker
              selectedDays={formValue.days}
              onChange={(days) => setFormValueAndValidate({ days })}
              disabled={!canEditServiceDays}
            />
            {formError.days && canEditServiceDays && (
              <Message showIcon type="error" description={formError.days as string} />
            )}
          </FormGroup>
          <FormGroup>
            <ControlLabel>Stops</ControlLabel>
            <FormControl
              name="stops"
              accepter={CheckPicker}
              sticky
              data={places}
              disabled={!canEditStops}
              onChange={(stops) =>
                buildScheduleAndValidate(stops, outboundSchedule, inboundSchedule)
              }
              placement="topEnd"
            />
          </FormGroup>
          {(formValue.stops || []).length > 0 && (
            <>
              <FormGroup>
                <ControlLabel>Outbound Schedule</ControlLabel>
                <ScheduleList
                  schedule={formValue.outboundSchedule}
                  setSchedule={setOutboundSchedule}
                  disabled={!canEditStops}
                />
              </FormGroup>
              {isMBAdmin && (
                <FormGroup>
                  <ControlLabel>Round Trip</ControlLabel>
                  <FormControl name="roundTrip" accepter={CheckboxGroup} inline>
                    <Checkbox value={true} disabled={!canEditStops}>
                      Round Trip
                    </Checkbox>
                    <></>
                  </FormControl>
                </FormGroup>
              )}
              {formValue.roundTrip[0] === true && (
                <FormGroup>
                  <ControlLabel>Inbound Schedule</ControlLabel>
                  <ScheduleList
                    schedule={formValue.inboundSchedule}
                    setSchedule={setInboundSchedule}
                    disabled={!canEditStops}
                  />
                </FormGroup>
              )}
            </>
          )}
          <FormGroup>
            <ControlLabel>Custom Pricing</ControlLabel>
            <FormControl
              name="adhocPricing"
              defaultChecked={formValue.adhocPricing}
              accepter={Toggle}
              disabled={!canEditPricing}
            />
          </FormGroup>
          {formValue.adhocPricing && (
            <>
              <FormGroup>
                <ControlLabel>Base Rate</ControlLabel>
                <FormControl
                  value={formValue.adhocPricingBaseRate}
                  name="adhocPricingBaseRate"
                  accepter={FormCurrencyInput}
                  disabled={!canEditPricing}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Per Mile Rate</ControlLabel>
                <FormControl
                  value={formValue.adhocPricingPerMileRate}
                  name="adhocPricingPerMileRate"
                  accepter={FormCurrencyInput}
                  disabled={!canEditPricing}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Sample 20 mile round trip</ControlLabel>
                <FormControl
                  accepter={FormCurrencyInput}
                  value={calculatePriceEstimate(10)}
                  readOnly={true}
                />
              </FormGroup>
            </>
          )}
          <p>Mapping for Removed Stops</p>
          {formValue.stopMapping?.stops.map((stop, index) => (
            <>
              <FormGroup>
                <ControlLabel>{places.find((x) => x.object.id === stop.oldId)?.label}</ControlLabel>
                <FormControl
                  accepter={SelectPicker}
                  sticky
                  cleanable={false}
                  data={places.filter((x) => formValue.stops.includes(x.value))}
                  value={stop.newId}
                  disabled={!canEditStops}
                  onChange={(value) => handleUpdateStopMapping(stop, value)}
                  placement="topEnd"
                />
              </FormGroup>
            </>
          ))}
          {editing && (
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={Object.keys(formError || {}).length > 0}
            >
              Save
            </Button>
          )}
        </Form>
      </ServicePanel>
    </div>
  );
};
