import React, { useEffect, useState } from "react";
import "./Reporting.scss";
import moment from "moment";
import { fetchReports } from "api/reports";
import { Bar } from "react-chartjs-2";
import { pool_data, trip_report } from "interfaces/reporting";
import useMBContext from "context/useMBContext";
import { CheckPicker, DateRangePicker, Dropdown, Loader, Radio, RadioGroup } from "rsuite";
import { Route, Switch, useHistory } from "react-router-dom";
import { routes } from "misc/http";
import { NTDReportPage } from "./ntdReport";

const { REACT_APP_ENVIRONMENT } = process.env;
const isDev = REACT_APP_ENVIRONMENT === "dev";

// sum several fields across pool_data
type PoolMapper = (p: pool_data) => number;
type MappingType<T> = { [P in keyof T]: PoolMapper };
type ResultType<T> = { [P in keyof T]: number };
type ArrayResultType<T> = { [P in keyof T]: number[] };

function zeroMapping<T>(m: MappingType<T>): ResultType<T> {
  return Object.fromEntries(Object.keys(m).map((k) => [k, 0])) as ResultType<T>;
}
function emptyArrayMapping<T>(m: MappingType<T>): ArrayResultType<T> {
  return Object.fromEntries(Object.keys(m).map((k) => [k, [] as number[]])) as ArrayResultType<T>;
}

// time (perDay) is X
// pool is Y
// pool_data is Z
// result = [
//   {_pool: pool1, Z1: sum(perDay.pool1.Z1), ...}
// ]
function reduce_by_pool<T>(report: trip_report, mapping: MappingType<T>) {
  const zero = zeroMapping(mapping);
  const poolIds = new Set(report.perDay.flatMap((p) => p.pools.map((pp) => pp.id)));
  const result = Object.fromEntries(
    [...poolIds].map((p) => [p, { ...zero, _pool: report.services.find((s) => s.id === p)! }]),
  );
  report.perDay.forEach((day) => {
    day.pools.forEach((pool) => {
      Object.keys(mapping).forEach((k) => {
        result[pool.id][k] += mapping[k](pool);
      });
    });
  });
  return Object.values(result).map((v) => ({
    ...v,
    _total: Object.keys(mapping)
      .map((k) => v[k])
      .reduce((a, b) => a + b),
  }));
}

function sum_by_day<T>(report: trip_report, mapping: MappingType<T>) {
  const result = emptyArrayMapping(mapping);
  report.perDay.forEach((d) => {
    Object.entries<PoolMapper>(mapping).forEach(([k, f]) => {
      result[k].push(d.pools.map(f).reduce((a, b) => a + b, 0));
    });
  });
  return result;
}

function sum_all<T>(report: trip_report, mapping: MappingType<T>) {
  const result = zeroMapping(mapping);
  report.perDay.forEach((d) => {
    Object.entries<PoolMapper>(mapping).forEach(([k, f]) => {
      result[k] += d.pools.map(f).reduce((a, b) => a + b, 0);
    });
  });
  return result;
}

function sum_by_dow<T>(report: trip_report, mapping: MappingType<T>) {
  const dowZero: number[] = Array(7).fill(0);
  const result = Object.fromEntries(Object.keys(mapping).map((k) => [k, [...dowZero]]));
  report.perDay.forEach((d) => {
    Object.entries<PoolMapper>(mapping).forEach(([k, f]) => {
      const dow = d.day.day();
      result[k][dow] += d.pools.map(f).reduce((a, b) => a + b, 0);
    });
  });
  return result;
}

const makeOptions = (title: string, x = {}, y = {}) => ({
  plugins: {
    title: {
      display: true,
      text: title,
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      ...x,
    },
    y: {
      stacked: true,
      ...y,
    },
  },
});

const makeDateLabels = (report: trip_report) => {
  const format = report.dateStart.isSame(report.dateEnd, "year") ? "MM/DD" : "yyyy/MM/DD";
  return report.perDay.map((d) => d.day.format(format));
};

interface ChartProps {
  report: trip_report;
}

const ChartPoolUtilization: React.FC<ChartProps> = ({ report }) => {
  const summary = reduce_by_pool(report, {
    subscribed: (p) => p.trips.subscribed,
    dynamic: (p) => p.trips.dynamic,
    unused: (p) => p.trips.emptySeats,
  });

  const data = {
    labels: summary.map((d) => d._pool.name),
    datasets: [
      {
        label: "Dynamic",
        data: summary.map((d) => (100 * d.dynamic) / d._total),
        backgroundColor: "rgb(5, 182, 203)",
      },
      {
        label: "Subscribed",
        data: summary.map((d) => (100 * d.subscribed) / d._total),
        backgroundColor: "rgb(0, 114, 240)",
      },
      {
        label: "Unused",
        data: summary.map((d) => (100 * d.unused) / d._total),
        backgroundColor: "rgb(178, 190, 181)",
      },
    ],
  };

  const options = {
    ...makeOptions("Average Utilization % by Vanpool", { max: 100 }),
    indexAxis: "y" as const,
  };

  return <Bar data={data} options={options} />;
};

const ChartPassengerTripStatus: React.FC<ChartProps> = ({ report }) => {
  const dateLabels = makeDateLabels(report);
  const summary = sum_by_day(report, {
    unconfirmed: (p: pool_data) => p.trips.unconfirmed,
    dynamic: (p: pool_data) => p.trips.dynamic,
    missed: (p: pool_data) => p.trips.missed,
    subscribed: (p: pool_data) => p.trips.subscribed,
    waitlisted: (p: pool_data) => p.trips.waitlisted,
    canceled: (p: pool_data) => p.trips.canceled,
  });

  const data = {
    labels: dateLabels,
    datasets: [
      {
        label: "Dynamic",
        data: summary.dynamic,
        backgroundColor: "rgb(5, 182, 203)",
      },
      {
        label: "Subscribed",
        data: summary.subscribed,
        backgroundColor: "rgb(0, 114, 240)",
      },
      {
        label: "Waitlisted",
        data: summary.waitlisted,
        backgroundColor: "rgb(249, 166, 2)",
      },
      {
        label: "Ride Canceled",
        data: summary.canceled,
        backgroundColor: "rgb(255, 0, 0)",
      },
      {
        label: "Unconfirmed",
        data: summary.unconfirmed,
        backgroundColor: "rgb(255, 211, 0)",
      },
      {
        label: "Missed",
        data: summary.missed,
        backgroundColor: "rgb(0, 211, 0)",
      },
    ],
  };

  const options = makeOptions("Passenger Trips Status by Day");
  return <Bar data={data} options={options} />;
};

const ChartRideIssues: React.FC<ChartProps> = ({ report }) => {
  const dateLabels = makeDateLabels(report);
  const summary = sum_by_day(report, {
    noRiders: (p: pool_data) => p.rides.noRiders,
    noDriver: (p: pool_data) => p.rides.noDriver,
    noVehicle: (p: pool_data) => p.rides.noVehicle,
    canceled: (p: pool_data) => p.rides.canceled,
    overbooked: (p: pool_data) => p.rides.overbooked,
  });

  const data = {
    labels: dateLabels,
    datasets: [
      {
        label: "Missing Rider",
        data: summary.noRiders,
        backgroundColor: "rgb(249, 166, 2)",
      },
      {
        label: "Overbooked",
        data: summary.overbooked,
        backgroundColor: "rgb(216, 31, 42)",
      },
      {
        label: "Missing Vehicle",
        data: summary.noVehicle,
        backgroundColor: "rgb(246, 70, 91)",
      },
      {
        label: "Missing Driver",
        data: summary.noDriver,
        backgroundColor: "rgb(226, 110, 110)",
      },
      {
        label: "Canceled",
        data: summary.canceled,
        backgroundColor: "rgb(255, 0, 0)",
      },
    ],
  };

  const options = makeOptions("Ride Issues by Day");
  return <Bar data={data} options={options} />;
};

const ChartSeatUtilization: React.FC<ChartProps> = ({ report }) => {
  const dateLabels = makeDateLabels(report);
  const summary = sum_by_day(report, {
    firstTime: (p: pool_data) => p.trips.firstTime,
    subscribed: (p: pool_data) => p.trips.subscribed,
    dynamic: (p: pool_data) => p.trips.dynamic,
    unused: (p: pool_data) => p.trips.emptySeats,
  });

  const data: any = {
    labels: dateLabels,
    datasets: [
      {
        type: "line",
        label: "First Time",
        data: summary.firstTime,
        borderColor: "rgb(249, 166, 2)",
        borderWidth: 2,
        fill: false,
      },
      {
        type: "bar",
        label: "Subscribed",
        data: summary.subscribed,
        backgroundColor: "rgb(0, 114, 240)",
      },
      {
        type: "bar",
        label: "Dynamic",
        data: summary.dynamic,
        backgroundColor: "rgb(5, 182, 203)",
      },
      {
        type: "bar",
        label: "Unused",
        data: summary.unused,
        backgroundColor: "rgb(178, 190, 181)",
      },
    ],
  };

  const options = makeOptions("Seat Utilization by Day");
  return <Bar data={data} options={options} />;
};

const ChartRidesDayOfWeek: React.FC<ChartProps> = ({ report }) => {
  const summary = sum_by_dow(report, {
    subscribed: (p) => p.trips.subscribed,
    dynamic: (p) => p.trips.dynamic,
  });

  const data = {
    labels: Array(7)
      .fill(0)
      .map((v, i) => moment().day(i).format("dddd")),
    datasets: [
      {
        label: "Dynamic",
        data: summary.dynamic,
        backgroundColor: "rgb(5, 182, 203)",
      },
      {
        label: "Subscribed",
        data: summary.subscribed,
        backgroundColor: "rgb(0, 114, 240)",
      },
    ],
  };

  const options = {
    ...makeOptions("Average Rides by Day of Week"),
  };

  return <Bar data={data} options={options} />;
};

const ReportSummary: React.FC<ChartProps> = ({ report }) => {
  const summary = sum_all(report, {
    subscribed: (p) => p.trips.subscribed,
    dynamic: (p) => p.trips.dynamic,
    vmtReduction: (p) => p.vmtReduction,
  });
  const activeRoutes = report.perDay.map((p) => p.routes.active).reduce((a, b) => a + b);

  const prettyNumber = (num: number) => num.toLocaleString(undefined, { maximumFractionDigits: 2 });

  return (
    <div className="Reporting-summary">
      <div className="Reporting-summary-total">
        <h3>Passenger Trips</h3>
        {prettyNumber(summary.subscribed + summary.dynamic)}
      </div>
      <div className="Reporting-summary-total">
        <h3>Active Routes</h3>
        {prettyNumber}
        {activeRoutes &&
          activeRoutes.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}
      </div>
      <div className="Reporting-summary-total">
        <h3>First Time Riders</h3>
        {prettyNumber(activeRoutes)}
      </div>
      <div className="Reporting-summary-total">
        <h3>Total VMT Reduction (miles)</h3>
        {prettyNumber(summary.vmtReduction)}
      </div>
      <div className="Reporting-summary-total">
        <h3>Total CO2 Reduction (tons)</h3>
        {((summary.subscribed + summary.dynamic) * 0.000411).toFixed(2)}
      </div>
    </div>
  );
};

interface ReportingProps {}

interface selectInterface {
  label: string;
  value: string;
}

const DefaultReporting: React.FC<ReportingProps> = ({}) => {
  interface Params {
    startDate?: string;
    endDate?: string;
  }
  const params: Params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
  const history = useHistory();
  const [dateRange, setDateRange] = useState<Date[]>(
    [
      params.startDate ? moment(params.startDate) : moment().startOf("month").subtract(3, "months"),
      params.endDate ? moment(params.endDate) : moment(),
    ].map((x) => x.toDate()),
  );

  const { selectedOrganization } = useMBContext();

  const [report, setReport] = useState<trip_report | undefined>();
  const [filteredReport, setFilteredReport] = useState<trip_report | undefined>();
  const [vanpools, setVanpools] = useState<selectInterface[]>([]);
  const [selectedVanpools, setSelectedVanpools] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!selectedOrganization) return;

    setLoading(true);
    setFilteredReport(undefined);
    fetchReports({
      startDate: dateRange[0],
      endDate: dateRange[1],
      orgId: selectedOrganization?.id,
    }).then((data: trip_report) => {
      setVanpools(data.services.map((s) => ({ value: s.id, label: s.name })));
      setReport(data);
    });
  }, [dateRange, selectedOrganization]);

  useEffect(() => {
    if (!report) return;

    // produce a filtered report for the selected vanpools
    const ids = new Set(selectedVanpools);
    const filtered: trip_report = {
      ...report,
      services: report.services.filter((s) => !ids.size || ids.has(s.id)),
      perDay: report.perDay.map((day) => ({
        ...day,
        pools: day.pools.filter((p) => !ids.size || ids.has(p.id)),
      })),
    };
    setFilteredReport(filtered);
    setLoading(false);
  }, [selectedVanpools, report]);

  const handleChangeReport = (value) => {
    history.push(`${routes.reporting}/${value}`);
  };

  return (
    <div className="Reporting">
      <div className="Reporting-toolbar">
        <div className="Reporting-toolbar-controls">
          <div className="Reporting-toolbar-control-left">
            <RadioGroup
              inline
              appearance="picker"
              defaultValue="default"
              onChange={handleChangeReport}
            >
              <Radio value="default">Default</Radio>
              <Radio value="ntd">NTD</Radio>
            </RadioGroup>
          </div>
        </div>
        <div className="Reporting-toolbar-controls">
          <div className="Reporting-toolbar-control">
            <CheckPicker
              cleanable
              style={{ width: 200 }}
              closeOnSelect={false}
              data={vanpools}
              value={selectedVanpools}
              placeholder="Select Vanpool(s)"
              onChange={(selected) => {
                setSelectedVanpools(selected);
              }}
              placement={"bottomEnd"}
            />
          </div>
          <div className="Reporting-toolbar-control">
            <DateRangePicker
              value={dateRange as [Date?, Date?]}
              placement={"bottomEnd"}
              onChange={(date: any) => setDateRange(Array.isArray(date) ? date : [date])}
            />
          </div>
          {isDev && (
            <div className="Reporting-toolbar-control">
              <Dropdown appearance="primary" title="Export" placement="bottomEnd" size="md">
                <Dropdown.Item>Full Report</Dropdown.Item>
                <Dropdown.Item>NTD Ridership Report</Dropdown.Item>
                <Dropdown.Item divider></Dropdown.Item>
                <Dropdown.Item>Custom Report 1</Dropdown.Item>
              </Dropdown>
            </div>
          )}
        </div>
      </div>
      {loading && <Loader center content="Loading Report..." size="lg" />}
      {filteredReport && (
        <>
          <ReportSummary report={filteredReport} />
          <div className="Reporting-section">
            <div className="Reporting-section-graph">
              <ChartPassengerTripStatus report={filteredReport} />
            </div>
            <div className="Reporting-section-graph">
              <ChartRideIssues report={filteredReport} />
            </div>
            <div className="Reporting-section-graph">
              <ChartSeatUtilization report={filteredReport} />
            </div>
            <div className="Reporting-section-graph">
              <ChartRidesDayOfWeek report={filteredReport} />
            </div>
            <div className="Reporting-section-graph">
              <ChartPoolUtilization report={filteredReport} />
            </div>
          </div>
        </>
      )}
      <div style={{ height: "50px" }}></div>
    </div>
  );
};

interface ReportingProps {}

interface selectInterface {
  label: string;
  value: string;
}

const Reporting: React.FC<ReportingProps> = ({}) => {
  return (
    <Switch>
      <Route path={`${routes.reporting}/ntd`}>
        <NTDReportPage />
      </Route>
      <Route path={`${routes.reporting}`}>
        <DefaultReporting />
      </Route>
    </Switch>
  );
};

export default Reporting;
