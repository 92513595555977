import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Header.scss";
import { routes } from "misc/http";
import logo from "assets/img/logo.png";
import Avatar from "components/common/Avatar";
import Organizations from "components/Organizations";
import { Button, Drawer, Dropdown, SelectPicker } from "rsuite";
import Icon from "components/common/Icon";
import useMBContext from "context/useMBContext";
import { treeInterface } from "interfaces/selectInterface";

interface HeaderInterface {
  className?: string;
}

const Header: React.FC<HeaderInterface> = ({ className }: HeaderInterface) => {
  const {
    adminUser,
    isMBAdmin,
    isAccManagerRole,
    isOrgManagerRoleOrGreater,
    isVPMRoleOrGreater,
    logout,
    setAccount,
    selectedAccount,
    setOrganization,
    selectedOrganization,
  } = useMBContext();
  const [accountData, setAccountData] = useState<Array<treeInterface>>([]);
  const [organizationData, setOrganizationData] = useState<Array<treeInterface>>([]);
  const [accountDrawerVisible, setAccountDrawerVisible] = useState<boolean>(false);
  const [orgDrawerVisible, setOrgDrawerVisible] = useState<boolean>(false);

  useEffect(() => {
    if (adminUser) {
      if (adminUser.accounts) {
        const accountList: Array<treeInterface> = adminUser.accounts.map((account) => {
          const accnt: treeInterface = {
            value: account.id,
            label: account.name,
          };
          return accnt;
        });
        accountList.push({ value: undefined, label: "All" });
        setAccountData(accountList);
      }
      if (adminUser.organizations) {
        const orgList: Array<treeInterface> = adminUser.organizations
          .filter((org) => {
            return !selectedAccount || org.accountId == selectedAccount.id;
          })
          .map((org) => {
            const obj: treeInterface = {
              value: org.id,
              label: org.name,
            };
            return obj;
          });
        setOrganizationData(orgList);
      }
    }
  }, [adminUser, selectedAccount, selectedOrganization]);

  const handleAccountSelect = (value: any) => {
    setAccount(value);
  };

  const handleOrganizationSelect = (value: any) => {
    if (value) {
      setOrganization && setOrganization(value);
    }
  };

  const toggleAccountDrawer = () => {
    setAccountDrawerVisible(!accountDrawerVisible);
  };

  const toggleOrgDrawer = () => {
    setOrgDrawerVisible(!orgDrawerVisible);
  };

  return (
    <header className={`Header ${className || ""}`} data-testid="header-block">
      <Drawer show={orgDrawerVisible} onHide={toggleOrgDrawer}>
        <Drawer.Header>
          <Drawer.Title>Organizations</Drawer.Title>
        </Drawer.Header>
        <div className="Header-drawer-content">
          <Organizations />
        </div>
      </Drawer>
      <Drawer placement="left" show={accountDrawerVisible} onHide={toggleAccountDrawer}>
        <Drawer.Header>
          <Drawer.Title>Accounts</Drawer.Title>
        </Drawer.Header>
        Accounts
      </Drawer>
      <NavLink to={routes.main} className="Header-logo" data-testid="header-logo">
        <img
          className="Header-logo-image"
          src={logo}
          alt="MagicBus logo"
          data-testid="header-logo-image"
        />
      </NavLink>
      <label htmlFor="nav-controller" className="Header-nav-icon">
        <Icon name="menu" />
      </label>

      <input id="nav-controller" type="checkbox" />
      <div className="Header-nav-backdrop" />
      <nav className="Header-nav" data-testid="header-navbar">
        <label htmlFor="nav-controller" className="Header-nav-icon-close">
          <Icon name="close" />
        </label>
        <div className="Header-nav-items">
          <div className="Header-selectFrame">
            <div className="Header-select">
              {accountData.length > 1 && (
                <div className="Header-select-accounts">
                  <div className="Header-select-label">Account</div>
                  <SelectPicker
                    data={accountData}
                    cleanable={false}
                    onSelect={handleAccountSelect}
                    value={selectedAccount?.id}
                    placement="bottomStart"
                    size="sm"
                  />
                  {isMBAdmin && false && (
                    <Button size="xs" onClick={toggleAccountDrawer}>
                      Add/Edit
                    </Button>
                  )}
                </div>
              )}
              <div className="Header-select-organizations">
                <div className="Header-select-label">Organization</div>
                {organizationData.length > 0 ? (
                  <SelectPicker
                    data={organizationData}
                    cleanable={false}
                    onSelect={handleOrganizationSelect}
                    value={selectedOrganization?.id}
                    placement="bottomStart"
                    size="sm"
                  />
                ) : (
                  <span className="Header-select-none">
                    {accountData.length > 0 ? "Select Account" : ""}
                  </span>
                )}
              </div>
            </div>
          </div>
          {isMBAdmin && (
            <NavLink
              to={routes.organizations}
              className="Header-nav-item"
              data-testid="header-orgs"
            >
              Orgs
            </NavLink>
          )}
          {isOrgManagerRoleOrGreater && (
            <>
              <NavLink to={routes.users} className="Header-nav-item" data-testid="header-users">
                Users
              </NavLink>
              <NavLink
                to={routes.vehicles}
                className="Header-nav-item"
                data-testid="header-vehicles"
              >
                Vehicles
              </NavLink>
              <NavLink to={routes.places} className="Header-nav-item" data-testid="header-places">
                Places
              </NavLink>
            </>
          )}
          {isVPMRoleOrGreater && (
            <NavLink to={routes.pools} className="Header-nav-item" data-testid="header-pools">
              Pools
            </NavLink>
          )}
          {isMBAdmin && (
            <>
              <NavLink
                to={routes.waitlist}
                className="Header-nav-item"
                data-testid="header-waitlist"
              >
                Waitlist
              </NavLink>
            </>
          )}
          {isVPMRoleOrGreater && (
            <NavLink
              to={routes.reporting}
              className="Header-nav-item"
              data-testid="header-reporting"
            >
              Reporting
            </NavLink>
          )}
          <div className="Header-nav-logout">
            <Button onClick={logout}>Logout</Button>
          </div>
        </div>
        <div className="Header-accounts">
          <Dropdown
            name="accountSelector"
            placement="bottomEnd"
            renderTitle={() => {
              return <Avatar />;
            }}
          >
            <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
          </Dropdown>
        </div>
      </nav>
    </header>
  );
};

export default Header;
