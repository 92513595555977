import React from "react";
import {
  OPTIONS,
  poolInterface,
  updateScheduleInterface,
  updateStopInterface,
} from "interfaces/pool";
import { stopDisplayInterface, stopInterface, STOP_TYPE } from "interfaces/stop";
import { coordinate, getRoute2 } from "api/mapbox";
import { mapLine, mapPin } from "interfaces/map";
import { decodePolyline, lineColors, formatTime, parseTime } from "misc/utils";
import { placeInterface } from "interfaces/place";
import { serviceInterface } from "interfaces/schedule";
import moment from "moment";

export const computeRoute = (schedule: stopDisplayInterface[]): Promise<[mapPin[], mapLine[]]> => {
  const selectedPlaces: placeInterface[] = schedule.map((stop) => stop.place);

  const coords: coordinate[] = selectedPlaces.map((p) => ({
    lat: p.coordinates.lat,
    lon: p.coordinates.lng,
  }));
  return getRoute2(coords).then(({ data }) => {
    const pins: mapPin[] = selectedPlaces.map((p, index) => ({
      id: p.id,
      pinNumber: index + 1,
      place: p,
      label: p.name,
    }));

    const polystring = decodePolyline(data.routes[0].geometry);

    const lines: mapLine[] = [];
    lines[""] = {
      id: "",
      polyline: polystring,
      color: lineColors[0],
    };

    return [pins, lines];
  });
};

export const makeStop = (
  place: placeInterface,
  startHour: number,
  date: Date,
  stopType?: STOP_TYPE,
): stopDisplayInterface => {
  return makeStopDisplay(
    {
      arrival: startHour.toString() + ":50",
      departure: (startHour + 1).toString() + ":00",
      place: place,
      stopType: stopType,
    },
    date,
  );
};

export const makeStopDisplay = (
  s: stopInterface,
  date: Date = new Date(),
): stopDisplayInterface => ({
  ...s,
  arrivalDate: parseTime(s.arrival, date),
  departureDate: parseTime(s.departure, date),
  stopTypes: s.stopType == null ? [STOP_TYPE.PICKUP, STOP_TYPE.DROPOFF] : [s.stopType],
});

export const makeSchedule = (schedule: stopDisplayInterface[]): updateScheduleInterface => {
  const makeStop = (s: stopDisplayInterface, index): updateStopInterface => ({
    arrival: formatTime(s.arrivalDate),
    departure: formatTime(s.departureDate),
    // first stop must always be pickup and last stop must always be dropoff
    // [PICKUP, DROPOFF] is handled by backend as undefined
    stopType:
      index == 0
        ? STOP_TYPE.PICKUP
        : index == schedule.length - 1
        ? STOP_TYPE.DROPOFF
        : s.stopTypes.length == 1
        ? s.stopTypes[0]
        : undefined,
    placeId: s.place.id,
  });
  return {
    stops: schedule.map((s, index) => makeStop(s, index)),
  };
};

export const serviceExtendDays = (vanpool: poolInterface) => {
  return 7 * (vanpool.options.includes(OPTIONS.DYNAMIC) ? 5 : 2);
};

export const serviceBookableWindow = (vanpool: poolInterface, service: serviceInterface) => {
  const extend = serviceExtendDays(vanpool);
  return [
    moment(service.startDate).add(-extend, "days"),
    service.endDate || moment().add(extend, "days"),
  ];
};

export const serviceBookableOn = (
  vanpool: poolInterface,
  service: serviceInterface,
  date: moment.Moment = moment(),
) => {
  const window = serviceBookableWindow(vanpool, service);
  return date.isSameOrAfter(window[0], "day") && date.isSameOrBefore(window[1], "day");
};
