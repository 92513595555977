import React, { useEffect, useState } from "react";
import { Link, Route, Switch, useParams } from "react-router-dom";
import { routes } from "misc/http";
import "./Vehicles.scss";
import { Button, Input, InputGroup, Icon, Table } from "rsuite";
import { useHistory } from "react-router-dom";
import useMBContext from "context/useMBContext";
import VehiclesEditor from "components/VehiclesEditor";
import { fetchVehicles, vehicleInterface } from "api/vehicle";
import { ActionCell } from "components/common/Table";

const { Cell, Column, HeaderCell } = Table;

interface VehicleEditBouncerProps {
  onClose?: () => void | undefined;
  refreshData?: () => void | undefined;
  vehicles: vehicleInterface[];
}

const VehicleEditBouncer: React.FC<VehicleEditBouncerProps> = ({ vehicles, ...rest }) => {
  interface Params {
    id: string;
  }
  const { id } = useParams<Params>();
  const vehicle = vehicles.find((x) => x.id === id);
  if (!vehicle) {
    rest.onClose?.();
  }

  return <>{vehicle && <VehiclesEditor {...rest} vehicle={vehicle} />}</>;
};

const Vehicles: React.FC = () => {
  const { selectedAccount, selectedOrganization } = useMBContext();
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [vehicles, setVehicles] = useState<vehicleInterface[]>([]);

  const history = useHistory();

  useEffect(() => {
    refreshData();
  }, [selectedAccount, selectedOrganization]);

  const refreshData = () => {
    fetchVehicles({}).then((items) => {
      setVehicles(items);
    });
  };

  const filterVehicles = (value: any) => {
    setSearchTerm(value);
  };

  const handleEdit = (row: vehicleInterface) => {
    history.push(`${routes.vehicles}/edit/${row.id}`);
  };

  const handleClose = () => {
    history.push(`${routes.vehicles}`);
  };

  const filteredVehicles = vehicles.filter(
    (v) => !searchTerm || v.description.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div className="Vehicles">
      <div className="Vehicles-toolbar">
        <div className="Vehicles-toolbar-controls">
          <InputGroup>
            <Input placeholder="Find Vehicles" onChange={filterVehicles} />
            <InputGroup.Addon>
              <Icon icon="search" />
            </InputGroup.Addon>
          </InputGroup>
          <Link to={`${routes.vehicles}/add`}>
            <Button>Add Vehicle</Button>
          </Link>
        </div>
      </div>
      <div className="scroll-container">
        <Table autoHeight data={filteredVehicles} affixHeader={0}>
          <Column>
            <HeaderCell>Year</HeaderCell>
            <Cell dataKey="year" />
          </Column>
          <Column resizable>
            <HeaderCell>Make</HeaderCell>
            <Cell dataKey="make" />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Model</HeaderCell>
            <Cell dataKey="model" />
          </Column>
          <Column>
            <HeaderCell>License Plate</HeaderCell>
            <Cell dataKey="licensePlate" />
          </Column>
          <Column>
            <HeaderCell>License State</HeaderCell>
            <Cell dataKey="state" />
          </Column>
          <Column>
            <HeaderCell>Action</HeaderCell>
            <ActionCell name="Edit" onClick={handleEdit} />
          </Column>
        </Table>
      </div>
      <Switch>
        <Route path={`${routes.vehicles}/add`}>
          <VehiclesEditor
            onClose={handleClose}
            refreshData={refreshData}
            vehicle={{} as vehicleInterface}
          />
        </Route>
        <Route path={`${routes.vehicles}/edit/:id`}>
          {vehicles.length > 0 && (
            <VehicleEditBouncer
              onClose={handleClose}
              refreshData={refreshData}
              vehicles={vehicles}
            />
          )}
        </Route>
      </Switch>
    </div>
  );
};

export default Vehicles;
