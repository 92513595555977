import { adminUserInterface, userOrgInterface, userOrgUpdateInterface } from "interfaces/user";
import { gqlOp, GQLSchema, gql_post } from "misc/http";

export const getAdminUser = async () => {
  const data = `{
    currentAdminUser {
      id name email phone
      accounts { id name number isMagicBus roles }
      organizations { id accountId name code roles }
    }
  }`;

  return gql_post<adminUserInterface>("currentAdminUser", data);
};

const userSchema: GQLSchema = {
  name: "users",
  op: "query",
  params: {
    accountId: "String",
    orgId: "String!",
    ids: "[String]",
    includeFirstRide: "Boolean",
  },
};

export const fetchUsers = (params: {
  accountId?: string;
  orgId?: string;
  ids?: string[];
  includeFirstRide?: boolean;
}) => {
  return gqlOp(userSchema, params, [new userOrgInterface()]).then<userOrgInterface[]>((users) =>
    users.map((x) => userOrgInterface.fromGQL(x)),
  );
};

export const checkEmailRegistered = async (params: { email: string }) => {
  const data = `query checkEmailRegistered($email: String!) {
    checkEmailRegistered(email: $email)
  }
  `;
  return gql_post<boolean>("checkEmailRegistered", data, params);
};

const adminUserUpdateSchema: GQLSchema = {
  name: "adminUserUpdate",
  op: "mutation",
  params: {
    accountId: "String",
    orgId: "String!",
    user: "AdminUserUpdateInput!",
  },
};

export const fetchAdminUserUpdate = (params: {
  accountId?: string;
  orgId?: string;
  user: userOrgUpdateInterface;
}) => {
  params.user = userOrgUpdateInterface.toGQL(params.user);
  return gqlOp(adminUserUpdateSchema, params, new userOrgInterface()).then<userOrgInterface>(
    (user) => userOrgInterface.fromGQL(user),
  );
};
