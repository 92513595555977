import React, { useEffect, useRef, useState } from "react";
import useMBContext from "context/useMBContext";
import {
  OPTIONS,
  poolUserInterface,
  poolInterface,
  selectItemInterface,
  updatePoolInterface,
  VANPOOLER_ROLE,
  fetchUpdateVanpoolV2,
  fetchUpdateVanpoolServiceV2,
  vanpoolServiceUpdateInterface,
  fetchVanpoolsV2,
} from "interfaces/pool";
import {
  Button,
  Checkbox,
  CheckPicker,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Schema,
  CheckboxGroup,
  Notification,
  HelpBlock,
  Modal,
  Input,
} from "rsuite";
import moment from "moment";
import { useHistory, useParams } from "react-router";
import { params } from "interfaces/params";
import { routes } from "misc/http";
import { FormInstance } from "rsuite/lib/Form";
import "./PoolsEditor.scss";
import { ORG_USER_ROLE } from "interfaces/user";
import { mapLine, mapPin } from "interfaces/map";
import { serviceInterface } from "interfaces/schedule";
import { placeInterface } from "interfaces/place";
import { ServiceItem } from "./ServiceEditor";
import { computeRoute, makeStopDisplay, serviceBookableOn } from "./PoolsEditorUtils";
import { vehicleInterface } from "api/vehicle";

const { REACT_APP_RIDER_APP } = process.env;

interface PoolServicesEditorInterface {
  className?;
  vanpool: poolInterface;

  onEdit?: (editing: boolean) => void;
  setRoute?: (pins: mapPin[], lines: mapLine[]) => void;
  places: selectItemInterface<placeInterface>[];
}

const PoolServicesEditor: React.FC<PoolServicesEditorInterface> = ({
  vanpool,
  setRoute,
  places,
  onEdit,
}) => {
  const [services, _setServices] = useState<serviceInterface[]>([]);

  const setServices = (services: serviceInterface[]) => {
    // display services in reverse chronological order (most recent start -> first)
    services.sort((a, b) => moment(b.startDate).unix() - moment(a.startDate).unix());
    _setServices(services);
  };

  useEffect(() => {
    setServices(vanpool.services);
  }, [vanpool.services]);

  const upcomingServices = services.filter((x) => x.startDate.isAfter(moment(), "day"));
  const activeServices = services.filter((x) => serviceBookableOn(vanpool, x)).slice(-1);
  const previousServices = services.filter((x) => x.endDate?.isBefore(moment(), "day"));

  const [newService, setNewService] = useState<serviceInterface | undefined>();
  const [editingService, setEditingService] = useState<serviceInterface | undefined>();

  const handleNewService = () => {
    // empty if this is the first service for this vanpool
    const blankService = {
      shortName: "Winter schedule",
      startDate: moment().add(1, "year"),
      availableDays: ["MO", "TU", "WE", "TH", "FR"],
      outbound: {
        stops: [],
        legs: [],
      },
      inbound: {
        stops: [],
        legs: [],
      },
    };
    const lastService = services[0];
    const lastDate = moment.unix(
      Math.max(
        (lastService?.startDate || lastService?.endDate || moment()).unix(),
        moment().unix(),
      ),
    );
    const resolvedService = {
      ...blankService,
      ...lastService,
      // we start 1 year after the maximum date so that the service is NOT immediately bookable
      startDate: lastDate.add(1, "year"),
      // some great casting here, thanks typescript!
      id: undefined,
    } as unknown as serviceInterface;

    setNewService(resolvedService);
    setEditingService(resolvedService);
    setServices([resolvedService, ...services]);
    onEdit?.(true);
  };

  const handleSubmitService = (service: vanpoolServiceUpdateInterface) => {
    if (!editingService) return;
    fetchUpdateVanpoolServiceV2({ vanpoolId: vanpool.id, service: service })
      .then((pool) => {
        setServices(pool.services);
        setEditingService(undefined);
        setNewService(undefined);
        Notification.open({
          title: "Changed saved!",
          placement: "bottomStart",
        });
      })
      .catch((errors) => {
        Notification.open({
          title: "Could not save vanpool",
          description: `${JSON.stringify(errors[0])}`,
          placement: "bottomStart",
        });
      });
  };

  const handleEditService = (service?: serviceInterface) => {
    // canceling a new service (no id) -> delete it
    if (!service && newService) {
      setServices(services.slice(1));
      setNewService(undefined);
    }
    setEditingService(service);
    onEdit?.(!!service);
  };

  const makeServiceItem = (service: serviceInterface, index: number) => {
    const absIndex = services.findIndex((x) => x.id === service.id);
    return (
      <ServiceItem
        vanpool={vanpool}
        isMostRecent={index === 0}
        previousService={services[absIndex + 1]}
        key={service.id || index}
        service={service}
        places={places}
        setRoute={setRoute}
        editing={editingService && service.id === editingService.id}
        onEdit={(e) => handleEditService(e ? service : undefined)}
        canEdit={!editingService}
        onSubmit={handleSubmitService}
      />
    );
  };

  return (
    <>
      <div className="Services-Container">
        <div className="upcoming-header">
          <h4>Upcoming Services</h4>
          {!editingService && <Button onClick={handleNewService}>+ Service</Button>}
        </div>
        {}
        {upcomingServices.map((service, index) => makeServiceItem(service, index))}
        {!upcomingServices.length && "No upcoming services"}
        <div className="section">
          <h4>Active Services</h4>
          {activeServices.map((service, index) => makeServiceItem(service, index))}
          {!activeServices.length && "No active services"}
        </div>
        <div className="section">
          <h4>Previous Services</h4>
          {previousServices.map((service, index) => makeServiceItem(service, index))}
          {!previousServices.length && "No previous services"}
        </div>
      </div>
    </>
  );
};

const PoolDeleteConfirm: React.FC<{ onDelete?: () => void }> = ({ onDelete }) => {
  const [show, setShow] = useState(false);
  const [really, setReally] = useState("");

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>Permanently delete vanpool</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          The vanpool, mypool entries, rides, trips, and changes will be permanently deleted. Type
          "DELETE" below to delete the vanpool.
          <div style={{ marginTop: "10px" }} />
          <Input type="text" value={really} onChange={setReally} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={really !== "DELETE"}
            onClick={() => {
              setShow(false);
              onDelete?.();
            }}
          >
            Delete
          </Button>
          <Button appearance="subtle" onClick={() => setShow(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Button onClick={() => setShow(true)}>Delete</Button>
    </>
  );
};

interface PoolsEditorInterface {
  className?;
  onClose?: () => void;
  setRoute?: (pins: mapPin[], lines: mapLine[]) => void;
  users: selectItemInterface<poolUserInterface>[];
  vehicles: selectItemInterface<vehicleInterface>[];
  places: selectItemInterface<placeInterface>[];
}

interface formInterface {
  id?: string;
  name?: string;
  internalName?: string;
  vehicles: string[];
  options: string[];
  drivers: string[];
  members: string[];
  coordinators: string[];
}

const PoolsEditor: React.FC<PoolsEditorInterface> = ({
  onClose,
  setRoute,
  users,
  vehicles,
  places,
}: PoolsEditorInterface) => {
  const { adminUser, selectedAccount, selectedOrganization, isVPMRole, isMBAdmin } = useMBContext();
  const history = useHistory();

  // pool we're editing or undefined
  const { poolId } = useParams<params>();
  const [vanpool, setVanpool] = useState<poolInterface | undefined>();

  // most data stored as form
  const [formValue, setFormValue] = useState<formInterface | undefined>();
  const [formError, setFormError] = useState<Record<string, string | undefined>>({});
  const form = useRef<FormInstance>();
  const [isVPM, setVPM] = useState<boolean>(false);

  // allowed user roles
  const allowedDrivers = users
    .filter((x) => x.object.roles.includes(ORG_USER_ROLE.DRIVER))
    .map((x) => x.object.id);
  const allowedRiders = users
    .filter((x) => x.object.roles.includes(ORG_USER_ROLE.RIDER))
    .map((x) => x.object.id);
  const allowedCoordinators = users.map((x) => x.object.id);

  const { ArrayType, StringType } = Schema.Types;
  const dynamicModel = Schema.Model({
    name: StringType().isRequired("Please enter a name for this vanpool"),
    internalName: StringType(),
  });
  const traditionalModelBase = Schema.Model({
    vehicles: ArrayType()
      .minLength(1, "Please select at least vehicle")
      .addRule((value, data) => {
        const selectedVehicle = vehicles.find((vehicle) => value.includes(vehicle.object.id));
        if (selectedVehicle && selectedVehicle.object.capacity < data.members.length) {
          return false;
        }
        return true;
      }, "The selected vehicle has insufficient capacity"),
    drivers: ArrayType().minLength(1, "Must have at least one driver"),
  });
  const traditionalModel = Schema.Model.combine(dynamicModel, traditionalModelBase);

  const isDynamic = formValue?.options.includes(OPTIONS.DYNAMIC) || false;
  const model = isDynamic ? dynamicModel : traditionalModel;

  const canEditScheduleProps = !isVPM;
  const [editingService, setEditingService] = useState<boolean>(false);

  const _setPoolData = (pool: poolInterface) => {
    setVanpool(pool);

    const drivers = pool.poolers
      .filter((x) => x.roles.includes(VANPOOLER_ROLE.DRIVER))
      .filter((x) => allowedDrivers.includes(x.pooler.id))
      .map((p) => p.pooler.id);
    const members = pool.poolers
      .filter((x) => x.roles.includes(VANPOOLER_ROLE.RIDER))
      .filter((x) => allowedRiders.includes(x.pooler.id))
      .map((p) => p.pooler.id);
    const coordinators = pool.poolers
      .filter((x) => x.roles.includes(VANPOOLER_ROLE.COORDINATOR))
      .filter((x) => allowedCoordinators.includes(x.pooler.id))
      .map((p) => p.pooler.id);

    const formValues: formInterface = {
      id: pool.id,
      name: pool.name,
      internalName: pool.internalName,
      options: pool.options,
      vehicles: pool.vehicles.map((x) => x.id),
      drivers,
      members,
      coordinators,
    };
    setFormValue(formValues);
  };

  const reloadPoolData = () => {
    if (!poolId) return;

    fetchVanpoolsV2({
      orgId: selectedOrganization?.id,
      accountId: selectedAccount?.id,
      ids: [poolId],
    }).then((pools) => {
      if (!pools?.length) {
        history.push(routes.pools);
      } else {
        _setPoolData(pools[0]);
      }
    });
  };

  const doDelete = () => {
    if (!vanpool) return;

    fetchUpdateVanpoolV2({ pool: { id: vanpool.id, deleted: true } }).then(() => {
      // do a full reload as otherwise the pools list is stale
      window.location.pathname = routes.pools;
    });
  };

  useEffect(() => {
    // update map display for "current" service
    const service =
      vanpool?.services.find((x) => serviceBookableOn(vanpool, x)) ||
      vanpool?.services?.[vanpool?.services.length - 1];
    if (service) {
      const outbound = service.outbound.stops.map((s) => makeStopDisplay(s));
      computeRoute(outbound).then((val) => setRoute?.(...val));
    } else {
      setRoute?.([], []);
    }
  }, [vanpool, editingService]);

  // load existing pool for edit
  useEffect(() => {
    if (!selectedOrganization || !places.length) return;

    if (poolId) {
      reloadPoolData();
    } else {
      const values: formInterface = {
        name: "My New Vanpool",
        internalName: "Vanpool012345",
        vehicles: [],
        options: [OPTIONS.DYNAMIC],
        coordinators: [],
        drivers: [],
        members: [],
      };
      setFormValue(values);
      setVanpool(undefined);
    }
  }, [poolId, selectedOrganization, selectedAccount, places]);

  const handleSubmit = () => {
    if (!formValue || !selectedOrganization) return;
    if (!form.current?.check()) return;

    // construct poolers
    const poolersById: Record<string, VANPOOLER_ROLE[]> = {};
    formValue.members.forEach((m) => {
      (poolersById[m] = poolersById[m] || []).push(VANPOOLER_ROLE.RIDER);
    });
    formValue.drivers.forEach((m) => {
      (poolersById[m] = poolersById[m] || []).push(VANPOOLER_ROLE.DRIVER);
    });
    formValue.coordinators.forEach((m) => {
      (poolersById[m] = poolersById[m] || []).push(VANPOOLER_ROLE.COORDINATOR);
    });
    const poolers = Object.entries(poolersById).map(([userId, roles]) => ({
      userId,
      roles,
    }));

    const pool: updatePoolInterface = {
      id: poolId,
      name: formValue.name || "",
      internalName: formValue.internalName,
      poolers: poolers,
      vehicles: formValue.vehicles,
      options: formValue.options,
    };

    fetchUpdateVanpoolV2({ pool })
      .then((p) => {
        if (!p) return;
        _setPoolData(p);

        // move to edit page
        history.replace(`${routes.pools}/edit/${p.id}`);
        Notification.open({
          title: "Changes saved!",
          placement: "bottomStart",
        });
      })
      .catch((errors) => {
        Notification.open({
          title: "Could not save vanpool",
          description: `${JSON.stringify(errors[0])}`,
          placement: "bottomStart",
        });
      });
  };

  return (
    <>
      <div className="Pools-toolbar">
        <div className="Pools-toolbar-controls">
          <Button onClick={onClose}>Back</Button>
        </div>
        <div className="Pools-toolbar-add"></div>
      </div>
      <div className="Pools-scroll">
        <div className="PoolsEditor">
          {formValue && (
            <>
              <Form
                fluid
                ref={form}
                model={model}
                formValue={formValue}
                formError={formError}
                layout="horizontal"
                onChange={(data) => setFormValue(data as formInterface)}
                onSubmit={handleSubmit}
                onCheck={(errors) => setFormError(errors)}
                style={{ marginTop: "20px" }}
              >
                <FormGroup>
                  <ControlLabel>Name</ControlLabel>
                  <FormControl name="name" disabled={!canEditScheduleProps} />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Internal Name</ControlLabel>
                  <FormControl name="internalName" disabled={!canEditScheduleProps} />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Restrictions</ControlLabel>
                  <FormControl name="options" accepter={CheckboxGroup}>
                    <Checkbox value={OPTIONS.MEMBERS_ONLY} disabled={!canEditScheduleProps}>
                      Members Only
                    </Checkbox>
                    <Checkbox value={OPTIONS.ORGANIZATION_ONLY} disabled={!canEditScheduleProps}>
                      Organization Only
                    </Checkbox>
                    {isMBAdmin && (
                      <Checkbox value={OPTIONS.DYNAMIC} disabled={!canEditScheduleProps}>
                        Dynamic
                      </Checkbox>
                    )}
                  </FormControl>
                </FormGroup>
                {!isDynamic && (
                  <>
                    <FormGroup>
                      <ControlLabel>Drivers</ControlLabel>
                      <FormControl
                        accepter={CheckPicker}
                        data={users.filter((x) => allowedDrivers.includes(x.value))}
                        name="drivers"
                        sticky={true}
                        block={true}
                        disabled={!canEditScheduleProps}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Legacy Members</ControlLabel>
                      <FormControl
                        accepter={CheckPicker}
                        data={users.filter((x) => allowedRiders.includes(x.value))}
                        name="members"
                        sticky={true}
                        block={true}
                        disabled={!canEditScheduleProps}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Coordinators</ControlLabel>
                      <FormControl
                        accepter={CheckPicker}
                        data={users.filter((x) => allowedCoordinators.includes(x.value))}
                        name="coordinators"
                        sticky={true}
                        block={true}
                      />
                    </FormGroup>
                  </>
                )}
                <FormGroup>
                  <ControlLabel>Vehicles</ControlLabel>
                  <FormControl
                    name="vehicles"
                    data={vehicles}
                    accepter={CheckPicker}
                    disabled={!canEditScheduleProps}
                  />
                </FormGroup>
                {isDynamic && (
                  <FormGroup>
                    <ControlLabel>Members</ControlLabel>
                    <FormControl
                      accepter={Button}
                      disabled={vanpool?.services.length == 0}
                      onClick={() =>
                        window.open(
                          `${REACT_APP_RIDER_APP}/admin/mypools/${vanpool?.id}/manage`,
                          "_blank",
                        )
                      }
                    >
                      Manage Members
                    </FormControl>
                    {vanpool?.services.length == 0 && (
                      <HelpBlock>Create a service to start adding members.</HelpBlock>
                    )}
                  </FormGroup>
                )}
                <Button
                  type="submit"
                  disabled={Object.keys(formError || {}).length > 0 || editingService}
                >
                  Save
                </Button>
              </Form>
              {vanpool && (
                <PoolServicesEditor
                  places={places}
                  vanpool={vanpool}
                  onEdit={setEditingService}
                  setRoute={setRoute}
                />
              )}
              {vanpool && isMBAdmin && <PoolDeleteConfirm onDelete={doDelete} />}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PoolsEditor;
