import React, { useEffect, useRef, useState } from "react";
import { getAddresses, geocodeLatLng, mapboxToAddress, addressToPlace } from "api/mapbox";
import "./PlacesEditor.scss";
import useMBContext from "context/useMBContext";
import {
  Modal,
  AutoComplete,
  Button,
  Divider,
  Form,
  FormGroup,
  FormControl,
  FlexboxGrid,
  InputGroup,
  Icon,
  ControlLabel,
  RadioGroup,
  Radio,
  Schema,
} from "rsuite";
import Map from "components/Map";
import { geoPointDataInterface, mapPin } from "interfaces/map";
import { LngLatBounds } from "mapbox-gl";
import { boundsFromPoint } from "misc/utils";
import { fetchPlaceUpdate } from "api/places";
import { placeInterface } from "interfaces/place";

interface selectInterface {
  label: string;
  value: string;
}
interface PlacesEditorInterface {
  onClose?: () => void | undefined;
  refreshData?: () => void | undefined;
  place: placeInterface;
}
const PlacesEditor: React.FC<PlacesEditorInterface> = ({
  onClose,
  refreshData,
  place,
}: PlacesEditorInterface) => {
  const { selectedOrganization, isMBAdmin } = useMBContext();
  const { StringType, NumberType, ObjectType } = Schema.Types;
  const model = Schema.Model({
    name: StringType().isRequired("Please enter a short name for this place"),
    streetAddress: StringType().isRequired("Please enter the street address"),
    city: StringType().isRequired("Please select a place with a valid city"),
    state: StringType().isRequired("Please select a place with a valid state"),
    zip: NumberType().isRequired("Please select a place with a valid ZIP code"),
    county: StringType(),
    coordinates: ObjectType()
      .isRequired()
      .addRule((value) => {
        return (
          value && value.lat >= -90 && value.lat <= 90 && value.lng >= -180 && value.lng <= 180
        );
      }),
  });

  const [formValue, setFormValue] = useState<placeInterface>(place);
  const [pins, setPins] = useState<Array<mapPin>>([]);
  const [bounds, setBounds] = useState<LngLatBounds | null>(null);
  const editor = useRef();
  const [editLatLon, setEditLatLon] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<Array<selectInterface>>([]);
  const [places, setPlaces] = useState<Array<placeInterface>>([]);
  const [notRoutable, setNotRoutable] = useState<boolean>(false);

  useEffect(() => {
    if (formValue.coordinates) {
      setBounds(boundsFromPoint(formValue.coordinates));
      setPins([
        {
          id: 0,
          pinNumber: 1,
          label: formValue.name || "",
          place: formValue,
        },
      ]);
    }
  }, [formValue.coordinates]);

  const handleSuggest = (value: string, event) => {
    value &&
      event.type === "change" &&
      getAddresses(value).then((data: geoPointDataInterface[]) => {
        const places = data.map((f) => addressToPlace(mapboxToAddress(f)));
        const suggestions: Array<selectInterface> = places.map((place, index) => {
          const obj: selectInterface = {
            label: place.name,
            value: place.name,
          };
          return obj;
        });
        setPlaces(places);
        setSuggestions(suggestions);
      });
  };
  const handleSuggestSelect = (value: any, event) => {
    const place = places.find((place) => value.value === place.name)!;
    setFormValue(place);
    const pin = [
      {
        id: 0,
        pinNumber: 1,
        place: place,
        label: place.name || "",
      },
    ];
    setBounds(boundsFromPoint(place.coordinates));
    setPins(pin);
  };
  const handleLat = (value: any, event) => {
    formValue.coordinates && addressFromLatLng(value, Number(formValue.coordinates.lng));
  };
  const handleLng = (value: any, event) => {
    formValue.coordinates && addressFromLatLng(Number(formValue.coordinates.lat), value);
  };
  const addressFromLatLng = (lat: number, lng: number) => {
    if (!(lat < 90 && lat > -90 && lng < 180 && lng > -180)) return;
    geocodeLatLng(lat, lng).then((features) => {
      if (!features?.[0]) {
        setNotRoutable(true);
        return;
      }

      const item = features[0];
      setNotRoutable(!(item.routable_points && item.routable_points?.points.length > 0));

      const obj = addressToPlace(mapboxToAddress(features[0]));
      setFormValue(obj);
      const pin = [
        {
          id: 0,
          pinNumber: 0,
          place: obj,
          label: obj.name,
        },
      ];
      setBounds(boundsFromPoint({ lat, lng }));
      setPins(pin);
    });
  };
  const handleSubmit = (valid: boolean, event) => {
    if (!valid || !selectedOrganization) return;

    formValue.id = place.id;
    fetchPlaceUpdate({ place: formValue }).then((pl) => {
      refreshData?.();
      onClose?.();
    });
  };
  const formUpdate = (data: any, event) => {
    if (event.target.className !== "rs-auto-complete-item") {
      setFormValue({ ...data });
    }
  };
  const toggleEditor = (selector: string, value: any) => {
    if (value === "latlon") {
      setEditLatLon(true);
    } else {
      setEditLatLon(false);
    }
  };
  const mapClick = (lat: number, lng: number) => {
    // small adjustment to current location
    const coordinates = { lat, lng };
    setFormValue((v) => ({
      ...v,
      coordinates,
    }));
  };
  return (
    <Modal size="lg" show={true} onHide={onClose}>
      <Form
        fluid
        formValue={formValue}
        layout="horizontal"
        model={model}
        onSubmit={handleSubmit}
        ref={editor}
        onChange={formUpdate}
      >
        <Modal.Header>
          <Modal.Title>
            {!place.id ? "Add New Place" : "Edit Place"}
            <Divider vertical />
            <RadioGroup
              name="editorType"
              inline
              appearance="picker"
              defaultValue="address"
              onChange={(value) => {
                toggleEditor("editorType", value);
              }}
            >
              <Radio value="address">Address</Radio>
              <Radio value="latlon">Lat/Lon</Radio>
            </RadioGroup>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FlexboxGrid justify="space-between">
            <FlexboxGrid.Item>
              <FormGroup>
                <ControlLabel>Place Name</ControlLabel>
                <FormControl name="name" style={{ width: 200 }} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Street Address</ControlLabel>
                <InputGroup inside style={{ width: 200 }}>
                  <FormControl
                    name="streetAddress"
                    data={suggestions}
                    onChange={handleSuggest}
                    onSelect={handleSuggestSelect}
                    accepter={AutoComplete}
                    readOnly={editLatLon || (!!place.id && !isMBAdmin)}
                    filterBy={() => {
                      return true;
                    }}
                  />
                  {!editLatLon ? (
                    <InputGroup.Addon>
                      <Icon icon="search" />
                    </InputGroup.Addon>
                  ) : (
                    ""
                  )}
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <ControlLabel>City</ControlLabel>
                <FormControl readOnly={true} name="city" style={{ width: 200 }} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>State</ControlLabel>
                <FormControl readOnly={true} name="state" style={{ width: 200 }} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>ZIP Code</ControlLabel>
                <FormControl readOnly={true} name="zip" style={{ width: 100 }} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>County</ControlLabel>
                <FormControl readOnly={true} name="county" style={{ width: 200 }} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Latitude</ControlLabel>
                <InputGroup inside style={{ width: 150 }}>
                  <FormControl
                    onChange={handleLat}
                    value={formValue.coordinates?.lat}
                    readOnly={!editLatLon}
                  />
                  {editLatLon ? (
                    <InputGroup.Addon>
                      <Icon icon="search" />
                    </InputGroup.Addon>
                  ) : (
                    ""
                  )}
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Longitude</ControlLabel>
                <InputGroup inside style={{ width: 150 }}>
                  <FormControl
                    onChange={handleLng}
                    value={formValue.coordinates?.lng}
                    readOnly={!editLatLon}
                  />
                  {editLatLon ? (
                    <InputGroup.Addon>
                      <Icon icon="search" />
                    </InputGroup.Addon>
                  ) : (
                    ""
                  )}
                </InputGroup>
              </FormGroup>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <div className="PlacesEditor-map">
                <Map pins={pins} selectedBounds={bounds} handleClick={mapClick} />
                {notRoutable && (
                  <div className="form-error">That lat/lon may not be accessible by vehicle</div>
                )}
              </div>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Save</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default PlacesEditor;
