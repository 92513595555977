import { addressInterface } from "interfaces/place";
import { GQLSchema, gqlOp } from "misc/http";
import { GQLBase } from "misc/utils";

export class vanpoolSearchSettingsInterface extends GQLBase {
  work = new addressInterface();
  arriveTime = "";
  departTime = "";
  rideDays = [""];
  driveDays? = [""];
  vehicleCapacity? = 0;
}

export class userSearchSettingsInterface extends GQLBase {
  id = "";
  name = "";
  email?: string;
  phone?: string;
  homeAddress = new addressInterface();
  searchSettings = new vanpoolSearchSettingsInterface();
}

const userSearchSettingsSchema: GQLSchema = {
  name: "users",
  op: "query",
  params: {
    accountId: "String",
    orgId: "String!",
    hasSearchSettings: "Boolean",
  },
};

export const fetchPledges = (params: { accountId?: string; orgId?: string }) => {
  const p = { ...params, hasSearchSettings: true };
  return gqlOp(userSearchSettingsSchema, p, [new userSearchSettingsInterface()]);
};
