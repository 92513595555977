import React from "react";
import { stopDisplayInterface, STOP_TYPE } from "interfaces/stop";
import { Button, DatePicker, FlexboxGrid, List, Message, ButtonGroup } from "rsuite";
import "./PoolsEditor.scss";

export enum UPDATE_TYPE {
  DRAG = "DRAG",
  TIME = "TIME",
  STOP_TYPE = "STOP_TYPE",
}

const handleDrag = (dataList: any[], drag) => {
  const newData = [...dataList].map((stop) => ({ ...stop }));
  const movedData = newData.splice(drag.oldIndex, 1);
  newData.splice(drag.newIndex, 0, movedData[0]);
  return newData;
};

const updateScheduleData = (
  schedule: stopDisplayInterface[],
  index,
  update: Record<string, unknown>,
) => {
  const newItem = { ...schedule[index], ...update };
  const newSchedule = [...schedule];
  newSchedule.splice(index, 1, newItem);
  return newSchedule;
};

const toggleStopType = (schedule, index, buttonType: STOP_TYPE) => {
  const stopTypes = schedule[index].stopTypes;
  stopTypes.includes(buttonType)
    ? stopTypes.splice(stopTypes.indexOf(buttonType), 1)
    : stopTypes.push(buttonType);
  return updateScheduleData(schedule, index, { stopTypes: stopTypes });
};

const getButtonStyle = (schedule, index, buttonType: STOP_TYPE) => {
  // first stop must be pickup only and last stop must be dropoff only
  const firstStop = index == 0;
  const lastStop = index == schedule.length - 1;
  if (
    (firstStop && buttonType == STOP_TYPE.PICKUP) ||
    (lastStop && buttonType == STOP_TYPE.DROPOFF) ||
    (!firstStop && !lastStop && schedule[index].stopTypes.includes(buttonType))
  ) {
    return "primary";
  } else {
    return "ghost";
  }
};

const checkFixedButton = (schedule, index, buttonType: STOP_TYPE) => {
  // first and last stop's stop type buttons must be disabled
  // if the current stop type is the only one enabled, don't allow disabling
  if (
    index == 0 ||
    index == schedule.length - 1 ||
    (schedule[index].stopTypes.length == 1 && schedule[index].stopTypes == buttonType)
  ) {
    return true;
  }

  return false;
};

interface ScheduleListProps {
  schedule: stopDisplayInterface[];
  setSchedule: (schedule: stopDisplayInterface[], updateType: UPDATE_TYPE) => void;
  disabled?: boolean;
}

// Schedule editor (one way)
export const ScheduleList: React.FC<ScheduleListProps> = ({
  schedule,
  setSchedule,
  disabled = false,
}) => {
  return (
    <List
      sortable={!disabled}
      bordered
      className="PoolsEditor-Schedule"
      onSort={(drag) => setSchedule(handleDrag(schedule, drag), UPDATE_TYPE.DRAG)}
    >
      {schedule.map((item, index) => (
        <List.Item key={item.place.id} index={index}>
          <FlexboxGrid justify="space-around">
            <FlexboxGrid.Item colspan={24}>{item.place.name}</FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <div className="PoolsEditor-Schedule-Title">Arrive at Stop</div>
              <DatePicker
                onChange={(date) => {
                  setSchedule(
                    updateScheduleData(schedule, index, {
                      arrivalDate: date,
                    }),
                    UPDATE_TYPE.TIME,
                  );
                }}
                format="HH:mm"
                ranges={[]}
                placement="topStart"
                value={item.arrivalDate}
                cleanable={false}
                disabled={disabled}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <div className="PoolsEditor-Schedule-Title">Depart from Stop</div>
              <DatePicker
                onChange={(date) => {
                  setSchedule(
                    updateScheduleData(schedule, index, {
                      departureDate: date,
                    }),
                    UPDATE_TYPE.TIME,
                  );
                }}
                format="HH:mm"
                ranges={[]}
                placement="topEnd"
                value={item.departureDate}
                cleanable={false}
                disabled={disabled}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <div className="PoolsEditor-Schedule-Title">Stop Type</div>
              <ButtonGroup className="PoolsEditor-Schedule-StopType">
                <Button
                  appearance={getButtonStyle(schedule, index, STOP_TYPE.PICKUP)}
                  onClick={
                    checkFixedButton(schedule, index, STOP_TYPE.PICKUP)
                      ? undefined
                      : () =>
                          setSchedule(
                            toggleStopType(schedule, index, STOP_TYPE.PICKUP),
                            UPDATE_TYPE.STOP_TYPE,
                          )
                  }
                  className={
                    checkFixedButton(schedule, index, STOP_TYPE.PICKUP)
                      ? "PoolsEditor-Schedule-StopType-Fixed"
                      : "PoolsEditor-Schedule-StopType-Enabled"
                  }
                  disabled={disabled}
                >
                  Pickup
                </Button>
                <Button
                  appearance={getButtonStyle(schedule, index, STOP_TYPE.DROPOFF)}
                  onClick={
                    checkFixedButton(schedule, index, STOP_TYPE.DROPOFF)
                      ? undefined
                      : () =>
                          setSchedule(
                            toggleStopType(schedule, index, STOP_TYPE.DROPOFF),
                            UPDATE_TYPE.STOP_TYPE,
                          )
                  }
                  className={
                    checkFixedButton(schedule, index, STOP_TYPE.DROPOFF)
                      ? "PoolsEditor-Schedule-StopType-Fixed"
                      : "PoolsEditor-Schedule-StopType-Enabled"
                  }
                  disabled={disabled}
                >
                  Dropoff
                </Button>
              </ButtonGroup>
            </FlexboxGrid.Item>
            {/* If the validation here changes, be sure to change it in the schema above so that form submission will be prevented */}
            {item.arrivalDate.getTime() > item.departureDate.getTime() && (
              <Message
                showIcon
                type="error"
                description="Departure time must be later than arrival time"
              />
            )}
            {schedule[index - 1] &&
              schedule[index - 1].departureDate.getTime() >= item.arrivalDate.getTime() && (
                <Message
                  showIcon
                  type="error"
                  description="Arrival time must be after previous stop departure"
                />
              )}
          </FlexboxGrid>
        </List.Item>
      ))}
    </List>
  );
};
