import React from "react";
import { Input, InputGroup } from "rsuite";
import { FormControlAccepterProps } from "rsuite/lib/FormControl";
import InputGroupAddon from "rsuite/lib/InputGroup/InputGroupAddon";

const FormCurrencyInput: React.FC<FormControlAccepterProps<number>> = ({
  value,
  onChange,
  readOnly = false,
}) => {
  return (
    <InputGroup>
      <InputGroupAddon>$</InputGroupAddon>
      <Input
        value={
          // display value in x.xx format
          ((value || 0) / 100).toFixed(2)
        }
        onChange={(value, event) => {
          // update value in integer (number of cents) format
          onChange?.(parseInt(value.replace(/\D/g, "").replace(/^0+/g, "")), event);
        }}
        disabled={readOnly}
      />
    </InputGroup>
  );
};

export default FormCurrencyInput;
