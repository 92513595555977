import React, { useState, useEffect } from "react";
import "./OrganizationsEditor.scss";
import useMBContext from "context/useMBContext";
import {
  Button,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Schema,
  Toggle,
  Notification,
  CheckPicker,
  SelectPicker,
  ButtonGroup,
} from "rsuite";
import {
  routeMakerInterface,
  pricingInterface,
  updateOrganizationInterface,
  organizationInterface,
  defaultUserSettingsInterface,
} from "interfaces/organization";
import { estimateOneWayPricing } from "misc/utils";
import { addOrganization, editOrganization } from "api/organization";
import FormCurrencyInput from "components/common/CurrencyInput";
import { fetchPlaces } from "api/places";
import { selectItemInterface } from "interfaces/pool";
import { OrganizationUserFlags, OrganizationUserRoles, ORG_USER_ROLE } from "interfaces/user";
import { placeInterface } from "interfaces/place";
import { customFieldInterface, CUSTOM_FIELD_TARGET, fetchCustomFieldUpdate } from "api/customfield";
import { CustomFieldEditor } from "components/common/CustomFields/CustomFieldEditor";

const { REACT_APP_RIDER_APP } = process.env;

export interface OrganizationsEditorInterface {
  org?: organizationInterface;
  onClose?: () => void | undefined;
}

export const OrganizationsEditor: React.FC<OrganizationsEditorInterface> = ({
  org,
  onClose,
}: OrganizationsEditorInterface) => {
  const { selectedAccount, isMBAdmin } = useMBContext();
  const { StringType } = Schema.Types;
  const editModel = Schema.Model({
    name: StringType().isRequired("Please enter an organization name"),
  });

  const newModel = Schema.Model.combine(
    editModel,
    Schema.Model({
      code: StringType()
        .isRequired("Please select an organization code")
        .maxLength(6, "Code should not be more than 6 characters long"),
    }),
  );

  interface formInterface {
    code: string;
    name: string;
    termsLink?: string;
    customPricing: boolean;
    defaultMapLocation?: string;
  }

  const [formValue, setFormValue] = useState<formInterface | undefined>();
  const [formError, setFormError] = useState<Record<string, string | undefined>>({});
  const [routeMaker, setRouteMaker] = useState<routeMakerInterface | undefined>();
  const [places, setPlaces] = useState<selectItemInterface<placeInterface>[]>([]);

  const defaultPricing: pricingInterface = {
    baseRate: 0,
    perMileRate: 100,
  };
  const [pricing, setPricing] = useState<pricingInterface>(defaultPricing);

  const defaultUserSettings: defaultUserSettingsInterface = {
    roles: [ORG_USER_ROLE.RIDER],
    flags: [],
    privateNetworkOnly: false,
  };
  const [userSettings, setUserSettings] =
    useState<defaultUserSettingsInterface>(defaultUserSettings);
  const [customFields, setCustomFields] = useState<customFieldInterface[]>([]);

  useEffect(() => {
    if (org) {
      setFormValue({
        name: org.name,
        code: org.code,
        termsLink: org.termsLink,
        customPricing: !!org.adhocPricing,
        defaultMapLocation: org.defaultMapLocation?.id,
      });
      setCustomFields(org.customFields);
      setRouteMaker(org.routeMaker);
      setPricing(org.adhocPricing || defaultPricing);
      setUserSettings(org.userSettings);
      fetchPlaces({ orgId: org.id }).then((places) => {
        const display = places.map(
          (p: placeInterface): selectItemInterface<placeInterface> => ({
            value: p.id,
            label: p.name,
            object: p,
          }),
        );
        setPlaces(display);
      });
    } else {
      setFormValue({
        name: "",
        code: "",
        customPricing: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculatePriceEstimate = (mileDistance: number) => {
    const baseRate = pricing.baseRate;
    const perMileRate = pricing.perMileRate;
    return estimateOneWayPricing(baseRate, perMileRate, mileDistance) * 2;
  };

  const onSubmit = (valid: boolean, event) => {
    if (!valid || !formValue || !selectedAccount) return;

    try {
      JSON.parse(routeMaker?.signupSchema || "{}");
    } catch {
      Notification.open({
        title: "SignUp Schema wasn't valid JSON",
        description: routeMaker?.signupSchema,
        placement: "bottomStart",
      });
      return;
    }

    const rm =
      Object.values(routeMaker || {}).filter((x) => ![null, undefined].includes(x)).length > 0
        ? routeMaker
        : undefined;
    const adhocPricing = formValue.customPricing ? pricing : undefined;

    const organization: updateOrganizationInterface = {
      name: formValue.name,
      termsAndConditionsLink: formValue.termsLink,
      routeMaker: rm,
      adhocPricing: adhocPricing,
      userSettings,
      defaultMapLocation: formValue.defaultMapLocation,
    };

    const params = {
      accountId: selectedAccount.id,
      code: formValue.code.toUpperCase(),
      organization,
      id: org?.id || "",
    };

    const res = org ? editOrganization(params) : addOrganization(params);
    res
      .then((result) => {
        Notification.open({
          title: "Changes saved!",
          placement: "bottomStart",
        });
        onClose && onClose();
      })
      .catch((errors) => {
        Notification.open({
          title: "Could not save changes",
          description: `${JSON.stringify(errors?.[0])}`,
          placement: "bottomStart",
        });
      });
  };

  const [customFieldIndex, setCustomFieldIndex] = useState<number | undefined>();

  const addCustomField = () => {
    setCustomFieldIndex(0);
    setCustomFields((fields) => [undefined as unknown as customFieldInterface, ...fields]);
  };

  const handleSaveCustomField = (field: customFieldInterface | undefined, index) => {
    if (!field) {
      // cancel
      const fields = [...customFields];
      if (!fields[index]) {
        // delete unsaved custom field
        fields.splice(index, 1);
        setCustomFields(fields);
      }
      setCustomFieldIndex(undefined);
    } else {
      fetchCustomFieldUpdate({ orgId: org?.id, field: field }).then((newField) => {
        const fields = [...customFields];
        fields.splice(index, 1, newField);
        setCustomFields(fields);
        setCustomFieldIndex(undefined);
      });
    }
  };

  return (
    <>
      {formValue && (
        <Form
          fluid
          formValue={formValue}
          layout="horizontal"
          model={org !== undefined ? editModel : newModel}
          onSubmit={onSubmit}
          onChange={(value) => {
            setFormValue(value as formInterface);
          }}
          formError={formError}
          onError={setFormError}
          style={{ height: "100%" }}
        >
          <div className="Organizations">
            <div className="Organizations-toolbar">
              <div className="Organizations-toolbar-controls">
                <ButtonGroup>
                  <Button className="Organizations-button" onClick={() => onClose?.()}>
                    Back
                  </Button>
                </ButtonGroup>
                <ButtonGroup>
                  <Button className="Organizations-button" type="submit">
                    Save
                  </Button>
                </ButtonGroup>
              </div>
            </div>
            <div className="scroll-container">
              <div className="content-container">
                {!org?.id ? "Add Organization" : "Edit Organization"}
                <FormGroup>
                  <ControlLabel>Name</ControlLabel>
                  <FormControl name="name" />
                </FormGroup>
                {!org && (
                  <FormGroup>
                    <ControlLabel>Code</ControlLabel>
                    <FormControl name="code" />
                  </FormGroup>
                )}
                <FormGroup>
                  <ControlLabel>Terms and Conditions Link</ControlLabel>
                  <FormControl name="termsLink" />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Default Map Location</ControlLabel>
                  <FormControl
                    accepter={SelectPicker}
                    data={places}
                    name="defaultMapLocation"
                    style={{ width: "300px" }}
                  />
                </FormGroup>
                <p>
                  <b>Default Settings for New Users</b>
                </p>
                <FormGroup>
                  <ControlLabel>Roles</ControlLabel>
                  <FormControl
                    name="userSettingsRoles"
                    value={userSettings.roles}
                    onChange={(v) => setUserSettings((u) => ({ ...u, roles: v }))}
                    accepter={CheckPicker}
                    data={OrganizationUserRoles}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Flags</ControlLabel>
                  <FormControl
                    name="userSettingsFlags"
                    value={userSettings.flags}
                    onChange={(v) => setUserSettings((u) => ({ ...u, flags: v }))}
                    accepter={CheckPicker}
                    data={OrganizationUserFlags}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Options</ControlLabel>
                  <Toggle
                    checked={userSettings.privateNetworkOnly}
                    size="lg"
                    checkedChildren="Private Network Only"
                    unCheckedChildren="Org + Public Vanpools"
                    onChange={(v) =>
                      setUserSettings((u) => ({
                        ...u,
                        privateNetworkOnly: v,
                      }))
                    }
                  />
                </FormGroup>
                <p>
                  <b>RouteMaker</b>
                </p>
                <FormGroup>
                  <ControlLabel>Organization Logo URL</ControlLabel>
                  <FormControl
                    name="routeMakerLogoURL"
                    value={routeMaker?.logoURL}
                    onChange={(url) =>
                      setRouteMaker((rm) => ({
                        ...rm,
                        logoURL: url?.length ? url : undefined,
                      }))
                    }
                  />
                </FormGroup>
                {routeMaker?.logoURL && (
                  <img
                    src={routeMaker.logoURL}
                    width="100%"
                    style={{ marginBottom: "20px" }}
                    alt={`${org?.name} logo`}
                  ></img>
                )}
                <FormGroup>
                  <ControlLabel>Organization Welcome Message</ControlLabel>
                  <FormControl
                    name="routeMakerWelcome"
                    value={routeMaker?.welcomeMessage}
                    onChange={(msg) =>
                      setRouteMaker((rm) => ({
                        ...rm,
                        welcomeMessage: msg?.length ? msg : undefined,
                      }))
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Default Work Address</ControlLabel>
                  <FormControl
                    accepter={SelectPicker}
                    data={places}
                    onChange={(place) => {
                      setRouteMaker((val) => ({
                        ...val,
                        defaultPlaceId: place,
                        allowedPlaceIds: undefined,
                      }));
                    }}
                    value={routeMaker?.defaultPlaceId}
                    placement="bottomStart"
                    style={{ width: "300px" }}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Allowed Work Addresses</ControlLabel>
                  <FormControl
                    accepter={CheckPicker}
                    sticky={true}
                    block={true}
                    data={places}
                    value={routeMaker?.allowedPlaceIds || []}
                    onChange={(value) => {
                      setRouteMaker((val) => ({
                        ...val,
                        defaultPlaceId: undefined,
                        allowedPlaceIds: value,
                      }));
                    }}
                    placement="bottomStart"
                    style={{ width: "300px" }}
                  />
                </FormGroup>
                {org && (
                  <FormGroup>
                    <ControlLabel>RouteMaker Link</ControlLabel>
                    <FormControl
                      value={`${REACT_APP_RIDER_APP}/routemaker/${org?.id}`}
                      readonly="readonly"
                    />
                  </FormGroup>
                )}
                {org && isMBAdmin && (
                  <FormGroup>
                    <ControlLabel>SignUp Schema</ControlLabel>
                    <FormControl
                      componentClass="textarea"
                      value={routeMaker?.signupSchema}
                      onChange={(value) => {
                        setRouteMaker((val) => ({
                          ...val,
                          signupSchema: value,
                        }));
                      }}
                    />
                  </FormGroup>
                )}
                <p>
                  <b>Organization Vanpool Pricing</b>
                </p>
                <p>
                  Set pricing for all vanpools in this organization - pricing can also be customized
                  for each pool.
                </p>
                <FormGroup>
                  <ControlLabel>Custom Pricing</ControlLabel>
                  <FormControl
                    accepter={Toggle}
                    name="customPricing"
                    checked={formValue.customPricing}
                    onChange={(value) => !value && setPricing(defaultPricing)}
                    style={{ marginTop: "5px" }}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Base Rate</ControlLabel>
                  <FormControl
                    accepter={FormCurrencyInput}
                    value={pricing?.baseRate}
                    onChange={(base) => {
                      setPricing({
                        ...pricing,
                        baseRate: base || 0,
                      });
                    }}
                    readOnly={!formValue.customPricing}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Per Mile Rate</ControlLabel>
                  <FormControl
                    accepter={FormCurrencyInput}
                    value={pricing?.perMileRate}
                    onChange={(perMile) => {
                      setPricing({
                        ...pricing,
                        perMileRate: perMile || 0,
                      });
                    }}
                    readOnly={!formValue.customPricing}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Sample 20 mile round trip</ControlLabel>
                  <FormControl
                    accepter={FormCurrencyInput}
                    value={calculatePriceEstimate(10)}
                    readOnly={true}
                  />
                </FormGroup>
                <p>
                  <b>Custom Fields</b>
                </p>
                <p>Custom fields applied to this organization.</p>
                <Button onClick={addCustomField} disabled={customFieldIndex !== undefined}>
                  Add Custom Field
                </Button>
                {customFields.map((value, index) => {
                  return (
                    <CustomFieldEditor
                      key={value?.id || index}
                      internalFields={customFields
                        .filter((x) => x?.target === CUSTOM_FIELD_TARGET.NONE && !!x.id)
                        .map((x) => ({ id: x.id!, name: x.name }))}
                      initialCustomField={value}
                      canEdit={customFieldIndex === undefined}
                      onSave={
                        customFieldIndex === index
                          ? (field) => handleSaveCustomField(field, index)
                          : undefined
                      }
                      onEdit={() => setCustomFieldIndex(index)}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </Form>
      )}
    </>
  );
};
