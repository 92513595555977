import React, { Suspense } from "react";
import "./Icon.scss";

interface iPropsIcon {
  name: string;
  className?: string;
  animate?: boolean;
}

const mappingNames: { [key: string]: string } = {
  home: "Home",
  work: "Work",
  routeStart: "VanPin",
  routeEnd: "BuildingPin",
  menu: "Menu",
  close: "Close",
  successCheck: "SuccessCheckGreenRound",
  compass: "Compass",
  plus: "Plus",
  dash: "Dash",
};

const Icon: React.FC<iPropsIcon> = ({ name, className, animate }: iPropsIcon) => {
  const IconComponent = React.lazy(
    () => import(`components/common/Icon/Icons/${mappingNames[name] || mappingNames["home"]}`),
  );

  return (
    <Suspense fallback={<i />}>
      <IconComponent className={className} animate={animate} />
    </Suspense>
  );
};

export default Icon;
