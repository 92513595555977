import React, { useEffect, useState } from "react";
import "./Organizations.scss";
import { Button, ButtonGroup, Input, InputGroup, Icon, Table } from "rsuite";
import { OrganizationsEditor, OrganizationsEditorInterface } from "components/OrganizationsEditor";
import useMBContext from "context/useMBContext";
import { organizationBasicInterface, organizationInterface } from "interfaces/organization";
import { fetchOrganizations, fetchOrganizationsFull } from "api/organization";
import { Link, Route, Switch, useHistory, useParams } from "react-router-dom";
import { routes } from "misc/http";
import { ActionCell } from "components/common/Table";

const { Cell, Column, HeaderCell } = Table;

const OrganizationEditBouncer: React.FC<Partial<OrganizationsEditorInterface>> = (props) => {
  interface Params {
    id: string;
  }
  const { id } = useParams<Params>();
  const history = useHistory();
  const [organization, setOrganization] = useState<organizationInterface | undefined>();

  useEffect(() => {
    fetchOrganizationsFull({ ids: [id] }).then((orgs) => {
      if (!orgs.length) {
        history.replace(routes.organizations);
      } else {
        setOrganization(orgs[0]);
      }
    });
  }, [id]);

  return <>{organization && <OrganizationsEditor org={organization} {...props} />}</>;
};

const OrganizationsList: React.FC = ({}) => {
  const { isMBAdmin, isAccManagerRole, selectedAccount } = useMBContext();

  const [organizations, setOrganizations] = useState<organizationBasicInterface[]>([]);
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    refreshData();
  }, [selectedAccount]);

  const refreshData = () => {
    fetchOrganizations({ accountId: selectedAccount?.id }).then((orgs) => {
      setOrganizations(orgs);
    });
  };

  const filteredOrganizations = organizations.filter(
    (v) =>
      !searchTerm ||
      v.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      v.code.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleEdit = (row: organizationBasicInterface) => {
    history.push(`${routes.organizations}/edit/${row.id}`);
  };

  return (
    <div className="Organizations">
      <div className="Organizations-toolbar">
        <div className="Organizations-toolbar-controls">
          <InputGroup>
            <Input placeholder="Find Organizations" onChange={(value) => setSearchTerm(value)} />
            <InputGroup.Addon>
              <Icon icon="search" />
            </InputGroup.Addon>
          </InputGroup>
          {(isMBAdmin || isAccManagerRole) && (
            <ButtonGroup>
              <Link to={`${routes.organizations}/add`}>
                <Button className="Organizations-button">Add Organization</Button>
              </Link>
            </ButtonGroup>
          )}
        </div>
      </div>
      <div className="scroll-container">
        <Table autoHeight data={filteredOrganizations} affixHeader={0}>
          <Column flexGrow={1}>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="name" />
          </Column>
          <Column>
            <HeaderCell>Code</HeaderCell>
            <Cell dataKey="code" />
          </Column>
          <Column>
            <HeaderCell>Action</HeaderCell>
            <ActionCell name="Edit" onClick={handleEdit} />
          </Column>
        </Table>
      </div>
    </div>
  );
};

const Organizations: React.FC = () => {
  const { isMBAdmin } = useMBContext();
  const history = useHistory();
  const handleClose = () => {
    history.push(routes.organizations);
  };

  return (
    <>
      {isMBAdmin ? (
        <Switch>
          <Route path={`${routes.organizations}/add`}>
            <OrganizationsEditor onClose={handleClose} />
          </Route>
          <Route path={`${routes.organizations}/edit/:id`}>
            <OrganizationEditBouncer onClose={handleClose} />
          </Route>
          <Route path="/">
            <OrganizationsList />
          </Route>
        </Switch>
      ) : (
        <p>You do not have access to this page.</p>
      )}
    </>
  );
};

export default Organizations;
