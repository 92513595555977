import { placeInterface } from "interfaces/place";
import { GQLBase } from "misc/utils";

export enum STOP_TYPE {
  PICKUP = "PICKUP",
  DROPOFF = "DROPOFF",
}

export class stopInterface extends GQLBase {
  arrival = "";
  departure = "";
  place: placeInterface = new placeInterface();
  stopType?: STOP_TYPE;
}

export interface stopDisplayInterface extends stopInterface {
  arrivalDate: Date;
  departureDate: Date;
  stopTypes: STOP_TYPE[];
}
