import { selectInterface } from "interfaces/selectInterface";
import { LngLat, LngLatBounds, LngLatLike } from "mapbox-gl";

declare global {
  export interface Array<T> {
    /**
     * Returns a sorted copy of the array.
     * @param compareFn Function used to determine the order of the elements. It is expected to return
     * a negative value if first argument is less than second argument, zero if they're equal and a positive
     * value otherwise. If omitted, the elements are sorted in ascending, ASCII character order.
     * @returns A new array containing the sorted elements.
     */
    toSorted(compareFn?: (a: T, b: T) => number): T[];
  }
}

if (!Array.prototype.toSorted) {
  /**
   * Returns a sorted copy of the array.
   * The sort is not necessarily stable. The default sort order is according to string Unicode code points.
   * @param {Function} [compareFn] Specifies a function that defines the sort order.
   * @returns {Array} A new sorted array.
   */
  Array.prototype.toSorted = function (compareFn) {
    return [...this].sort(compareFn);
  };
}

// eslint-disable-next-line
export class GQLBase extends Object {}

export class GQLTransformer {
  // eslint-disable-next-line
  static fromGQL(data) {}
}

// eslint-disable-next-line
export function slice<T1 extends Object, T2 extends Object>(klass: T1, data: T2) {
  const res: T1 = {} as T1;
  Object.keys(klass).forEach((k) => {
    if (data.hasOwnProperty(k)) {
      res[k] = data[k];
    }
  });
  return res;
}

export function makeFields<T extends GQLBase>(obj: T) {
  const names = Object.entries(obj)
    .map(([key, value]) => {
      const v = Array.isArray(value) ? value[0] : value;
      return v instanceof GQLBase
        ? key + " { " + makeFields(v) + " }"
        : Array.isArray(value) && v === undefined
        ? ""
        : key;
    })
    .join(" ");
  return names;
}

// doesn't have types?
/* eslint-disable */
const polyline = require("@mapbox/polyline");

export const flattenObject = (flattendObj, obj) => {
  if (obj) {
    Object.keys(obj).forEach((key) => {
      const newKey = `${key}`;
      if (typeof obj[key] === "object") {
        // calling the function again
        flattenObject(flattendObj, obj[key]);
      } else {
        flattendObj[newKey] = obj[key];
      }
    });
  }
};

export const dateFormat = (date) => {
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  return date.getFullYear() + "-" + month + "-" + day;
};

// Date -> 16:30
export const formatTime = (d: Date): string => {
  return `${d.getHours()}:${d.getMinutes()}`;
};

// time: 16:30 -> Date
export const parseTime = (time: string, date: Date) => {
  return new Date(new Date(date).setHours(Number(time.split(":")[0]), Number(time.split(":")[1])));
};

export const compare = (a: selectInterface, b: selectInterface) => {
  if (a.label !== null && b.label !== null) {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
  }
  return 0;
};

export const compareOpt = (
  a: any | undefined,
  b: any | undefined,
  func?: (av: any, bv: any) => number,
): number => {
  // sort values that may be undefined, keeping undefined first when sorted ascending
  if (!func) func = (av: any, bv: any) => av - bv;
  return a !== undefined && b !== undefined
    ? func(a, b)
    : a === undefined
    ? -1
    : b === undefined
    ? 1
    : 0;
};

export const formatPhoneNumber = (phoneInput) => {
  if (phoneInput) {
    const phone = phoneInput.replace(/[^+\d]/g, "");
    if (/^(?!\+)\d{10}$/.test(phone)) {
      // US number but user left out country code
      return "+1" + phone;
    } else if (/^[1]\d{10}$/.test(phone)) {
      // US number but the user left out the +
      return "+" + phone;
    } else if (/^(\+[1-9]\d{1,14})$/.test(phone)) {
      // number is in valid E.164 format
      return phone;
    }
  }
  return null;
};

export const formatPhoneNumberDisplay = (phone) => {
  if (phone) {
    if (phone.length == 12) {
      const match = phone.replace(/\D/g, "").match(/^(\d{1,2}?)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        const intlCode = match[1] ? `+${match[1]} ` : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
      }
    } else {
      return phone;
    }
  }
  return undefined;
};

/** @returns either a valid email address or undefined  */
export const formatEmail = (emailInput: string | undefined): string | undefined => {
  const em = (emailInput || "").toLowerCase().trim();
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(em) ? em : undefined;
};

// polylines and coordinate arrays for display have opposite (lat,lng) <-> (lng,lat)
// orderings, so we flip on decode and encode.
export const decodePolyline = (poly: string) => {
  const decodedPoly = polyline.decode(poly);
  return decodedPoly.map((coords) => [coords[1], coords[0]]);
};

export const boundsFromPolyline = (polyline: number[]): LngLatBounds => {
  const bounds = new LngLatBounds();
  polyline.map((segment) => {
    bounds.extend([segment[0], segment[1]]);
  });
  return bounds;
};

export const boundsFromPoint = (coord: LngLatLike, offset: number = 0.01): LngLatBounds => {
  const sw = LngLat.convert(coord);
  const ne = LngLat.convert(coord);
  sw.lat -= offset;
  sw.lng -= offset;
  ne.lat += offset;
  ne.lng += offset;
  return new LngLatBounds(sw, ne);
};

export const lineColors = [
  "rgba(148, 0, 211,0.5)",
  "rgba(75, 0, 130,0.5)",
  "rgba(0, 0, 255,0.5)",
  "rgba(0, 255, 0,0.5)",
  "rgba(255, 255, 0,0.5)",
  "rgba(255, 127, 0,0.5)",
  "rgba(255, 0 , 0,0.5)",
];

// baseRate and perMileRate are in cents ($10 = 1000)
export const estimateOneWayPricing = (
  baseRate: number,
  perMileRate: number,
  estimateDistance: number,
) => {
  return baseRate + perMileRate * estimateDistance;
};
