import axios, { AxiosError } from "axios";
import { makeFields } from "./utils";

const { REACT_APP_API_URL, REACT_APP_USER_MANAGEMENT_APP } = process.env;

// FIXME this is kinda gross, but we have to update all the env vars in dev/stage/prod to fix...
const graphqlAPIURL = REACT_APP_API_URL!;
const authURL = REACT_APP_API_URL!.replace("graphql", "authorize");

const http = axios.create({ timeout: 200000 });

http.interceptors.request.use(
  (config) => {
    config.headers["Access-Control-Allow-Origin"] = "*";
    return config;
  },
  (error: AxiosError) => Promise.reject(error),
);

http.interceptors.response.use(
  (r) => {
    if (r?.data?.errors?.[0]?.code == "INVALID_TOKEN") {
      // try to refresh
      return http
        .post(authURL + "/refresh", {}, { withCredentials: true })
        .then((res) => {
          return http.request(r.config);
        })
        .catch((errors) => {
          // if refresh fails, redirect to login screen and throw
          console.log("refresh failed ", errors);
          window.location.href = routes.user.login;
          throw errors;
        });
    } else {
      return r;
    }
  },
  (error) => {
    return Promise.reject(error);
  },
);

/**
 * For external URLs and API calls
 */
export const url = {
  log: "/log",
};

/**
 * Project routes
 */
export const routes = {
  main: "/",
  not_found: "/404",
  organizations: "/organizations",
  users: "/users",
  vehicles: "/vehicles",
  places: "/places",
  pools: "/pools",
  waitlist: "/waitlist",
  reporting: "/reporting",
  user: {
    login: `${REACT_APP_USER_MANAGEMENT_APP}/login`,
    signup: `${REACT_APP_USER_MANAGEMENT_APP}/registration`,
  },
};

export const authorize_action = (action, params = {}) => {
  return http.post(authURL + "/" + action, params, { withCredentials: true });
};

export const authorize_action_log_out = () => {
  return authorize_action("logout");
};

export const api_post = async (data: any) => {
  return http.post(graphqlAPIURL, data, { withCredentials: true });
};

// Resolve with query/mutation data, or reject with errors array
export const gql_post = async <T = any>(
  name: string,
  data: string,
  variables?: any,
): Promise<T> => {
  const always_vars = {
    accountId: localStorage.getItem("accountId"),
    orgId: localStorage.getItem("orgId"),
  };
  const vars = { ...always_vars, ...variables };
  return api_post({ query: data, variables: vars })
    .then((data) => {
      if (data?.data?.errors) throw data.data.errors;
      if (![undefined].includes(data?.data?.data?.[name])) {
        return data.data.data[name];
      }
      throw data.data.errors;
    })
    .catch((err) => {
      throw err;
    });
};

export default http;

type GQLOperation = "query" | "mutation";

export interface GQLSchema {
  // eslint-disable-next-line
  params: {};
  name: string;
  op: GQLOperation;
}

export function gqlOp<T>(schema: GQLSchema, params: Record<string, any>, extraction: T) {
  const sig1 = Object.entries(schema.params)
    .map(([key, value]) => `\$${key}: ${value}`)
    .join(", ");
  const sig2 = Object.entries(schema.params)
    .map(([key, value]) => `${key}: \$${key}`)
    .join(", ");
  const fields = makeFields(Array.isArray(extraction) ? extraction[0] : extraction);
  const query = `${schema.op} ${schema.op === "query" ? "get_" : "mutate_"}${
    schema.name
  }(${sig1}) { ${schema.name}(${sig2}) { ${fields} } }`;
  return gql_post<T>(schema.name, query, params);
}
