import { stopInterface, stopDisplayInterface } from "interfaces/stop";
import { GQLBase } from "misc/utils";
import moment from "moment";
import { pricingInterface } from "./organization";

export class legInterface extends GQLBase {
  polyline = "";
  cost = 0;
  distance = 0;
}

export class scheduleInterface extends GQLBase {
  stops: stopInterface[] = [new stopInterface()];
  legs: legInterface[] = [new legInterface()];
}

export class stopMappingItemInterface extends GQLBase {
  oldId = "";
  newId = "";
}

export class stopMappingInterface extends GQLBase {
  stops = [new stopMappingItemInterface()];
}

export class serviceInterface extends GQLBase {
  id = "";
  shortName = "";
  description = "";
  availableDays: string[] = [""];
  startDate: moment.Moment = moment();
  endDate?: moment.Moment = undefined;
  outbound: scheduleInterface = new scheduleInterface();
  inbound?: scheduleInterface = new scheduleInterface();
  adhocPricing?: pricingInterface = new pricingInterface();
  stopMapping? = new stopMappingInterface();

  static fromGQL = (d: serviceInterface) => ({
    ...d,
    startDate: moment(d.startDate),
    endDate: d.endDate && moment(d.endDate),
  });
  static toGQL = (d: serviceInterface) => ({
    ...d,
    startDate: d.startDate.format("YYYY-MM-DD"),
    endDate: d.endDate?.format("YYYY-MM-DD"),
  });
}

export interface serviceDisplayInterface extends serviceInterface {
  outboundStops: stopDisplayInterface[];
  inboundStops: stopDisplayInterface[];
}
