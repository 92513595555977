import { ntd_report, pool_day, trip_report, trip_report_service } from "interfaces/reporting";
import { gql_post } from "misc/http";
import moment, { Moment } from "moment";

export const fetchReports = async (params: {
  startDate: Date;
  endDate?: Date;
  accountId?: string;
  orgId?: string;
}) => {
  const data = `
    query getTripReport($accountId: String, $orgId: String, $startDate: String!, $endDate: String) {
      tripReport2(accountId: $accountId, orgId: $orgId, startDate: $startDate, endDate: $endDate) {
        dateStart dateEnd perDay services { id name }
      }
    }
  `;

  const variables = {
    ...params,
    endDate: params.endDate || new Date(),
  };

  interface trip_report_gql {
    perDay: string;
    services: trip_report_service[];
    dateStart: string;
    dateEnd: string;
  }

  return gql_post<trip_report_gql>("tripReport2", data, variables).then<trip_report>((r) => {
    const perDay: pool_day[] = JSON.parse(r.perDay);
    perDay.forEach((x) => (x.day = moment(x.day)));
    r.services.forEach((s) => (s.id = s.id!.split("+")[0]));
    return {
      perDay,
      services: r.services,
      dateStart: moment(r.dateStart),
      dateEnd: moment(r.dateEnd),
    };
  });
};

export interface ntd_report_gql {
  dateStart: string;
  dateEnd: string;
  report: string;
}

export const parseNTDReportRaw = (r: ntd_report_gql): ntd_report => {
  const report = JSON.parse(r.report);
  report.mr20s.forEach((x) => {
    x.date = moment(x.date);
  });
  return {
    dateStart: moment(r.dateStart),
    dateEnd: moment(r.dateEnd),
    report,
  };
};

export const fetchNTDReport = (params: {
  startDate: Date;
  endDate?: Date;
  accountId?: string;
  orgId?: string;
}) => {
  const data = `
    query getNTDReport($accountId: String, $orgId: String!, $startDate: String!, $endDate: String) {
      ntdReport(accountId: $accountId, orgId: $orgId, startDate: $startDate, endDate: $endDate) {
        dateStart dateEnd report
      }
    }
  `;

  const variables = {
    ...params,
    endDate: params.endDate || new Date(),
  };

  return gql_post<ntd_report_gql>("ntdReport", data, variables).then<ntd_report>((r) =>
    parseNTDReportRaw(r),
  );
};
