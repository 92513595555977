import React, { createContext, useCallback, useEffect, useState, ReactNode } from "react";

//interfaces
import { errorModalPopUpInterface } from "interfaces/errormodal";
import {
  adminUserInterface,
  accountRoleInterface,
  organizationRoleInterface,
  ORG_USER_ROLE,
} from "interfaces/user";

//APIs
import { getAdminUser } from "api/user";
import { fetchOrganizations } from "api/organization";
import { authorize_action_log_out } from "misc/http";

export interface globalContextInterface {
  loadingAdminUser: boolean;
  adminUser: adminUserInterface | null;
  logout: () => void;
  selectedAccount: accountRoleInterface | undefined;
  setAccount: (id: string) => void;
  selectedOrganization: organizationRoleInterface | undefined;
  setOrganization: (id: string) => void;
  start?: {
    address?: string;
    lat: number;
    lon: number;
  };
  end?: {
    address?: string;
    lat: number;
    lon: number;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorModalData: errorModalPopUpInterface;
  isMBAdmin: boolean;
  isAccManagerRole: boolean;
  isOrgManagerRole: boolean;
  isVPMRole: boolean;
  isAccManagerRoleOrGreater: boolean;
  isOrgManagerRoleOrGreater: boolean;
  isVPMRoleOrGreater: boolean;
}
export interface Props {
  children?: ReactNode;
}
export const GlobalContext = createContext<globalContextInterface>({} as globalContextInterface);

export const GlobalContextProvider: React.FC = ({ children }: Props) => {
  const [loadingAdminUser, setLoadingAdminUser] = useState<boolean>(true);
  const [adminUser, setAdminUser] = useState<adminUserInterface | null>(null);
  const [selectedOrganization, setSelectedOrganization] = useState<
    organizationRoleInterface | undefined
  >(undefined);
  const [selectedAccount, setSelectedAccount] = useState<accountRoleInterface | undefined>(
    undefined,
  );

  const _setAccount = (account?: accountRoleInterface) => {
    setSelectedAccount(account);
    if (account) {
      localStorage.setItem("accountId", account.id);
    } else {
      localStorage.removeItem("accountId");
    }
  };

  const _setOrganization = (organization?: organizationRoleInterface) => {
    setSelectedOrganization(organization);
    if (organization) {
      localStorage.setItem("orgId", organization.id);
    } else {
      localStorage.removeItem("orgId");
    }
  };

  const getAdminUserData = () => {
    return getAdminUser()
      .then((res) => {
        const thisUser = res;

        // select the saved account if available, otherwise default to the first account
        const thisAccount =
          thisUser?.accounts.find((account) => account.id === localStorage.getItem("accountId")) ||
          thisUser.accounts[0];

        // fetch organizations this user has access to, optionally scoped to the account
        const userOrgs = fetchOrganizations({ accountId: thisAccount?.id });
        return userOrgs.then((orgs) => {
          const allOrgs: organizationRoleInterface[] = orgs.map((org) => {
            const existing = thisUser.organizations.find((o) => o.id == org.id);
            return (
              existing || {
                id: org.id,
                name: org.name,
                code: org.code,
                accountId: org.accountId,
                roles: [],
              }
            );
          });

          thisUser.organizations = allOrgs;
          setAdminUser(thisUser);

          // select the saved organization if available, defaulting to the first one
          const thisOrg =
            allOrgs.find((org) => org.id === localStorage.getItem("orgId")) || allOrgs[0];

          _setAccount(thisAccount);
          _setOrganization(thisOrg);
          setLoadingAdminUser(false);
        });
      })
      .catch(() => {
        setLoadingAdminUser(false);
      });
  };

  useEffect(() => {
    !adminUser && getAdminUserData();
  }, [adminUser]);

  // user role for current organization, excluding non-admin roles
  const userRoles = useCallback(() => {
    const mbAccRoles = adminUser?.accounts.find((acc) => acc.isMagicBus)?.roles || [];
    const accRoles = adminUser?.accounts.find((acc) => acc.id == selectedAccount?.id)?.roles || [];
    const orgRoles =
      adminUser?.organizations.find((org) => org.id == selectedOrganization?.id)?.roles || [];
    return accRoles
      .concat(orgRoles)
      .concat(mbAccRoles)
      .filter((r) => ![ORG_USER_ROLE.DRIVER, ORG_USER_ROLE.RIDER].includes(r));
  }, [adminUser, selectedOrganization]);

  // for permissions below, only the highest level permission
  // (admin -> acc manager -> org manager -> vp manager) will be true;
  // a user that's an account manager will NOT also be an org manager, even
  // though they would have those permissions
  // This means that to expose a feature, all allowed roles should be listed explicitly
  // rather than relying on the cascade.

  const isMBAdmin = adminUser?.accounts.find((acc) => acc.isMagicBus) !== undefined;

  /// true if the user can manage the current account
  const isAccManagerRole =
    !isMBAdmin && selectedAccount
      ? [ORG_USER_ROLE.MANAGER, ORG_USER_ROLE.OWNER].some((x) => selectedAccount.roles.includes(x))
      : false;

  /// true if the user can manage the current organization
  const isOrgManagerRole =
    !isAccManagerRole &&
    userRoles().some((x) => [ORG_USER_ROLE.MANAGER, ORG_USER_ROLE.OWNER].includes(x));

  /// true if the user is a vanpool manager (but not an admin)
  const isVPMRole =
    !isOrgManagerRole && userRoles().some((x) => [ORG_USER_ROLE.VANPOOL_MANAGER].includes(x));

  // The permissions below are implicit; they include higher level permissions
  const isAccManagerRoleOrGreater = isAccManagerRole || isMBAdmin;
  const isOrgManagerRoleOrGreater = isOrgManagerRole || isAccManagerRoleOrGreater;
  const isVPMRoleOrGreater = isVPMRole || isOrgManagerRoleOrGreater;

  const logout = () => {
    setAdminUser(null);
    authorize_action_log_out();
  };

  const setAccount = (accountId: string) => {
    const thisAccount = adminUser?.accounts.find((account) => account.id === accountId);
    _setAccount(thisAccount);
  };

  const setOrganization = (orgId: string) => {
    const thisOrg = adminUser?.organizations.find((org) => org.id === orgId);
    if (thisOrg) {
      _setOrganization(thisOrg);
    }
  };

  const [errorModalData, setErrorModalData] = useState<errorModalPopUpInterface>({
    show: false,
    title: "",
    content: "",
  });

  return (
    <GlobalContext.Provider
      value={{
        loadingAdminUser,
        adminUser,
        logout,
        errorModalData,
        selectedAccount,
        setAccount,
        selectedOrganization,
        setOrganization,
        isMBAdmin,
        isAccManagerRole,
        isOrgManagerRole,
        isVPMRole,
        isAccManagerRoleOrGreater,
        isOrgManagerRoleOrGreater,
        isVPMRoleOrGreater,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
