import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "../node_modules/rsuite/dist/styles/rsuite-default.css";
import "assets/styles/index.scss";
import App from "./pages/App";
import { GlobalContextProvider } from "./context/global";
// import { debugContextDevtool } from 'react-context-devtool';

ReactDOM.render(
  <React.StrictMode>
    <GlobalContextProvider>
      <App />
    </GlobalContextProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// Attach root container
// const container = document.getElementById("root");
// debugContextDevtool(container);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
