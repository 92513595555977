import { gql_post } from "misc/http";
import { poolUserInterface } from "interfaces/pool";

export const sendWeeklyPrompt = async (params: {
  accountId?: string;
  orgId?: string;
  user: string;
}) => {
  const data = `
    mutation sendWeeklyPrompt($accountId: String!, $orgId: String!, $user: String!){
      sendWeeklyPrompt(accountId: $accountId, orgId: $orgId, user: $user){
        success
      }
    }
  `;
  return gql_post<{ success: boolean }>("sendWeeklyPrompt", data, params);
};

export const fetchPoolUsers = async (params: { orgId?: string; accountId?: string }) => {
  const data = `query getUsers($accountId: String, $orgId: String!) {
      users(accountId: $accountId, orgId: $orgId) {
        id name email roles
      }
    }
  `;
  return gql_post<poolUserInterface[]>("users", data, params);
};
