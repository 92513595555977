import React, { useEffect, useState } from "react";
import "./DayPicker.scss";

interface dayOfWeek {
  label: string;
  value: string;
}
const days: dayOfWeek[] = [
  { label: "Sunday", value: "SU" },
  { label: "Monday", value: "MO" },
  { label: "Tuesday", value: "TU" },
  { label: "Wednesday", value: "WE" },
  { label: "Thursday", value: "TH" },
  { label: "Friday", value: "FR" },
  { label: "Saturday", value: "SA" },
];

interface DayPickerInterface {
  className?: string;
  selectedDays?: string[];
  onChange?: (availableDays: string[]) => void;
  disabled?: boolean;
}

const DayPicker: React.FC<DayPickerInterface> = ({
  className,
  selectedDays,
  onChange,
  disabled = false,
}: DayPickerInterface) => {
  const [checked, setChecked] = useState<string[]>(selectedDays || []);

  useEffect(() => {
    setChecked(selectedDays || []);
  }, [selectedDays]);

  const handleChange = (value) => {
    let newChecked = [...checked];
    if (newChecked.includes(value)) {
      newChecked = newChecked.filter((y) => y !== value);
    } else {
      newChecked.push(value);
    }
    onChange?.(newChecked);
    setChecked(newChecked);
  };

  return (
    <div className="DayPicker">
      <div className={`DayPicker-Frame ${disabled ? "disabled" : ""}`}>
        {days.map((day) => {
          return (
            <div key={day.label}>
              <input
                className="Input"
                type="checkbox"
                id={day.label}
                checked={checked.includes(day.value)}
                disabled={disabled}
                onChange={() => handleChange(day.value)}
              />
              <label className="Day" htmlFor={day.label}>
                {day.label.substring(0, 3)}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DayPicker;
