import React, { useEffect, useState } from "react";
import { Button } from "rsuite";
import "./PoolsEditor.scss";

interface ServicePanelProps {
  title: string;
  editing?: boolean;
  canEdit?: boolean;
  onExpanded?: (expanded: boolean) => void;
  onEdit?: (editing: boolean) => void;
}

export const ServicePanel: React.FC<ServicePanelProps> = ({
  title,
  children,
  editing: initialEditing = false,
  canEdit = false,
  onExpanded,
  onEdit,
}) => {
  const [expanded, setExpanded] = useState<boolean>(initialEditing);
  const [editing, setEditing] = useState<boolean>(initialEditing);

  useEffect(() => {
    if (initialEditing) {
      setExpanded(true);
    }
    setEditing(initialEditing);
  }, [initialEditing]);

  const handleToggle = () => {
    if (editing) return;
    const exp = !expanded;
    setExpanded(exp);
    onExpanded?.(exp);
  };

  const handleEdit = (e) => {
    // prevent collapse/expand click
    e.preventDefault();
    e.stopPropagation();

    // TODO: add confirmation?
    const edit = !editing;
    onEdit?.(edit);
    setEditing(edit);
  };

  return (
    <div className={"ServicePanel" + (expanded ? " expanded" : "")}>
      <div className="header-container" onClick={handleToggle}>
        <div className="header">
          <div className="title">{title}</div>
          <div className="actions">
            {expanded && canEdit && (
              <Button onClick={handleEdit} appearance={"ghost"}>
                {editing ? "Cancel" : "Edit"}
              </Button>
            )}
          </div>
        </div>
      </div>
      {expanded && <div className="content-container">{children}</div>}
    </div>
  );
};
