import { serviceInterface, stopMappingInterface } from "interfaces/schedule";
import { gqlOp, GQLSchema } from "misc/http";
import { GQLBase } from "misc/utils";
import { pricingInterface } from "./organization";
import { STOP_TYPE } from "./stop";
import { ORG_USER_ROLE } from "./user";

export class poolUserInterface extends GQLBase {
  id = "";
  name = "";
  email = "";
  roles: ORG_USER_ROLE[] = [];
}

// for display in SelectPicker
export interface selectItemInterface<T> {
  label: string;
  value: string;
  object: T;
}

export enum VANPOOLER_ROLE {
  DRIVER = "DRIVER",
  RIDER = "RIDER",
  COORDINATOR = "COORDINATOR",
}

export class poolerInterface extends GQLBase {
  id = "";
  name = "";
}

export class poolerRoleInterface extends GQLBase {
  pooler = new poolerInterface();
  roles: VANPOOLER_ROLE[] = [VANPOOLER_ROLE.RIDER];
}

export enum OPTIONS {
  MEMBERS_ONLY = "MEMBERS_ONLY",
  ORGANIZATION_ONLY = "ORGANIZATION_ONLY",
  DYNAMIC = "DYNAMIC",
}

export class poolVehicleInterface extends GQLBase {
  id = "";
}

export class poolInterface {
  id = "";
  name?: string;
  internalName = "";
  services: serviceInterface[] = [new serviceInterface()];
  options: string[] = [""];
  vehicles: poolVehicleInterface[] = [new poolVehicleInterface()];
  poolers: poolerRoleInterface[] = [new poolerRoleInterface()];

  static fromGQL = (d: poolInterface) => ({
    ...d,
    services: (d.services || []).map((s) => serviceInterface.fromGQL(s)),
  });
}

const poolSchema: GQLSchema = {
  params: {
    accountId: "String",
    orgId: "String",
    vehicleId: "String",
    userId: "String",
    ids: "[String]",
  },
  name: "vanpools",
  op: "query",
};
export interface FetchVanpoolsV2Params {
  orgId?: string;
  accountId?: string;
  vehicleId?: string;
  userId?: string;
  ids?: string[];
}

export function fetchVanpoolsV2<T extends poolInterface = poolInterface>(
  params: FetchVanpoolsV2Params,
  extract = [new poolInterface()],
  mapper = (p) => poolInterface.fromGQL(p),
) {
  return gqlOp<T[]>(poolSchema, params, extract as T[]).then<T[]>(
    (pools) => pools.map(mapper) as T[],
  );
}

export interface poolDisplayInterface extends poolInterface {
  Color: string;
}

export interface updatePoolerInterface {
  userId: string;
  roles: VANPOOLER_ROLE[];
}

export interface updatePoolInterface {
  id?: string;
  deleted?: boolean;
  name?: string;
  internalName?: string;
  options?: string[];
  poolers?: updatePoolerInterface[];
  vehicles?: string[];
}

const poolUpdateSchema: GQLSchema = {
  params: {
    accountId: "String",
    orgId: "String",
    pool: "VanpoolUpdateInput!",
  },
  name: "vanpoolUpdate",
  op: "mutation",
};

export const fetchUpdateVanpoolV2 = (params: { pool: updatePoolInterface }) => {
  return gqlOp<poolInterface>(poolUpdateSchema, params, new poolInterface()).then<
    poolInterface | undefined
  >((p) => p && poolInterface.fromGQL(p));
};

//
// Mutations
//

export interface updateStopInterface {
  placeId: string;
  arrival: string;
  departure: string;
  stopType?: STOP_TYPE;
}

export interface updateScheduleInterface {
  stops: updateStopInterface[];
}

export interface updateServiceInterface {
  shortName?: string;
  description?: string;
  availableDays: string[];
  startDate: string;
  endDate?: string;
  outbound: updateScheduleInterface;
  inbound?: updateScheduleInterface;
  adhocPricing?: pricingInterface;
  stopMapping?: stopMappingInterface;
}

export enum VANPOOL_SERVICE_UPDATE_APPLY {
  AFTER_DATE = "AFTER_DATE",
}

export interface vanpoolServiceUpdateInterface {
  apply?: VANPOOL_SERVICE_UPDATE_APPLY;
  applyDate?: string;
  serviceId?: string;
  service?: updateServiceInterface;
}

const serviceUpdateSchema: GQLSchema = {
  params: {
    accountId: "String",
    orgId: "String",
    vanpoolId: "String!",
    service: "VanpoolServiceUpdateInput!",
  },
  name: "vanpoolServiceUpdate",
  op: "mutation",
};

export const fetchUpdateVanpoolServiceV2 = (params: {
  vanpoolId: string;
  service: vanpoolServiceUpdateInterface;
}) => {
  return gqlOp<poolInterface>(serviceUpdateSchema, params, new poolInterface()).then((p) =>
    poolInterface.fromGQL(p),
  );
};

export interface updatePoolResultInterface {
  ids: string[];
}
