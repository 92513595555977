import React from "react";
import { InputGroup, Input, SelectPicker, ErrorMessage, Checkbox } from "rsuite";
import {
  customFieldLinkedInterface,
  customFieldValueUpdateInterface,
  CUSTOM_FIELD_TYPE,
} from "api/customfield";
import { FormControlAccepterProps, FormControlProps } from "rsuite/lib/FormControl";

export interface CheckResult {
  hasError: boolean;
  errorMessage?: string;
}

type ErrorsObject = { [key: string]: string };

export const isSelect = (type: CUSTOM_FIELD_TYPE) =>
  [CUSTOM_FIELD_TYPE.SELECT, CUSTOM_FIELD_TYPE.SELECT_WITH_OTHER].includes(type);

class _AnyType {
  readonly name = "object";
  shape(types: any) {
    return this;
  }
  checkFn(v: any, d: any) {
    return true;
  }
  errorMessage?: string = undefined;
  addRule(checkFn, errorMessage) {
    this.checkFn = checkFn;
    this.errorMessage = errorMessage;
    return this;
  }

  // Function that will actually be called to validate the data
  check(value, data) {
    if (this.checkFn && !this.checkFn(value, data)) {
      return { hasError: true, errorMessage: this.errorMessage || "Error in field validation." };
    }
    return { hasError: false, errorMessage: "" }; // Return false if there is no error
  }
  isRequired(str) {
    return this;
  }
  isRequiredOrEmpty(str) {
    return this;
  }
}

export const AnyType = () => new _AnyType();

// don't seem to be able to access the CheckResult<> type from Schema here...
export const makeFormError = (errors: { [key: string]: CheckResult }): ErrorsObject => {
  return Object.fromEntries(
    Object.entries(errors)
      .filter(([name, result]) => result.hasError)
      .map(([name, result]) => [name, result.errorMessage!]),
  );
};

export const mergeFormErrors = (arrayErrors: { [key: string]: ErrorsObject[] }) => {
  var allErrors = {};
  Object.entries(arrayErrors).forEach(([key, errors]) => {
    const hasError = errors.some((x) => Object.keys(x).length > 0);
    if (hasError) {
      allErrors[key] = errors;
    }
  });
  return allErrors;
};

type ForwardPropsHOCProps<P, Value> = Omit<FormControlProps<P, Value>, "accepter"> &
  P & {
    // for some reason need to redefine accepter as ComponentType rather than ElementType,
    // or we get an error that it 'does not have any construct or call signatures'.
    accepter: React.ComponentType<P & FormControlAccepterProps<Value>>;
  };

export function FormControlWrapper<P extends object, Value>({
  accepter: Component,
  errorMessage,
  errorPlacement,
  ...rest
}: ForwardPropsHOCProps<P, Value>): React.ReactElement {
  return (
    <div className="derp">
      <Component {...(rest as P)} />
      {errorMessage && (
        <ErrorMessage
          show={!!errorMessage}
          className={"message-wrapper"}
          placement={errorPlacement || "bottomStart"}
        >
          {errorMessage}
        </ErrorMessage>
      )}
    </div>
  );
}

const FormOptionValueComponent: React.FC<
  FormControlAccepterProps<customFieldValueUpdateInterface> & {
    field: customFieldLinkedInterface;
    onClear?: () => void;
  }
> = ({ field, defaultValue, value, onChange, onClear, ...rest }) => {
  const childField = field.options?.find((x) => x.id === value?.value)?.child;
  const options = field.options?.map((x) => ({ label: x.name, value: x.id }));
  const isOtherSelected =
    field.type === CUSTOM_FIELD_TYPE.SELECT_WITH_OTHER &&
    value &&
    !field.options!.map((x) => x.id).includes(value.value);
  const optionsWithOther =
    field.type === CUSTOM_FIELD_TYPE.SELECT_WITH_OTHER
      ? options?.concat({ label: "Other", value: isOtherSelected ? value.value : "myValue" })
      : options;

  return (
    <>
      {field.type === CUSTOM_FIELD_TYPE.TEXT && (
        <Input
          defaultValue={defaultValue?.value}
          value={value?.value}
          onChange={(v, evt) => onChange?.({ ...value!, value: v }, evt)}
          {...rest}
          style={{ width: "100%" }}
        />
      )}
      {isSelect(field.type) && (
        <>
          <SelectPicker
            {...rest}
            defaultValue={field.options?.[0].id}
            value={value?.value}
            onChange={(v, evt) => onChange?.({ ...value!, value: v }, evt)}
            data={optionsWithOther!}
            style={{ width: "100%" }}
            cleanable={false}
            searchable={false}
          />
          {isOtherSelected && (
            <Input
              defaultValue={value?.value}
              value={value?.value}
              onChange={(v, evt) => onChange?.({ ...value!, value: v }, evt)}
              {...rest}
              style={{ width: "100%" }}
            />
          )}
          {childField && (
            <FormOptionValueComponent
              field={childField}
              value={value?.child}
              onChange={(v, evt) =>
                onChange?.({ ...value!, child: { fieldId: childField.id!, value: v.value } }, evt)
              }
            />
          )}
        </>
      )}
      {field.type === CUSTOM_FIELD_TYPE.BOOLEAN && (
        <Checkbox
          checked={value?.value}
          onChange={(v, ch, evt) => onChange?.({ ...value!, value: ch }, evt)}
          {...rest}
          style={{ width: "100%" }}
        >
          {field.name}
        </Checkbox>
      )}
    </>
  );
};

export const FormOptionValueInput: React.FC<
  FormControlAccepterProps<customFieldValueUpdateInterface> & {
    field: customFieldLinkedInterface;
    onClear?: () => void;
  }
> = ({ field, defaultValue, value, onChange, onClear, ...rest }) => {
  return (
    <InputGroup style={{ width: "300px" }}>
      <FormOptionValueComponent
        field={field}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
      />
      {onClear && (
        <InputGroup.Button color="red" onClick={onClear}>
          X
        </InputGroup.Button>
      )}
    </InputGroup>
  );
};

export const FormOptionSelectValueInput: React.FC<
  FormControlAccepterProps<string> & {
    onClear?: () => void;
    onAdd?: () => void;
  }
> = ({ onClear, onAdd, ...rest }) => {
  return (
    <InputGroup style={{ width: "300px" }}>
      <Input {...rest} style={{ width: "100%" }} />
      {onClear && (
        <InputGroup.Button color="red" onClick={onClear}>
          X
        </InputGroup.Button>
      )}
      {onAdd && (
        <InputGroup.Button color="blue" onClick={onAdd}>
          +
        </InputGroup.Button>
      )}
    </InputGroup>
  );
};
