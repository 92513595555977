import React, { useEffect, useState } from "react";
import "./Waitlist.scss";
import { Input, InputGroup, Icon, Table, Checkbox } from "rsuite";
import Map from "components/Map";
import useMBContext from "context/useMBContext";
import { ColorCell } from "components/common/Table";
import { pledgeDisplayInterface } from "interfaces/pledge";
import { mapPin } from "interfaces/map";
import { fetchPledges, userSearchSettingsInterface } from "api/pledges";
import { boundsFromPoint } from "misc/utils";
import { LngLat, LngLatBounds } from "mapbox-gl";
import { fetchOrganizationDefaultMapLocation } from "api/organization";

const Waitlist: React.FC = () => {
  const { selectedAccount, selectedOrganization } = useMBContext();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pledges, setPledges] = useState<Array<pledgeDisplayInterface>>([]);
  const [pins, setPins] = useState<Array<mapPin>>([]);
  const [filteredPins, setFilteredPins] = useState<Array<mapPin>>([]);
  const [bounds, setBounds] = useState<LngLatBounds | null>(null);
  const [checked, setChecked] = useState<boolean>(true);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);

  const lineColors = {
    0: "rgba(148, 0, 211,0.5)",
    1: "rgba(75, 0, 130,0.5)",
    2: "rgba(0, 0, 255,0.5)",
    3: "rgba(0, 255, 0,0.5)",
    4: "rgba(255, 255, 0,0.5)",
    5: "rgba(255, 127, 0,0.5)",
    6: "rgba(255, 0 , 0,0.5)",
  };

  useEffect(() => {
    selectedAccount &&
      selectedOrganization &&
      fetchPledges({ accountId: selectedAccount.id, orgId: selectedOrganization.id }).then(
        (items) => {
          const pins: Array<mapPin> = [];
          const pledgesItems: Array<pledgeDisplayInterface> = [];
          const allBounds = new LngLatBounds();
          items.forEach((item: userSearchSettingsInterface, index) => {
            pledgesItems.push({
              Checked: true,
              Index: index + 1,
              Id: item.id,
              Name: item.name,
              Email: item.email,
              Arrive: item.searchSettings.arriveTime,
              Depart: item.searchSettings.departTime,
              Color: lineColors[index % 7],
              CanDrive: !!item.searchSettings.driveDays,
              RideDays: item.searchSettings.rideDays,
              DriveDays: item.searchSettings.driveDays,
            });
            const arrive = item.searchSettings.work;
            allBounds.extend(LngLat.convert(arrive.coordinates));
            pins.push({
              id: "destination-" + index,
              pinNumber: index + 1,
              place: {
                id: "",
                coordinates: arrive.coordinates,
                name: arrive.name || "",
                streetAddress: arrive.streetAddress || "",
                city: arrive.city || "",
                state: arrive.state || "",
                zip: arrive.zip || "",
              },
              label: "Drop Off",
            });
            const depart = item.homeAddress;
            allBounds.extend(LngLat.convert(depart.coordinates));
            pins.push({
              id: "origin-" + index,
              pinNumber: index + 1,
              place: {
                id: "",
                coordinates: depart.coordinates,
                name: depart.name || "",
                streetAddress: depart.streetAddress || "",
                city: depart.city || "",
                state: depart.state || "",
                zip: depart.zip || "",
              },
              label: "Pick Up",
            });
          });
          setPledges(pledgesItems);
          setPins(pins);
          setFilteredPins(pins);
          setBounds(allBounds);
          if (allBounds.isEmpty()) {
            fetchOrganizationDefaultMapLocation({
              accountId: selectedAccount.id,
              id: selectedOrganization.id,
            }).then((place) => {
              setBounds(boundsFromPoint(place.coordinates, 0.05));
            });
          }
        },
      );
  }, [selectedAccount, selectedOrganization]);

  const filterPledges = (value: any) => {
    setSearchTerm(value);
  };

  const showSuggestion = (data, event) => {
    if (event.target.className.includes("checkbox")) {
      handleShowHide(data);
      return;
    }
  };

  const handleShowHide = (data) => {
    const updated = pledges.map((value) => {
      return {
        ...value,
        Checked: value.Id === data.Id ? !value.Checked : value.Checked,
      };
    });

    setPledges(updated);
    _updateMap(updated);
  };

  const handleShowHideAll = () => {
    setChecked(!checked);
    const updated = pledges.map((value) => {
      return {
        ...value,
        Checked: !checked,
      };
    });

    setPledges(updated);
    _updateMap(updated);
  };

  const _updateMap = (updated: pledgeDisplayInterface[]) => {
    const checkedIndices = updated.filter((m) => m.Checked).map((m) => m.Index);
    const newPins = pins.filter((p) => {
      return checkedIndices.includes(p.pinNumber!);
    });
    setFilteredPins(newPins);

    setIndeterminate(checkedIndices.length !== updated.length && checkedIndices.length != 0);
  };

  interface CellTypes {
    rowData?: pledgeDisplayInterface;
    dataKey?: string;
  }

  const SubmitterCell: React.FC<CellTypes> = ({ rowData, dataKey, ...props }) => {
    return (
      <Table.Cell {...props}>
        {rowData?.Name}
        <br />
        {rowData?.Email} {rowData?.CanDrive ? "(Driver)" : ""}
      </Table.Cell>
    );
  };

  const TimesCell: React.FC<CellTypes> = ({ rowData, dataKey, ...props }) => {
    return (
      <Table.Cell {...props}>
        {rowData?.Arrive}
        <br />
        {rowData?.Depart}
      </Table.Cell>
    );
  };

  const DaysCell: React.FC<CellTypes> = ({ rowData, dataKey, ...props }) => {
    return (
      <Table.Cell {...props}>
        {rowData?.RideDays && <span>Ride: {rowData.RideDays.join(", ")}</span>}
        <br />
        {rowData?.DriveDays && <span>Drive: {rowData.DriveDays.join(", ")}</span>}
      </Table.Cell>
    );
  };

  interface CheckCellTypes extends CellTypes {
    onChange: (any) => void;
  }

  const CheckCell: React.FC<CheckCellTypes> = ({ rowData, onChange, dataKey, ...props }) => (
    <Table.Cell {...props} style={{ padding: 0 }}>
      <div style={{ lineHeight: "46px" }}>
        {dataKey && (
          <Checkbox
            value={rowData?.[dataKey]}
            inline
            onChange={onChange}
            checked={rowData?.["Checked"]}
          />
        )}
      </div>
    </Table.Cell>
  );

  interface ActionCellTypes extends CellTypes {
    onClick: (row: pledgeDisplayInterface) => void;
    name: string;
  }

  const ActionCell: React.FC<ActionCellTypes> = ({ rowData, name, onClick, dataKey, ...props }) => (
    <Table.Cell {...props} style={{ padding: 0 }}>
      <span>
        <a onClick={() => onClick(rowData!)} className="action">
          {name}
        </a>
      </span>
    </Table.Cell>
  );

  const [deletingEntry, setDeletingEntry] = useState<pledgeDisplayInterface | null>(null);

  const onCloseConfirmDelete = () => {
    setDeletingEntry(null);
  };

  return (
    <>
      <div className="Waitlist">
        <div className="Waitlist-sidebar">
          <div className="Waitlist-toolbar">
            <h1>Waitlist</h1>
            <div className="Waitlist-toolbar-controls">
              <InputGroup>
                <Input placeholder="Find Pledges" onChange={filterPledges} />
                <InputGroup.Addon>
                  <Icon icon="search" />
                </InputGroup.Addon>
              </InputGroup>
            </div>
          </div>
          <div className="Waitlist-scroll">
            <Table
              wordWrap
              autoHeight={true}
              data={pledges}
              sortColumn={"date"}
              sortType={"desc"}
              onRowClick={showSuggestion}
            >
              <Table.Column
                flexGrow={0}
                width={50}
                key={"Index"}
                align="left"
                verticalAlign="middle"
              >
                <Table.HeaderCell>{"Index"}</Table.HeaderCell>
                <Table.Cell dataKey="Index" />
              </Table.Column>
              <Table.Column flexGrow={1} key={"Submitter"} align="left" verticalAlign="middle">
                <Table.HeaderCell>{"Submitter"}</Table.HeaderCell>
                <SubmitterCell />
              </Table.Column>
              <Table.Column flexGrow={0} key={"Times"} align="center" verticalAlign="middle">
                <Table.HeaderCell>{"Times"}</Table.HeaderCell>
                <TimesCell />
              </Table.Column>
              <Table.Column flexGrow={1} key="Days" align="center" verticalAlign="middle">
                <Table.HeaderCell>Days</Table.HeaderCell>
                <DaysCell />
              </Table.Column>
              <Table.Column flexGrow={0} key={"Checked"} align="center" verticalAlign="middle">
                <Table.HeaderCell style={{ padding: 0 }}>
                  <div style={{ lineHeight: "40px", marginTop: -10 }}>
                    <Checkbox
                      value={true}
                      inline
                      onChange={handleShowHideAll}
                      checked={checked}
                      indeterminate={indeterminate}
                    />
                  </div>
                </Table.HeaderCell>
                <CheckCell dataKey="Id" onChange={handleShowHide} />
              </Table.Column>
            </Table>
          </div>
        </div>
        <div className="Waitlist-map">
          <Map pins={filteredPins} selectedBounds={bounds} />
        </div>
      </div>
    </>
  );
};

export default Waitlist;
