import React from "react";
import { Layer, Feature } from "react-mapbox-gl";

interface MapLineInterface {
  routeID: number;
  polyline: number[];
  lineType?: string;
  color?: string;
}

const MapLine: React.FC<MapLineInterface> = ({
  routeID,
  polyline,
  lineType,
  color = "rgba(100, 100, 100, 0.3)",
}: MapLineInterface) => {
  return (
    <div>
      <Layer
        id={lineType ? lineType : "line" + routeID}
        type="line"
        layout={{
          "line-join": "round",
          "line-cap": "round",
        }}
        paint={{
          "line-color": color,
          "line-width": 6,
        }}
      >
        {polyline && <Feature coordinates={polyline} />}
      </Layer>
    </div>
  );
};

export default MapLine;
