import { gqlOp, GQLSchema } from "misc/http";
import { GQLBase } from "misc/utils";

export enum CUSTOM_FIELD_TYPE {
  TEXT = "TEXT",
  SELECT = "SELECT",
  SELECT_WITH_OTHER = "SELECT_WITH_OTHER",
  BOOLEAN = "BOOLEAN",
}

export enum CUSTOM_FIELD_TARGET {
  USER = "USER",
  NONE = "NONE",
}

export class customFieldOptionInterface extends GQLBase {
  id? = "";
  name = "";
  description? = "";
  childId? = ""; // custom field ID
}

export class customFieldOptionLinkedInterface extends customFieldOptionInterface {
  child?: customFieldInterface;
}

export class customFieldChildInterface extends GQLBase {
  id = "";
  name = "";
}

export class customFieldInterface extends GQLBase {
  id? = "";
  disabled? = false;
  target = CUSTOM_FIELD_TARGET.USER;
  type = CUSTOM_FIELD_TYPE.TEXT;
  name = "";
  description? = "";
  options? = [new customFieldOptionInterface()];
  tags? = "";
}

export class customFieldLinkedInterface extends customFieldInterface {
  options?: customFieldOptionLinkedInterface[];
}

export class customFieldValueUpdateInterfaceBase extends GQLBase {
  fieldId = "";
  value?: any = ""; // JSON string encoded

  static fromGQL = (c: customFieldValueUpdateInterfaceBase) => ({
    ...c,
    value: JSON.parse(c.value as string),
  });

  static toGQL = (c: customFieldValueUpdateInterfaceBase) => ({
    ...c,
    value: JSON.stringify(c.value),
  });
}

export class customFieldValueUpdateInterface extends customFieldValueUpdateInterfaceBase {
  child? = new customFieldValueUpdateInterfaceBase();
  static fromGQL = (c: customFieldValueUpdateInterface) => ({
    ...customFieldValueUpdateInterfaceBase.fromGQL(c),
    child: c.child ? customFieldValueUpdateInterfaceBase.fromGQL(c.child) : undefined,
  });

  static toGQL = (c: customFieldValueUpdateInterface) => ({
    ...customFieldValueUpdateInterfaceBase.toGQL(c),
    child: c.child ? customFieldValueUpdateInterfaceBase.toGQL(c.child) : undefined,
  });
}

export const customFieldDefaultValue = (field: customFieldInterface) => {
  if ([CUSTOM_FIELD_TYPE.SELECT, CUSTOM_FIELD_TYPE.SELECT_WITH_OTHER].includes(field.type)) {
    return field.options![0].id!;
  }
  if (CUSTOM_FIELD_TYPE.BOOLEAN === field.type) {
    return true;
  }
  return "myValue";
};

const customFieldGetValueDisplay = (
  field: customFieldInterface,
  value: customFieldValueUpdateInterface | undefined,
): string => {
  if (field.type === CUSTOM_FIELD_TYPE.SELECT) {
    return field.options?.find((x) => x.id === value?.value)?.name || "";
  } else if (field.type === CUSTOM_FIELD_TYPE.SELECT_WITH_OTHER) {
    return field.options?.find((x) => x.id === value?.value)?.name || value?.value || "";
  } else if (field.type === CUSTOM_FIELD_TYPE.BOOLEAN) {
    return value?.value ? "true" : "false";
  }
  return value?.value || "";
};

export const customFieldValueDisplay = (
  field: customFieldLinkedInterface,
  value: customFieldValueUpdateInterface | undefined,
  separator = ", ",
): string => {
  const childField = field.options?.find((x) => x.id === value?.value)?.child;
  return (
    customFieldGetValueDisplay(field, value) +
    (value?.child && childField ? separator + customFieldValueDisplay(childField, value.child) : "")
  );
};

const customFieldUpdateSchema: GQLSchema = {
  params: {
    accountId: "String",
    orgId: "String",
    field: "CustomFieldInput!",
  },
  name: "customFieldUpdate",
  op: "mutation",
};

export const fetchCustomFieldUpdate = (params: {
  accountId?: string;
  orgId?: string;
  field: customFieldInterface;
}) => {
  return gqlOp(customFieldUpdateSchema, params, new customFieldInterface());
};
