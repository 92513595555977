import {
  organizationBaseInterface,
  organizationBasicInterface,
  organizationInterface,
  updateOrganizationInterface,
} from "interfaces/organization";
import { placeInterface } from "interfaces/place";
import { gqlOp, GQLSchema, gql_post } from "misc/http";
import { customFieldLinkedInterface } from "./customfield";

const orgSchema: GQLSchema = {
  params: {
    accountId: "String",
    ids: "[String]",
  },
  name: "organizations",
  op: "query",
};

export const fetchOrganizations = (params: { accountId?: string; ids?: string[] }) => {
  return gqlOp(orgSchema, params, [new organizationBasicInterface()]);
};

export const fetchOrganizationsFull = (params: { accountId?: string; ids: string[] }) => {
  return gqlOp(orgSchema, params, [new organizationBaseInterface()]).then<organizationInterface[]>(
    (orgs) =>
      orgs.map((org) => {
        // link custom fields
        (org.customFields as customFieldLinkedInterface[]).forEach((field) => {
          field.options?.forEach((option) => {
            option.child = option.childId
              ? org.customFields.find((x) => x.id === option.childId)
              : undefined;
          });
        });
        return org;
      }),
  );
};

export const fetchOrganizationDefaultMapLocation = async (params: {
  accountId?: string;
  id: string;
}) => {
  const data = `
    query getOrganization($accountId: String, $ids: [String]!) {
      organizations(accountId: $accountId, ids: $ids) {
        defaultMapLocation { id coordinates { lat lng } }
      }
    }
  `;
  return gql_post<organizationInterface[]>("organizations", data, {
    accountId: params.accountId,
    ids: [params.id],
  }).then<placeInterface>((orgs) => {
    if (orgs.length > 0 && orgs[0].defaultMapLocation) {
      return orgs[0].defaultMapLocation;
    }
    throw "no orgs";
  });
};

export const addOrganization = async (params: {
  accountId: string;
  code: string;
  organization: updateOrganizationInterface;
}) => {
  const data = `
    mutation createOrganization($accountId: String!, $code: String!, $organization: AdminOrganizationInput!) {
      createOrganization(accountId: $accountId, code: $code, organization: $organization) {
        id
      }
    }
  `;
  return gql_post<{ id: string }>("createOrganization", data, params);
};

export const editOrganization = async (params: {
  accountId: string;
  id: string;
  organization: updateOrganizationInterface;
}) => {
  const data = `
    mutation updateOrganization($accountId: String!, $id: String!, $organization: AdminOrganizationInput!) {
      updateOrganization(accountId: $accountId, id: $id, organization: $organization) {
        success
      }
    }
  `;
  return gql_post<{ success: boolean }>("updateOrganization", data, params);
};
